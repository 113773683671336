(function($) {
  var SnsTabulator = function(element, settings) {
    var config = {
        listener: "tab"
      },
      tabWrapper = element,
      initCall = null,
      tabNavigation = null,
      tabItems = null,
      urlHash = window.location.hash || false;

    /**
     *
     * @private
     */
    function __construct() {
      config = $.extend(config, settings);

      initCall = true;

      tabNavigation = tabWrapper.find("a");
      tabItems = $(".js_tab-item");

      tabItems.hide();

      init();
      bindEvents();
    }

    function init() {
      var active = false,
        activeIndex = 0;

      for (var i = 0, max = tabNavigation.length; i < max; i++) {
        if (
          !urlHash &&
          $(tabNavigation[i])
            .parent()
            .hasClass("active")
        ) {
          active = true;
          activeIndex = i;
        }

        if (urlHash && $(tabNavigation[i]).attr("href") == urlHash) {
          active = true;
          activeIndex = i;
        }
      }
      removeActive();
      activateTab($(tabNavigation[activeIndex]).attr("href"));
    }

    /**
     *
     */
    function bindEvents() {
      tabNavigation.on("click", function() {
        initCall = false;
        removeActive();
        window.location.hash = $(this).attr("href");
        activateTab($(this).attr("href"));
      });

      $(window).on("hashchange", function() {
        if (window.location.hash === "#" || window.location.hash === "") {
          return;
        }
        initCall = false;
        removeActive();
        activateTab(window.location.hash);
      });
    }

    function removeActive() {
      tabItems.hide();
      for (var i = 0, max = tabNavigation.length; i < max; i++) {
        $(tabNavigation[i])
          .parent()
          .removeClass("active");
      }
    }

    /**
     *
     */
    function activateTab(key) {
      for (var i = 0, max = tabNavigation.length; i < max; i++) {
        if ($(tabNavigation[i]).attr("href") == key) {
          $(tabNavigation[i])
            .parent()
            .addClass("active");
        }
      }

      key = key.replace("#", "");

      for (i = 0, max = tabItems.length; i < max; i++) {
        if ($(tabItems[i]).data("tab") == key) {
          $(tabItems[i]).show();

          initMap($(tabItems[i]));
          updateSameHeightElements($(tabItems[i]));

          if (!initCall) {
            $("html,body").animate(
              {
                scrollTop: tabWrapper.offset().top
              },
              400
            );
          }

          break;
        }
      }
    }

    function updateSameHeightElements(tabWrap) {
      var elements = tabWrap.find(".js_has-same-height");
      if (elements.length) {
        elements.data("SnsEqualizeHeight").refresh();
      }
    }

    /**
     *
     * @param {Object} tabWrap
     */
    function initMap(tabWrap) {
      var mapWrap = tabWrap.find(".map");
      if (
        mapWrap.length &&
        typeof mapWrap.data("SnsStoreLocator") !== "undefined"
      ) {
        mapWrap.data("SnsStoreLocator").makeVisible();
      }
    }

    return __construct();
  };

  $.fn.extend({
    SnsTabulator: function(options) {
      return $(this).each(function() {
        new SnsTabulator($(this), options);
      });
    }
  });
})(jQuery);
