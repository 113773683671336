/*globals define, document, window, $*/

import global from "../global";
import minicart from "../modules/minicart";
import datalayer from "../modules/datalayer";
import "../utils/equalizeheights";

var self;

export default {
  init: function() {
    self = this;
    self.equalizeHeights();

    // add to cart
    $(document).on("click", ".watch-list .js-add-to-cart", function(e) {
      e.preventDefault();
      self.fireDataLayer($(this).data("item-id"), 1);
      self.addToCart(this);
    });

    // remove item
    global.removeItemInit(self.removeConfirmAction, null);

    // perform equal heights for collapsed elements
    $(".js_toggle_element_classes").on("togglingFinished", function(e, data) {
      e.preventDefault();
      self.equalizeHeights();
    });
  },

  removeConfirmAction: function(el) {
    $.ajax({
      url: el.data("ajaxurl"),
      success: function(data) {
        self.setNumberCircle(data.count);
      },
      error: function(e) {
        // do nothing
        if (window.console) {
          window.console.log(e);
        }
      }
    });
  },

  addToCart: function(el) {
    $.ajax({
      url: el.href,
      success: function(data) {
        // update minicart
        let $el = $(el),
          count = $(data)
            .filter("#addToCartLayer")
            .attr("data-count");
        self.updateMiniCart(count);

        // reset button text as user feedback
        $el.text($el.data("successmsg"));
      },
      error: function(e) {
        if (window.console) {
          window.console.log(e);
        }
      }
    });
  },

  updateMiniCart: function(count) {
    minicart.reset();
    minicart.setNumberCircle(count);
  },

  equalizeHeights: function() {
    $(".js_same-height-row-wrap").SnsEqualizeHeights({
      resizePerRow: true
    });
  },

  fireDataLayer: function(itemId, changingAmount) {
    datalayer.addToCart(itemId, changingAmount);
  },

  setNumberCircle: function(count) {
    let $numberCircle = $(".wishlist-preview .number-circle");
    if (count === "undefined" || count === 0) {
      $numberCircle.hide();
      $numberCircle.html(0);
    } else {
      $numberCircle.show();
      $numberCircle.html(count);
    }
  }
};
