(function($) {
  var SnsSquareResize = function(element) {
    var self = this,
      square = null;

    /**
     *
     * @returns {SnsSquareResize}
     * @private
     */
    function __construct() {
      square = $(element);

      resize();

      $(window).on("resize", resize);

      return self;
    }

    /**
     *
     */
    function resize() {
      resetPaddings();

      var width = square.innerWidth(),
        height = square.height(),
        padding = (width - height) / 2;

      updatePaddings(padding);
    }

    /**
     *
     * @param {number} padding
     */
    function updatePaddings(padding) {
      if (padding > 0) {
        square.css({
          "padding-top": padding,
          "padding-bottom": padding
        });
      }
    }

    /**
     *
     */
    function resetPaddings() {
      square.css({
        "padding-top": 0,
        "padding-bottom": 0
      });
    }

    return __construct();
  };

  $.fn.extend({
    SnsSquareResize: function() {
      return $(this).each(function() {
        new SnsSquareResize($(this));
      });
    }
  });
})(jQuery);
