import global from "../global";

export default {
  init: function() {
    var $formCheckout = $("#stepModel");
    var loadingButtonCheckout = $formCheckout.find(".loading-button");
    var $errorContainerCheckout = $(".js-address-lookup").find(".error-txt");

    $(document).on("click", "#stepModel .get-data-uc-address", function(event) {
      var optKlarnaAddressUrl = $(this).attr("data-binding-address-url");
      event.preventDefault();
      if (
        optKlarnaAddressUrl &&
        $formCheckout.attr("data-submit-form-url") !== undefined
      ) {
        $.ajax({
          url: optKlarnaAddressUrl,
          method: "POST",
          headers: global.headers,
          data: $formCheckout.serialize(),
          timeout: 3000,
          beforeSend: function() {
            loadingButtonCheckout.show();
          },
          success: function(json) {
            if (typeof json == "object") {
              if (json.success === false) {
                if (json.errorMessage) {
                  $errorContainerCheckout.text(json.errorMessage);
                  $errorContainerCheckout.show();
                }
              } else {
                $errorContainerCheckout.hide();
              }
              if (json.success) {
                if (json.address) {
                  $.each(json.address, function(key, value) {
                    $(document)
                      .find('.billing input[data-attribute-name="' + key + '"]')
                      .val(value);
                  });
                }
              }
            }
          }
        }).success(function() {
          setTimeout(function() {
            loadingButtonCheckout.hide();
          }, 300);
        });
      }
    });

    var $formRegister = $("#registration-form");
    var loadingButtonRegister = $formCheckout.find(".loading-button");
    var $errorContainerRegister = $(".js-address-lookup").find(".error-txt");
    $(document).on("click", "#registration-form .get-data-uc-address", function(
      event
    ) {
      var optKlarnaAddressUrl = $(this).attr("data-binding-address-url");
      event.preventDefault();
      if (optKlarnaAddressUrl) {
        $.ajax({
          url: optKlarnaAddressUrl,
          method: "POST",
          headers: global.headers,
          data: $formRegister.serialize(),
          timeout: 3000,
          beforeSend: function() {
            loadingButtonRegister.show();
          },
          success: function(json) {
            if (typeof json == "object") {
              if (json.success === false) {
                if (json.errorMessage) {
                  $errorContainerRegister.text(json.errorMessage);
                  $errorContainerRegister.show();
                }
              } else {
                $errorContainerRegister.hide();
              }
              if (json.success) {
                if (json.address) {
                  $.each(json.address, function(key, value) {
                    $(document)
                      .find('input[data-attribute-name="' + key + '"]')
                      .val(value);
                  });
                }
              }
            }
          }
        }).success(function() {
          setTimeout(function() {
            loadingButtonRegister.hide();
          }, 300);
        });
      }
    });
  }
};
