import "parsleyjs/dist/parsley";

var _self;

var doubleClickPrevent = {
  init: function() {
    _self = this;
    _self.preventDoubleSubmission("#registration-form"); // Register
    _self.preventDoubleSubmission("#login-form"); // Account Login
    _self.preventDoubleSubmission("#customer-login"); // Checkout Login
  },

  preventDoubleSubmission: function(id) {
    $(id).on("submit", function(e) {
      let $form = $(this);
      if ($form.data("submitted") === true) {
        // Previously submitted - don't submit again
        e.preventDefault();
      } else {
        // Mark it so that the next submit can be ignored
        if ($form.parsley().validate()) {
          $form.data("submitted", true);
        }
      }
    });
  }
};

export default doubleClickPrevent;
