export default {
  init: function() {
    let self = this;
    self.initInsuranceHintLayer();
    self.initInsuranceCheckboxDisabled();
  },

  initInsuranceHintLayer: function() {
    let self = this;

    $(".js_insurance-layer").click(function(e) {
      e.preventDefault();

      let $button = $(this);
      let layerWidth = window.outerWidth * 0.6;
      let layerHeight = window.innerHeight;
      if (window.outerWidth <= 802) {
        layerWidth = window.outerWidth;
        layerHeight = window.outerHeight;
      }
      lightcase.start({
        href: $button.attr("data-href"),
        showSequenceInfo: false,
        type: "ajax",
        maxWidth: 700,
        maxHeight: layerHeight,
        ajax: {
          data: {
            itemId: $button.attr("data-sku"),
            categoryId: $button.attr("data-category")
          },
          width: layerWidth,
          height: layerHeight
        },
        shrinkFactor: 1,
        onFinish: {
          injectContent: function() {
            lightcase
              .get("contentInner")
              .children()
              .css({
                height: layerHeight,
                overflowY: "scroll"
              });
            if (window.outerWidth <= 802) {
              $(".insurance-image.desktop").hide();
            } else {
              $(".insurance-image.mobile").hide();
            }
          }
        }
      });
    });
  },

  toggleInsuranceCheckbox: function(enable) {
    let $insuranceDiv = $(".article-insurance");
    if ($insuranceDiv.length > 0) {
      let $insuranceCheckbox = $insuranceDiv.find(".design-checkbox");
      let $insuranceJsCheckbox = $insuranceDiv.find(".js-design-checkbox");

      if (!$insuranceJsCheckbox.prop("disabled")) {
        $insuranceJsCheckbox.prop("checked", enable);
        if (enable) {
          $insuranceCheckbox.addClass("checked");
        } else {
          $insuranceCheckbox.removeClass("checked");
        }
      }
    }
  },

  getInsuranceCheckboxValue: function() {
    let $insuranceDiv = $(".article-insurance");
    if ($insuranceDiv.length > 0) {
      return $insuranceDiv.find(".js-design-checkbox").prop("checked");
    }
  },

  initInsuranceCheckboxDisabled: function() {
    let $insuranceDiv = $(".article-insurance");
    if ($insuranceDiv.length > 0) {
      let $insuranceCheckbox = $insuranceDiv.find(".design-checkbox");
      let $insuranceJsCheckbox = $insuranceDiv.find(".js-design-checkbox");
      if ($insuranceJsCheckbox.prop("disabled")) {
        $insuranceCheckbox.css("background-color", "#e5e5e5");
      }
    }
  },

  setInsuranceCheckboxDisable: function(disable) {
    let $insuranceDiv = $(".article-insurance");
    if ($insuranceDiv.length > 0) {
      let $insuranceCheckbox = $insuranceDiv.find(".design-checkbox");
      $insuranceDiv.find(".js-design-checkbox").prop("disabled", disable);
      if (disable) {
        $insuranceCheckbox.css("background-color", "#e5e5e5");
      } else {
        $insuranceCheckbox.css("background-color", "#ffffff");
      }
    }
  }
};
