import wishlist from "../modules/wishlist";

/*globals define, document, window, $*/
var SnsWatchlistLayer = function(element) {
  var self = this,
    wrap = null,
    toggleLayerLink = null,
    layer = null,
    closeButton = null,
    lists = null,
    addToRemoveFromListButtons = null,
    newListFieldWrap = null,
    newListField = null,
    newListButton = null,
    newListItemBoilerplate = null,
    newListFieldActive = false,
    URL_QUERYPARAM_WISHLIST_NAME = "&wishlistGroupName=";

  this.__construct = function() {
    wrap = $(element);
    toggleLayerLink = wrap.find(".js_add-to-watchlist-button");
    layer = wrap.find(".js_watchlist-layer");
    closeButton = layer.find(".js_close");
    lists = layer.find(".js_watchlists");
    addToRemoveFromListButtons = layer.find(".js_addTo-removeFrom-list");
    newListFieldWrap = layer.find(".js_create-list-name-wrap");
    newListField = newListFieldWrap.find(".js_create-list-name");
    newListButton = layer.find(".js_create-list-btn");
    newListItemBoilerplate = layer.find(".js_single-list-boilerplate");

    self.initEvents();

    return self;
  };

  this.closeNewListFieldWrap = function() {
    newListFieldWrap.addClass("hide");
    newListFieldActive = false;
  };

  this.initEvents = function() {
    toggleLayerLink.on("click", function(ev) {
      ev.preventDefault();
      self.toggleLayerVisibility();
    });

    closeButton.on("click", self.toggleLayerVisibility);

    newListButton.on("click", function(ev) {
      ev.preventDefault();
      if ($(this).hasClass("js_disabled")) {
        return;
      }
      if (newListFieldActive) {
        newListFieldActive = false;
        self.submitForm(newListField.val(), newListField.attr("data-url"));
        // hideing the input is done in triggerViewUpdate()
      } else {
        newListFieldWrap.removeClass("hide");
        newListField.focus();
        newListFieldActive = true;
      }
    });

    newListField.on("keydown", function(ev) {
      if (ev.keyCode === 13) {
        ev.preventDefault();
        self.submitForm(newListField.val());
        return false;
      }
    });

    addToRemoveFromListButtons.on("click", function(ev) {
      ev.preventDefault();
      self.addRemoveArticle($(this));
    });
  };

  this.toggleLayerVisibility = function() {
    layer.toggleClass("hide");
  };

  this.submitForm = function(listName, url) {
    $.get(url + URL_QUERYPARAM_WISHLIST_NAME + listName, function(jsonObj) {
      if (jsonObj.errorMsg) {
        if (window.console) {
          window.console.log("Wishlist input error!", jsonObj.errorMsg);
        }
        return;
      }
      var $el = newListItemBoilerplate.clone(true).show();
      $el
        .find(".js_addTo-removeFrom-list")
        .data("wishlist-group-name", jsonObj.relatedWishlistGroupName);
      $el
        .find(".title")
        .text(jsonObj.relatedWishlistGroupName)
        .attr("href", function(index, attr) {
          return attr + "#" + jsonObj.relatedWishlistNormalizedGroupName;
        });
      lists.prepend($el);

      self.triggerViewUpdate("added", $el, jsonObj.count);
    }).fail(function(error) {
      if (window.console) {
        window.console.log("Wishlist input error!", error);
      }
    });
  };

  this.addRemoveArticle = function($el) {
    var action = $el.data("action"),
      succeededAction = "add" === action ? "added" : "removed",
      actionUrl =
        "add" === action
          ? $el.data("add-url") +
            URL_QUERYPARAM_WISHLIST_NAME +
            $el.data("wishlist-group-name")
          : $el.data("remove-url");

    $.get(actionUrl, function(data) {
      if (data.errorMsg) {
        if (window.console) {
          window.console.log("Wishlist input error!", data.errorMsg);
        }
        return;
      }
      self.triggerViewUpdate(succeededAction, $el, data.count);
    }).fail(function(error) {
      if (window.console) {
        window.console.log("Wishlist input error!", error);
      }
    });
  };

  this.triggerViewUpdate = function(succeededAction, $el, wishlistCounter) {
    let iconPda, iconEvent, innerSpan, outerListItem;

    //SNS provides two markups which cannot easily merged :-/ :
    (iconPda = $("#jsnote")), //one for PDA wishlist
      (iconEvent = $(".js_add-to-watchlist-button .js_heart")), //and one for events
      (innerSpan = $el.find(".js_link-content")),
      (outerListItem = $el.parent(".js_single-list"));
    //update the counter in header area
    wishlist.setNumberCircle(wishlistCounter);

    //toggle heard icon and behaviour of links that represents the wishlists
    if ("added" === succeededAction) {
      iconPda.addClass("active");
      iconEvent.removeClass("icon-heart-inactive").addClass("icon-heart");
      outerListItem.addClass("active");
      innerSpan.text($el.data("remove-text"));
      newListButton.addClass("disabled js_disabled");
      addToRemoveFromListButtons.addClass("disabled js_disabled");
      $el.removeClass("disabled js_disabled"); //leave the current element enabled
      $el.addClass("remove-link");
      $el.removeClass("add-link");
      $el.data("action", "remove");
    } else {
      iconPda.removeClass("active");
      iconEvent.removeClass("icon-heart").addClass("icon-heart-inactive");
      outerListItem.removeClass("active");
      innerSpan.text($el.data("add-text"));
      newListButton.removeClass("disabled js_disabled");
      addToRemoveFromListButtons.removeClass("disabled js_disabled");
      $el.addClass("add-link");
      $el.removeClass("remove-link");
      $el.data("action", "add");
    }
    //hide input form for new wishlist
    self.closeNewListFieldWrap();
  };

  return this.__construct();
};

$.fn.extend({
  SnsWatchlistLayer: function() {
    return $(this).each(function() {
      new SnsWatchlistLayer($(this));
    });
  }
});
