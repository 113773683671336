(function($) {
  var CustomerSearch = function(element, options) {
    let $icustomerSearchForm = $(options.selectionFormId);
    let $icustomerSearchSpinner = $(
      "#globalbanner-icustomer-search .icustomer-search-spinner"
    );
    function errorHandler(e) {
      if (window.console) window.console.log(e);
    }

    function iCustomerSearchResponseHandler(data) {
      let $loginAsCustomerButton = $("#login-as-customer-button");
      enableButton($loginAsCustomerButton, true);
      let select = options.selectionFormId + " select";
      let noCustomerFoundOptionValue = "no-customer-found";
      let $select = $(select);
      $select.empty();
      if (data.icustomerSearchModels.length > 10) {
        $(options.selectionFormId + " .error").removeClass("hide");
        $("<option>", {
          value: "",
          text: data.message
        }).appendTo($select);
      } else {
        $(options.selectionFormId + " .error").addClass("hide");
        if (data.icustomerSearchModels.length === 0) {
          $("<option>", {
            value: noCustomerFoundOptionValue,
            text: data.message
          }).appendTo($select);
        } else {
          $.each(data.icustomerSearchModels, function(index, value) {
            let text = "";
            if (value.firstName.length > 0 && value.lastName.length > 0) {
              text += value.firstName + " " + value.lastName + ", ";
            }
            text += value.mail + ", " + value.loyaltyProgram;
            $("<option>", {
              value: value.emailOrNumber,
              text: text
            }).appendTo($select);
          });
        }
      }
      let customersFoundCount = $select.children("option").length;
      let firstOption = $select.children("option")[0];
      if (
        customersFoundCount === 1 &&
        firstOption.value === noCustomerFoundOptionValue
      ) {
        enableButton($loginAsCustomerButton, true);
      }
      if (
        customersFoundCount === 1 &&
        firstOption.value !== noCustomerFoundOptionValue
      ) {
        firstOption.selected = true;
        enableButton($loginAsCustomerButton, false);
      }
      $(options.selectionFormId + " .input-wrapper").SnsSelector();
      selectChangeEventHandler(
        $select,
        customersFoundCount,
        firstOption,
        noCustomerFoundOptionValue,
        $loginAsCustomerButton
      );
      removeAjaxSpinner();
    }

    function removeAjaxSpinner() {
      $icustomerSearchForm.removeClass("icustomer-overlay");
      $icustomerSearchSpinner.addClass("hide");
    }

    function selectChangeEventHandler(
      select,
      customersFoundCount,
      firstOption,
      noCustomerFoundOptionValue,
      loginAsCustomerButton
    ) {
      select.unbind("change");
      select.change(function() {
        if (
          !(
            customersFoundCount === 1 &&
            firstOption.value === noCustomerFoundOptionValue
          )
        ) {
          enableButton(loginAsCustomerButton, false);
        }
      });
    }

    function enableButton(loginAsCustomerButton, state) {
      if (loginAsCustomerButton.prop("disabled") !== state) {
        loginAsCustomerButton.prop("disabled", state);
      }
    }

    $(document).on("click", options.searchFormId + " button", function(e) {
      e.preventDefault();
      $icustomerSearchForm.addClass("icustomer-overlay");
      $icustomerSearchSpinner.removeClass("hide");
      var $iCustomerSearchFormId = $(options.searchFormId);
      $.ajax({
        cache: false,
        data: $iCustomerSearchFormId.serialize(),
        method: "post",
        success: iCustomerSearchResponseHandler,
        error: errorHandler,
        url: $iCustomerSearchFormId.attr("action")
      });
    });

    return this;
  };

  $.fn.extend({
    CustomerSearch: function(options) {
      return $(this).each(function() {
        new CustomerSearch($(this), options);
      });
    }
  });
})(jQuery);
