/* jshint camelcase:false */

/**
 * No functional code goes here. Only load modules by its api
 */

// the modules array - holds loaded modules
import ModuleFinder from "./utils/modules_finder";

var modules = [];
var initializedModules = [];
let shopName = "";

const organizationInfoElm = document.getElementById(
  "fjallraven-organization-info"
);
if (organizationInfoElm) {
  const organizationInfo = JSON.parse(organizationInfoElm.innerText);
  shopName = organizationInfo["sameAs"][0].split("/")[3];
}

const moduleloader = {
  /**
   * init function of the main module (called from main.js)
   */
  init: function() {
    // loading all modules which are required on the current page
    this.loadStaticModules();

    // load onsiteedit module if onsiteedit flag is enabled
    this.enableOnSiteEditing();
  },

  enableOnSiteEditing: function() {
    const urlParams = new URLSearchParams(window.location.search);
    const onSiteEditFlag = urlParams.get("onsiteedit");

    if (onSiteEditFlag === "on") {
      setTimeout(() => {
        import("./onsiteedit/onsiteedit").then(onSiteEditModule => {
          onSiteEditModule["default"].init();
        });
      }, 500);
    }
  },

  dynamicImportWithInit: function(module, moduleData) {
    let currentModule = module["default"];
    if (
      module &&
      typeof currentModule.init === "function" &&
      !initializedModules.includes(currentModule)
    ) {
      initializedModules.push(currentModule);
      currentModule.init(moduleData);
    }
  },

  /**
   * @param scope - scope container to search for modules - default the whole document
   */
  loadStaticModules: function(scope) {
    // Loading all modules on the current site
    // To be load, there must be a attribute data-require-module with its
    // module name. Loadable by this, are only module who are initial on the site.
    // modules in ajaxable templates must be load separately or on another way, like in fn loadDynamicModules
    var moduleName;
    var $moduleEle;
    var moduleData = {};

    // define the scope where to search for modules
    scope = scope ? scope : document;

    $("[data-require-module]", scope).each(function(idx) {
      $moduleEle = $(this);
      moduleName = $moduleEle.attr("data-require-module");
      // console.log('moduleName : ', moduleName);
      moduleData[idx] = $moduleEle.data() ? $moduleEle.data() : null;
      // console.log('idx : ', idx);
      // console.log('moduleData[idx] : ', moduleData[idx]);
      // check moduleName and empty string
      if (!moduleName || moduleName === "") {
        return;
      }
      moduleName = moduleName.split(","); // it's possible to define more modules in one data-require-module attr
      moduleName.forEach(function(item) {
        // console.log('Shop : Any / Globetrotter Module [2] : ', item.trim());

        const folder = ModuleFinder.getModulesPath(item.trim());
        import(`./${folder}/${item.trim()}`).then(module => {
          moduleloader.dynamicImportWithInit(module, moduleData[idx]);
        });

        // Put module to the defined modules so it wouldn't be define several times
        if (!modules.includes(item.trim())) modules.push(item.trim());
      });
    });
  }
};

export default moduleloader;
