/*globals define*/

import "../plugin/texttocanvas";

var stageslider = {
  init: function(data) {
    var conf = {
      slidesPerPage: 1,
      slidesPerScroll: 1,
      ttc: false,
      cycle: true,
      unveilBackgrounds: true,
      addFakeSlides: 1,
      pageIndicator: {
        type: "line",
        outer: true
      },
      controls: {
        generate: false,
        outer: true,
        content: {
          prev: "",
          next: ""
        }
      }
    };
    if ("object" === typeof data.autoslide) {
      // merge json defined as data-autoslide="" and automatically provided by
      // module loader within "data" variable.
      conf = $.extend({}, conf, data.autoslide);
    }
    $(".js_stage-slider").SnsSlider(conf);
  }
};
export default stageslider;
