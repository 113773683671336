import "../utils/imagefit";
import "../utils/mosaic";

var outdoorworld = {
  init: function() {
    this.imageMosaic();
  },

  imageMosaic: function() {
    $(".js_mosaic").SnsMosaic();
    $(".simple-stage").SnsImageFit();
  }
};
export default outdoorworld;
