import "parsleyjs/dist/parsley";
import "../plugin/passwordstrength";

var _self;

var registrationFormValidator = {
  init: function() {
    _self = this;
    _self.loginEvent();
    _self.toggleLoyalty();
    _self.toggleLoyaltyMagazine();
    _self.setRequiredFields();
  },

  loginEvent: function() {
    $(".js-login-event").on("click", function(e) {
      var loginEventData = {
        event: "click",
        eventValue: {
          category: "account",
          action: "login",
          label: "",
          value: ""
        }
      };

      window.dataLayer.push(loginEventData);
    });
  },

  toggleLoyalty: function() {
    if ($(".js-toggle-loyaltyprogram").is(":checked")) {
      $(".js-loyalty-legal-wrapper").removeClass("hide");
    }
    $(".js-toggle-loyaltyprogram").on("change", function() {
      if ($(this).is(":checked")) {
        $(".js-loyalty-legal-wrapper").removeClass("hide");
        _self.toggleNestedFields(
          ".js-magazine-req",
          $(".js-toggle-loyaltymagazine").is(":checked")
        );
      } else {
        $(".js-loyalty-legal-wrapper").addClass("hide");
        _self.toggleNestedFields(".js-magazine-req", false);
      }
    });
  },

  toggleLoyaltyMagazine: function() {
    if ($(".js-toggle-loyaltymagazine").is(":checked")) {
      $(".js-loyalty-magazine-wrapper").removeClass("hide");
    }
    $(".js-toggle-loyaltymagazine").on("change", function() {
      if ($(this).is(":checked")) {
        $(".js-loyalty-magazine-wrapper").removeClass("hide");
        _self.toggleNestedFields(".js-magazine-req", true);
      } else {
        $(".js-loyalty-magazine-wrapper").addClass("hide");
        _self.toggleNestedFields(".js-magazine-req", false);
      }
    });
  },

  setRequiredFields: function() {
    var clubMemberSsn = $("#clubMemberSsn");

    $(document).on("click", ".js-set-required-field", function() {
      if ($(this).is(":checked")) {
        clubMemberSsn.attr("data-validate-required", true);
      } else {
        clubMemberSsn.attr("data-validate-required", false);

        clubMemberSsn.val("");
        clubMemberSsn.parsley().reset();
      }
    });
  },

  toggleNestedFields: function(selector, value) {
    $(selector).prop("required", value);
    $(selector).attr("data-validate-required", value);
  }
};

export default registrationFormValidator;
