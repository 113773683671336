import global from "../global";

export default {
  init: function() {
    var $form = $("#stepModel");
    var loadingButton = $form.find(".loading-button");
    var $errorContainer = $(".js-address-lookup").find(".error-txt");

    $(document).on("click", ".get-data-klarna", function(event) {
      var optKlarnaAddressUrl = $(this).attr("data-binding-klarna-address-url");
      event.preventDefault();
      if (
        optKlarnaAddressUrl &&
        $form.attr("data-submit-form-url") !== undefined
      ) {
        $.ajax({
          url: optKlarnaAddressUrl,
          method: "POST",
          headers: global.headers,
          data: $form.serialize(),
          timeout: 3000,
          beforeSend: function() {
            loadingButton.show();
          },
          success: function(json) {
            if (typeof json == "object") {
              if (json.success === false) {
                if (json.errorMessage) {
                  $errorContainer.text(json.errorMessage);
                  $errorContainer.show();
                }
                handleErrorClass(json.validation.ssn, ".klarna-ssn-lookup");
                handleErrorClass(
                  json.validation.zipCode,
                  ".klarna-zipcode-lookup"
                );
              } else {
                $errorContainer.hide();
              }
              if (json.success) {
                if (json.address) {
                  handleErrorClass(true, ".klarna-ssn-lookup", true);
                  handleErrorClass(true, ".klarna-zipcode-lookup", true);
                  $.each(json.address, function(key, value) {
                    $(document)
                      .find('.billing input[data-attribute-name="' + key + '"]')
                      .val(value);
                  });
                }
              }
            }
          }
        }).success(function() {
          setTimeout(function() {
            loadingButton.hide();
          }, 300);
        });
      }
    });

    var handleErrorClass = function(jsonValue, formfield, forceSuccess) {
      if (jsonValue === false) {
        $(formfield).addClass("error");
      } else {
        $(formfield).removeClass("error");
      }
      if (forceSuccess === true) {
        $(formfield).addClass("success");
      }
    };
  }
};
