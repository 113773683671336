const navigationbuilder = {
  init: function() {
    const navigationItemIdentifiers = document.querySelectorAll(
      '[data-is-used-for-sticky-nav="true"]'
    );

    const stickyNavigationWrap = document.querySelector(
      ".sticky-navigation-wrap"
    );

    $(stickyNavigationWrap).SnsStickyNavi();
    if (navigationItemIdentifiers.length < 3) {
      return false;
    }

    const navigationWrap = document.querySelector(".navigation-wrap");
    const template = navigationWrap.querySelector("#sticky-navi-item-template");

    for (const navigationItemIdentifier of navigationItemIdentifiers) {
      const itemClone = template.content.cloneNode(true);

      const naviLink = itemClone.querySelector(".js_sticky-navi-link");
      naviLink.href = "#" + navigationItemIdentifier.id;
      naviLink.append(
        document.createTextNode(navigationItemIdentifier.dataset.anchorLinkText)
      );

      navigationWrap.append(itemClone);
    }

    stickyNavigationWrap.style.display = "block";
  }
};
export default navigationbuilder;
