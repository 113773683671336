import "../utils/fontspy";

(function($) {
  var SnsTextToCanvas = function(element) {
    var self = this,
      conf = {
        canvas: {
          class: "stage-content-image",
          width: 650
        },
        font: {
          family: "OpenSans, Roboto, sans-serif",
          weight: 700,
          size: 36,
          lineHeight: 48,
          iconChar: "~",
          iconResize: 1.5
        }
      },
      canvas = null,
      ctx = null,
      textWrap = null,
      textColor = "",
      specialCharWidth = 0;

    /**
     *
     * @returns {SnsTextToCanvas}
     * @private
     */
    function __construct() {
      textWrap = $(element);
      textWrap.hide();
      canvas = $("<canvas>", {
        class: conf.canvas.class + " hide"
      });

      canvas.attr({
        width: conf.canvas.width,
        height: conf.font.lineHeight
      });

      ctx = canvas[0].getContext("2d");
      textColor = textWrap.css("color");

      fontSpy("OpenSans", {
        success: function() {
          textWrap.replaceWith(canvas);
          writeTextToCanvas(
            textWrap
              .text()
              .toUpperCase()
              .trim()
          );
          canvas.removeClass("hide");
        },
        failure: function() {
          textWrap.text(textWrap.text().replace(/~/g, ""));
          textWrap.show();
        },
        timeOut: 5000
      });

      return self;
    }

    /**
     *
     * @param {string} text
     */
    function writeTextToCanvas(text) {
      var fontString =
        conf.font.weight + " " + conf.font.size + "px " + conf.font.family;
      ctx.font = fontString;

      specialCharWidth = ctx.measureText(conf.font.iconChar).width;

      var rows = splitIntoRows(text);
      canvas.attr("height", rows.length * conf.font.lineHeight);

      ctx.font = fontString;
      ctx.fillStyle = textColor;
      if (textWrap.hasClass("text-shadow")) {
        ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        ctx.shadowBlur = 2;
      }

      for (var i = 0; i < rows.length; i++) {
        var lineTop = conf.font.lineHeight * (i + 1) - conf.font.lineHeight / 4,
          currentLeft = 0,
          rowWidth = getTextLength(rows[i]),
          characterCount = getCharacterCount(rows[i]),
          characterSpacing =
            (conf.canvas.width - rowWidth - 10) / (characterCount - 1),
          specialCharCount = 0;

        for (var c = 0; c < rows[i].length; c++) {
          var currentChar = rows[i][c];

          if (currentChar === conf.font.iconChar) {
            specialCharCount++;
          } else {
            if (specialCharCount > 0) {
              if (textWrap.data().iconType === "wave") {
                drawWave(currentLeft, i, specialCharCount);
              } else if (textWrap.data().iconType === "mountain") {
                drawMountain(currentLeft, i, specialCharCount);
              } else if (textWrap.data().iconType === "line") {
                drawLine(currentLeft, i, specialCharCount);
              }

              currentLeft +=
                specialCharCount * specialCharWidth * conf.font.iconResize +
                characterSpacing;

              specialCharCount = 0;
            }

            ctx.fillText(currentChar, currentLeft, lineTop);
            currentLeft +=
              ctx.measureText(currentChar).width + characterSpacing;
          }
        }

        if (specialCharCount > 0) {
          if (textWrap.data().iconType === "wave") {
            drawWave(currentLeft, i, specialCharCount);
          } else if (textWrap.data().iconType === "mountain") {
            drawMountain(currentLeft, i, specialCharCount);
          } else if (textWrap.data().iconType === "line") {
            drawLine(currentLeft, i, specialCharCount);
          }

          currentLeft +=
            specialCharCount * specialCharWidth * conf.font.iconResize +
            characterSpacing;

          specialCharCount = 0;
        }
      }
    }

    /**
     *
     * @param {number} x
     * @param {number} lineIndex
     * @param {number} charCount
     */
    function drawLine(x, lineIndex, charCount) {
      var characterWidth = charCount * specialCharWidth * conf.font.iconResize,
        y = (lineIndex + 1) * conf.font.lineHeight - conf.font.lineHeight / 2;

      initDrawing(x, y);
      ctx.lineTo(
        x + characterWidth,
        (lineIndex + 1) * conf.font.lineHeight - conf.font.lineHeight / 2
      );
      ctx.stroke();
    }

    /**
     *
     * @param {number} x
     * @param {number} lineIndex
     * @param {number} charCount
     */
    function drawMountain(x, lineIndex, charCount) {
      var characterWidth = specialCharWidth * conf.font.iconResize,
        y = (lineIndex + 1) * conf.font.lineHeight - conf.font.lineHeight / 2.5;

      initDrawing(x, y);

      for (var i = 0; i < charCount; i++) {
        ctx.lineTo(
          x + i * characterWidth + characterWidth / 2,
          y - conf.font.lineHeight / 6
        );
        ctx.lineTo(x + i * characterWidth + characterWidth, y);
      }

      ctx.stroke();
    }

    /**
     *
     * @param {number} x
     * @param {number} lineIndex
     * @param {number} charCount
     */
    function drawWave(x, lineIndex, charCount) {
      var characterWidth = specialCharWidth * conf.font.iconResize,
        y = (lineIndex + 1) * conf.font.lineHeight - conf.font.lineHeight / 1.6;

      var baseWidth = characterWidth / 2,
        baseHeight = y + conf.font.lineHeight / 5;

      initDrawing(x, baseHeight);

      for (var i = charCount; i--; ) {
        ctx.bezierCurveTo(
          x + baseWidth / 2,
          baseHeight,
          x + baseWidth / 2,
          y,
          x + baseWidth,
          y
        );
        ctx.bezierCurveTo(
          x + baseWidth * 1.5,
          y,
          x + baseWidth * 1.5,
          baseHeight,
          x + baseWidth * 2,
          baseHeight
        );

        x += characterWidth;
      }

      ctx.stroke();
    }

    /**
     *
     * @param {number} x
     * @param {number} y
     */
    function initDrawing(x, y) {
      ctx.beginPath();
      ctx.strokeStyle = textColor;
      ctx.lineWidth = 5;
      ctx.moveTo(x, y);
    }

    /**
     *
     * @param {string} text
     * @returns {number}
     */
    function getTextLength(text) {
      var textMatches = text.match(new RegExp(conf.font.iconChar + "+", "g")),
        specialCharsCount = 0;

      if (textMatches !== null) {
        for (var i = 0; i < textMatches.length; i++) {
          specialCharsCount += textMatches[i].length;
        }
      }

      var specialCharsExtraWidth =
        specialCharWidth * (conf.font.iconResize - 1) * specialCharsCount;

      return ctx.measureText(text).width + specialCharsExtraWidth;
    }

    function getCharacterCount(text) {
      var textMatches = text.match(new RegExp(conf.font.iconChar + "+", "g"));

      if (textMatches === null) {
        return text.length;
      }

      var tmp = text.replace(
        new RegExp(conf.font.iconChar + "+", "g"),
        conf.font.iconChar
      );
      return tmp.length;
    }

    /**
     *
     * @param {string} text
     * @returns {Array}
     */
    function splitIntoRows(text) {
      var rows = [],
        words = [],
        lastWordAdded = "",
        lastRowState = "",
        currentRow = "";

      if (text.indexOf("|") >= 0) {
        words = text.split("|");
        return words;
      } else {
        words = text.split(" ");
        while (words.length) {
          do {
            lastRowState = currentRow;
            lastWordAdded = words.shift();

            if (currentRow !== "") {
              currentRow += " ";
            }

            currentRow += lastWordAdded;
          } while (
            getTextLength(currentRow) < conf.canvas.width &&
            words.length
          );

          if (!words.length) {
            lastRowState = currentRow;
          }
          currentRow = lastWordAdded;
          rows.push(lastRowState);
        }
        return rows;
      }
    }

    return __construct();
  };

  $.fn.extend({
    SnsTextToCanvas: function() {
      return $(this).each(function() {
        new SnsTextToCanvas($(this));
      });
    }
  });
})(jQuery);
