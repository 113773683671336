import "../utils/selector";
import global from "../global";

var _this;

/*
 * NOTE: Here are the apple pay versions which are available from a certain macOS and iOS version
 * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
 */
const VERSION = 7;

const DOMStrings = {
  appleButton: "#ckoApplePay",
  errorMessage: "#ckoApplePayError"
};

// High level configuration options.
const config = {
  payments: {
    acceptedCardSchemes: ["amex", "masterCard", "visa"]
  },
  shop: {
    shop_name: "",
    shop_localisation: {
      currencyCode: "",
      countryCode: ""
    }
  }
};

var applepay = {
  init: function() {
    _this = this;
    // Prepare the necessary checkout values for apple pay
    _this._prepareConfigValues();
    // Set the onClick listener on the Apple Pay button
    _this._setButtonClickListener();
  },

  displayApplePayButton: function() {
    $(DOMStrings.appleButton).show();
  },

  hideApplePayButton: function() {
    $(DOMStrings.appleButton).hide();
  },

  displayErrorMessage: function() {
    $(DOMStrings.errorMessage).show();
  },

  hideErrorMessage: function() {
    $(DOMStrings.errorMessage).hide();
  },

  /**
   * Checks if Apple Pay is possible in the current environment.
   * @return {boolean} Boolean to check if Apple Pay is possible
   */
  applePayAvailable: function() {
    return (
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(VERSION) &&
      ApplePaySession.canMakePayments()
    );
  },

  _prepareConfigValues: function() {
    let $appleButton = $(DOMStrings.appleButton);
    config.shop.shop_name = _this._ucFirst(
      $appleButton.attr("data-config-brand")
    );
    config.shop.shop_localisation.countryCode = $appleButton.attr(
      "data-config-country"
    );
    config.shop.shop_localisation.currencyCode = $appleButton.attr(
      "data-config-currency"
    );
  },

  /**
   * Starts the Apple Pay session using a configuration
   *
   * This is the main method of the script, since here we handle all the Apple Pay
   * events. Here you are able to populate your shipping methods, react to  shipping methods
   * changes, and many other interaction that the user has with the Apple Pay pup-up.
   */
  _startApplePaySession: function(config) {
    let applePaySession = new ApplePaySession(VERSION, config);

    // apple events
    applePaySession.onvalidatemerchant = function(event) {
      let appleUrl = event.validationURL;
      _this._validateApplePaySession(appleUrl, function(merchantSession) {
        applePaySession.completeMerchantValidation(merchantSession);
      });
    };

    // This method is the most important method. It gets triggered after the user has
    // confirmed the transaction with the Touch ID or Face ID. Besides getting all the
    // details about the customer (email, address ...) you also get the Apple Pay payload
    // needed to perform a payment.
    applePaySession.onpaymentauthorized = function(event) {
      _this._performTransaction(event.payment, function(response) {
        if (response.failureUrl !== undefined) {
          applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
          document.location.href = response.failureUrl;
        } else if (response.successUrl !== undefined) {
          applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
          document.location.href = response.successUrl;
        } else {
          applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
          document.location.reload();
          $(document).ready(function() {
            $(this).scrollTop(0);
          });
        }
      });
    };

    applePaySession.begin();
  },

  _validateApplePaySession: function(appleUrl, callback) {
    $.ajax({
      url: $(DOMStrings.appleButton).attr("data-apple-validate-url"),
      cache: false,
      type: "post",
      data: { validationUrl: appleUrl },
      dataType: "html",
      success: function(response) {
        callback(JSON.parse(response));
      },
      error: function(response) {
        console.log("An error occurs: " + response);
      }
    });
  },

  // here we talk to our backend to send the Apple Pay Payload and return the transaction outcome
  _performTransaction: function(details, callback) {
    $.ajax({
      url: $(DOMStrings.appleButton).attr("data-submit-form-url"),
      cache: false,
      type: "post",
      data:
        $("#stepModel").serialize() +
        "&applePayToken=" +
        encodeURIComponent(JSON.stringify(details.token.paymentData)),
      success: function(response) {
        callback(response);
      },
      error: function(response) {
        console.log("An error occurs: " + response);
      }
    });
  },

  /**
   * Sets a onClick listen on the Apple Pay button. When clicked it will
   * begin the Apple Pay session with your configuration
   */
  _setButtonClickListener: function() {
    $(DOMStrings.appleButton).on("click", function() {
      if (
        $("#stepModel")
          .parsley()
          .validate()
      ) {
        _this._startApplePaySession({
          currencyCode: config.shop.shop_localisation.currencyCode,
          countryCode: config.shop.shop_localisation.countryCode,
          merchantCapabilities: ["supports3DS"],
          supportedNetworks: config.payments.acceptedCardSchemes,
          shippingType: "shipping",
          total: {
            label: config.shop.shop_name,
            amount: _this._findTotalAmount(),
            type: "final"
          }
        });
      }
    });

    $(DOMStrings.appleButton).on("mouseover", function() {
      $.ajax({
        url: $(this).attr("data-remember-inputs-url"),
        method: "POST",
        headers: global.headers,
        data: $("#stepModel").serialize(),
        success: function(data) {}
      });
    });
  },

  _findTotalAmount: function() {
    let priceTotal = $(".se-checkout-validation-price-total").attr(
      "data-basket-total"
    );
    let $priceTotalOpenLabel = $(".se-checkout-validation-price-total-open");
    if ($priceTotalOpenLabel != null) {
      let priceTotalOpen = $priceTotalOpenLabel.attr("data-basket-total");
      if (typeof priceTotalOpen !== "undefined") {
        return priceTotalOpen;
      }
    }
    return priceTotal;
  },

  _ucFirst: function(string) {
    return (
      string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase()
    );
  }
};
export default applepay;
