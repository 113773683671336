export const ContinousSlider = function(_element) {
  if (_element.getAttribute("inited")) {
    return;
  }

  /**
   * @type {HTMLElement}
   */
  const element = _element;

  element.ContinousSlider = this;

  let autoSlide = false;

  /**
   * @param {MouseEvent} event
   */
  const onControlClick = function(event) {
    const controlElement = event.currentTarget;

    const itemsElement = element.querySelector(".items");

    let delta;
    if (controlElement.classList.contains("prev")) {
      delta = -itemsElement.clientWidth;
    } else {
      delta = itemsElement.clientWidth;
    }

    autoSlide = false;

    scrollBy(delta);
  };

  const scrollBy = function(delta) {
    itemsElement.scrollBy({
      top: 0,
      left: delta,
      behavior: "auto"
    });
  };

  const itemsElement = element.querySelector(".items");
  const firstItem = itemsElement.querySelector(".item:first-of-type");
  const lastItem = itemsElement.querySelector(".item:last-of-type");

  const arrowPrev = element.querySelector(".control.prev");
  arrowPrev.addEventListener("click", onControlClick);
  const arrowNext = element.querySelector(".control.next");
  arrowNext.addEventListener("click", onControlClick);

  const threshold = 1.0 - (itemsElement.childElementCount - 1) * 0.01;
  const options = {
    root: itemsElement,
    threshold: threshold
  };
  const callback = entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio >= threshold) {
        if (entry.target === firstItem) {
          arrowPrev.classList.add("disabled");
        } else if (entry.target === lastItem) {
          arrowNext.classList.add("disabled");
        }
      } else {
        if (entry.target === firstItem) {
          arrowPrev.classList.remove("disabled");
        } else if (entry.target === lastItem) {
          arrowNext.classList.remove("disabled");
        }
      }
    });
  };

  const intersectionObserver = new IntersectionObserver(callback, options);
  intersectionObserver.observe(firstItem);
  intersectionObserver.observe(lastItem);

  const onResize = () => {
    const controls = element.querySelector(".controls");
    if (itemsElement.scrollWidth > itemsElement.clientWidth) {
      controls.classList.remove("disabled");
    } else {
      controls.classList.add("disabled");
    }
  };

  const resizeObserver = new ResizeObserver(onResize);
  resizeObserver.observe(element);

  if (element.dataset.slideRhythm) {
    autoSlide = true;
    const timeout = parseInt(element.dataset.slideRhythm) * 1000;
    let triggeringElement = arrowNext.classList.contains("disabled")
      ? arrowPrev
      : arrowNext;

    let timeoutId;

    const trigger = () => {
      timeoutId = undefined;
      if (element.classList.contains("pausing")) {
        return;
      }

      if (triggeringElement.classList.contains("disabled")) {
        triggeringElement =
          triggeringElement === arrowNext ? arrowPrev : arrowNext;
      }

      let delta;
      if (triggeringElement.classList.contains("prev")) {
        delta = -itemsElement.clientWidth;
      } else {
        delta = itemsElement.clientWidth;
      }
      scrollBy(delta);

      if (autoSlide) {
        timeoutId = setTimeout(trigger, timeout);
      }
    };

    timeoutId = setTimeout(trigger, timeout);

    const onEnter = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }

      element.classList.add("pausing");

      element.addEventListener("pointerleave", onLeave);
    };

    const onLeave = () => {
      element.classList.remove("pausing");

      element.removeEventListener("pointerleave", onLeave);

      if (autoSlide) {
        timeoutId = setTimeout(trigger, timeout);
      }
    };

    element.addEventListener("pointerenter", onEnter);
  }

  element.setAttribute("inited", "");

  return this;
};

export default {
  init: function() {
    for (const continousSlider of document.querySelectorAll(
      ".continous-slider"
    )) {
      new ContinousSlider(continousSlider);
    }
  }
};
