/*globals define, document, window, $*/
import recaptcha from "../modules/recaptcha";
import "parsleyjs/dist/parsley";
import "../modules/slideup";
import "jquery-ui/ui/widgets/datepicker";
import global from "../global";

var self;
var productsActiveIndex;

export default {
  origFormButton: null,
  init: function() {
    self = this;
    $(document).on("click", ".js_individual_order_button", function(e) {
      e.preventDefault();
      productsActiveIndex = [];
      var $orderForm = $("#individualOrderModel");

      if (window.iShop.isStaff) {
        if ($orderForm.parsley().validate()) {
          self.submitForm();
        }
      } else {
        var recatpchaLength = grecaptcha.getResponse().length,
          $recatpchaError = $(".js-recatpcha-error");

        if (recatpchaLength === 0) {
          $recatpchaError.show();
        } else {
          $recatpchaError.hide();
        }
        if ($orderForm.parsley().validate() && recatpchaLength !== 0) {
          self.submitForm();
        }

        $.each($(".product-row.active"), function() {
          productsActiveIndex.push($(this).attr("data-product-list-index"));
        });
      }
    });

    $(document).on("blur", ".js-check-for-availibility", function() {
      self.availibilityCheck(
        $(this).val(),
        $(this).attr("data-availability-check-url")
      );
    });

    self.initBrandSuggest();
    self.addProductForm();
    self.deleteProductForm();
  },
  initDesignFormElements: function() {
    $("#js_individual_order .js-design-checkbox").SnsDesignCheckbox();
    $("#js_individual_order .js-design-radio").SnsDesignRadio();
    $("#js_individual_order .selector").SnsSelector();
    self.initBrandSuggest();
  },
  submitForm: function() {
    var origWrapper = $("#js_individual_order"),
      origForm = origWrapper.find("form");
    $.ajax({
      url: origForm.attr("action"),
      method: "POST",
      headers: global.headers,
      data: origForm.serialize(),
      dataType: "html",
      success: function(data) {
        // replace form
        origWrapper.replaceWith(data);
        self.initDesignFormElements();
        var identifier = $(data).find("[data-require-captcha]");
        $.each(identifier, function(i) {
          var recaptchaId = $(this).attr("id");
          recaptcha.renderRecaptcha(recaptchaId);
        });

        $.each(productsActiveIndex, function(key, value) {
          var productRow = $('[data-product-list-index="' + value + '"]');
          productRow.addClass("active");
        });
      },
      error: function(e) {
        if (window.console) {
          window.console.log(e);
        }
      }
    });
  },

  availibilityCheck: function(query, url) {
    if (query.length >= 6) {
      $.ajax({
        url: url,
        method: "GET",
        data: {
          id: query
        },
        success: function(response) {
          var data = $.parseJSON(response);
          if (data.success !== false) {
            self.fillProductSlideUp(data);
          } else {
            self._hideProductSlideup();
          }
        }
      });
    } else {
      self._hideProductSlideup();
    }
  },

  fillProductSlideUp: function(product) {
    var slideup = $(".slideup");

    slideup.find(".js-product-manufacturer").text(product.manufacturer);
    slideup.find(".js-product-title").text(product.name);
    slideup.find(".js-product-image").attr("src", product.imageUrl);
    slideup.find(".js-product-link").attr("href", product.productUrl);
    slideup.Slideup({
      cookies: {
        setCookie: false
      },
      displayOnScrollDown: true
    });
    slideup.addClass("position-up");

    $(document).on("click", ".js-icon-close", function() {
      self._hideProductSlideup();
      slideup.find(".js-product-title").text();
      slideup.find(".js-product-image").attr("src", "");
      slideup.find(".js-product-link").attr("href", "");
    });
  },

  _hideProductSlideup: function() {
    $(".slideup").removeClass("position-up");
  },

  addProductForm: function() {
    $(document).on("click", ".js-add-product-form", function() {
      var productRowLastActive = $(".product-list")
        .find(".product-row.active")
        .last();
      var productRowToShow = $(".product-list")
        .find(".product-row:not(.active)")
        .first();
      $(productRowLastActive).after($(productRowToShow));
      productRowToShow.addClass("active");
      productRowToShow.find("*[name]").attr("disabled", false);
      productRowToShow
        .find('[data-validate-required="false"]')
        .attr("data-validate-required", true);
    });
  },

  deleteProductForm: function() {
    $(document).on("click", ".js-delete-product", function() {
      var productRowToDelete = $(this).closest("[data-product-list-index]");
      productRowToDelete.removeClass("active");
      productRowToDelete.find("*[name]").attr("disabled", "disabled");
      productRowToDelete.find("*[name]").val("");
      productRowToDelete
        .find('[data-validate-required="true"]')
        .attr("data-validate-required", false);
    });
  },

  initBrandSuggest: function() {
    $.widget("custom.combobox", {
      _create: function() {
        this.wrapper = $("<span>")
          .addClass("custom-combobox")
          .insertAfter(this.element);

        this.element.hide();
        this._createAutocomplete();
        this._createShowAllButton();
      },

      _createAutocomplete: function() {
        var selected = this.element.children(":selected"),
          value = selected.val() ? selected.text() : "";
        this.input = $(".js-brand-suggest").autocomplete({
          delay: 0,
          classes: {
            "ui-autocomplete": "autocomplete-wrapper"
          },
          minLength: 0,
          appendTo: $(".js-autocomplete-brand-wrapper"),
          source: $.proxy(this, "_source")
        });

        this._on(this.input, {
          autocompleteselect: function(event, ui) {
            ui.item.option.selected = true;
            this._trigger("select", event, {
              item: ui.item.option
            });
          },

          autocompletechange: "_removeIfInvalid"
        });
      },

      _createShowAllButton: function() {
        var input = this.input,
          wasOpen = false;

        $("<a>")
          .attr("tabIndex", -1)
          .attr("title", "Show All Items")
          .appendTo(this.wrapper)
          .addClass("btn-show-more")
          .html("&or;")
          .on("mousedown", function() {
            wasOpen = input.autocomplete("widget").is(":visible");
          })
          .on("click", function() {
            input.trigger("focus");
            if (wasOpen) {
              return;
            }
            input.autocomplete("search", "");
          });
      },

      _source: function(request, response) {
        var matcher = new RegExp(
          $.ui.autocomplete.escapeRegex(request.term),
          "i"
        );
        response(
          this.element.children("option").map(function() {
            var text = $(this).text();
            if (this.value && (!request.term || matcher.test(text)))
              return {
                label: text,
                value: text,
                option: this
              };
          })
        );
      },

      _removeIfInvalid: function(event, ui) {
        if (ui.item) {
          return;
        }
        var value = this.input.val(),
          valueLowerCase = value.toLowerCase(),
          valid = false;
        this.element.children("option").each(function() {
          if (
            $(this)
              .text()
              .toLowerCase() === valueLowerCase
          ) {
            this.selected = valid = true;
            return false;
          }
        });

        if (valid) {
          return;
        }

        this.input.val("");
        this.element.val("");
        //this.input.parsley().validate();
        this.input.autocomplete("instance").term = "";
      },

      _destroy: function() {
        this.wrapper.remove();
        this.element.show();
      }
    });
    $("#combobox").combobox();
  }
};
