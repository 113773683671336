function Dashboard() {
  this._this = this;
}

Dashboard.prototype = {
  init: function() {
    let _this = this;
    _this.initFirstDashboardInnerGroup();
    _this.initMinimizeButtons();
  },

  initFirstDashboardInnerGroup: function() {
    let $firstDashboardInnerGroup = $(".dashboard-group.first-group").find(
      ".inner-group:first"
    );
    if (typeof $firstDashboardInnerGroup !== "undefined") {
      let $firstDashboardMinimizeButton = $firstDashboardInnerGroup.prev();
      $firstDashboardMinimizeButton.addClass("expand");
      $firstDashboardInnerGroup.show();
    }
  },

  initMinimizeButtons: function() {
    $(".js-minimize-button").each(function() {
      let $minimizeButton = $(this);
      $minimizeButton.click(function() {
        let $this = $(this);
        let minimizeButtonId = $minimizeButton.attr("id");
        let minimizeTableId = minimizeButtonId.replace("button", "table");
        let $minimizeTable = $("#" + minimizeTableId);
        if ($this.hasClass("expand")) {
          $this.removeClass("expand");
          $minimizeTable.hide();
        } else {
          $this.addClass("expand");
          $minimizeTable.show();
        }
      });
    });
  }
};
export default new Dashboard();
