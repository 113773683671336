(function($) {
  /**
   * @requires SnsImageFit
   * @type {Function}
   */
  var SnsMosaic = function(mosaic, options) {
    var self = this,
      conf = {
        defaultRatio: "1-1",
        defaultMobileRatio: "16-9",
        columnBaseClass: "column-",
        itemSelector: "js_mosaic-item",
        imageSelector: "js_mosaic-background",
        desktopStart: "802",
        imageClasses: {
          default: "mosaic-full-height",
          wide: "mosaic-full-width"
        }
      },
      desktopRatio = "",
      mobileRatio = "",
      ratio = "",
      wrapper = null,
      items = null,
      columnSpans = [],
      windowWidth = 0,
      resizeTimeout = null;

    /**
     *
     * @returns {SnsMosaic}
     * @private
     */
    function __construct() {
      conf = $.extend(conf, options);
      wrapper = $(mosaic);

      desktopRatio = conf.defaultRatio;
      if (wrapper.data().mosaicFormat) {
        desktopRatio = wrapper.data().mosaicFormat;
      }

      mobileRatio = conf.defaultMobileRatio;
      if (wrapper.data().mosaicMobileFormat) {
        mobileRatio = wrapper.data().mosaicMobileFormat;
      }

      initItems();

      initEvents();
      getItemSizes();

      return self;
    }

    /**
     *
     */
    function initEvents() {
      $(window).on("resize", function() {
        if (resizeTimeout !== null) {
          window.clearTimeout(resizeTimeout);
        }

        resizeTimeout = window.setTimeout(function() {
          resizeTimeout = null;

          getItemSizes();
        }, 100);
      });
    }

    /**
     *
     */
    function initItems() {
      items = wrapper.find("." + conf.itemSelector);

      items.SnsImageFit({
        imageSelector: "." + conf.imageSelector
      });
    }

    function updateWindowWidth() {
      windowWidth = $(window).width();
    }

    /**
     *
     */
    function getItemSizes() {
      resetItems();
      updateWindowWidth();

      if (windowWidth < conf.desktopStart) {
        ratio = mobileRatio;
      } else {
        ratio = desktopRatio;
      }

      for (var i = items.length; i--; ) {
        var currentItem = $(items[i]),
          columnSpan = getColumnSpan(currentItem.attr("class"));

        if (columnSpans.indexOf(columnSpan) === -1) {
          columnSpans.push(columnSpan);
        }
      }

      if (windowWidth < conf.desktopStart) {
        setItemsMobileWidth();
      }

      setItemsHeight();

      resizeImage();
    }

    /**
     *
     */
    function resizeImage() {
      for (var i = items.length; i--; ) {
        $(items[i])
          .data()
          .SnsImageFit.recalc();
      }
    }

    /**
     *
     */
    function setItemsMobileWidth() {
      var biggest = 0;

      for (var c = columnSpans.length; c--; ) {
        if (columnSpans[c] > biggest) {
          biggest = columnSpans[c];
        }
      }

      var biggestSelector = "." + conf.columnBaseClass + biggest;

      items.filter(biggestSelector).css("width", "100%");
      items.not(biggestSelector).css("width", "50%");
    }

    /**
     *
     */
    function setItemsHeight() {
      for (var c = columnSpans.length; c--; ) {
        var currentItems = items.filter(
          "." + conf.columnBaseClass + columnSpans[c]
        );

        currentItems.height(getItemHeight(currentItems.innerWidth()));
      }
    }

    /**
     *
     */
    function resetItems() {
      for (var i = items.length; i--; ) {
        var currentItem = $(items[i]),
          currentStyle = currentItem.attr("style"),
          singleStyles =
            typeof currentStyle === "undefined" ? [] : currentStyle.split(";"),
          newStyles = [],
          newStyleAttr;

        for (var x = singleStyles.length; x--; ) {
          currentStyle = $.trim(singleStyles[x]);

          if (
            !new RegExp("^(width|height):").test(currentStyle) &&
            currentStyle !== ""
          ) {
            newStyles.push(currentStyle);
          }
        }

        newStyleAttr = newStyles.join(";");

        if (newStyleAttr === "") {
          currentItem.removeAttr("style");
        }

        currentItem.attr("style", newStyleAttr);
      }
    }

    /**
     *
     * @param {number} itemWidth
     * @returns {number}
     */
    function getItemHeight(itemWidth) {
      var ratioParts = ratio.split("-");

      return itemWidth / (ratioParts[0] / ratioParts[1]);
    }

    /**
     *
     * @param {string} classString
     * @returns {number}
     */
    function getColumnSpan(classString) {
      var regExp = new RegExp("(?:^|[^_-])column-([0-9]+)\\b", "g"),
        results = regExp.exec(classString),
        columnSpan = results[1];

      if (parseInt(columnSpan, 10) != columnSpan) {
        return 1;
      }

      return columnSpan;
    }

    return __construct();
  };

  $.fn.extend({
    SnsMosaic: function(options) {
      return $(this).each(function() {
        new SnsMosaic($(this), options);
      });
    }
  });
})(jQuery);
