var SnsSelector = function(element) {
  var self = this,
    selectorWrap = null,
    header = null,
    itemList = null,
    selectedValueWrap = null,
    fakeOptions = [],
    realSelect = null,
    realOptions = [],
    isDisabled = false,
    usesRealSelect = false,
    isOpen = false;

  /**
   *
   * @returns {SnsSelector}
   * @private
   */
  function __construct() {
    selectorWrap = $(element);
    header = selectorWrap.find(".header");

    itemList = selectorWrap.find("ul");
    if (itemList.length === 0) {
      itemList = $("<ul>");
      header.after(itemList);
    }

    realSelect = selectorWrap.find("select");
    selectedValueWrap = header.find(".js_selected_name");

    if (realSelect.length) {
      usesRealSelect = true;
      initRealSelect();
    }
    initEvents();

    return self;
  }

  /**
   *
   */
  function initEvents() {
    header.on("click", function() {
      if (isDisabled) {
        return;
      }

      if (isOpen) {
        close();
      } else {
        open();
      }
    });

    selectorWrap.on("mouseleave", close);

    if (usesRealSelect) {
      for (var i = fakeOptions.length; i--; ) {
        fakeOptions[i].on(
          "click",
          {
            currentIndex: i
          },
          fakeOptionChange
        );
      }

      realSelect.on("change", function() {
        for (var r = realOptions.length; r--; ) {
          if (realOptions[r].is(":selected")) {
            updateSelectedOption(r);
            break;
          }
        }
      });
    }
  }

  /**
   *
   * @param {Object} event
   */
  function fakeOptionChange(event) {
    if (usesRealSelect) {
      event.preventDefault();
    }

    updateSelectedOption(event.data.currentIndex);

    selectorWrap.trigger("selectchange");
  }

  /**
   *
   * @param {number} index
   */
  function updateSelectedOption(index) {
    selectedValueWrap.text(fakeOptions[index].text());
    realSelect.val(realOptions[index].attr("value"));

    for (var i = fakeOptions.length; i--; ) {
      fakeOptions[i].removeClass("selected");
      if (i == index) {
        fakeOptions[i].addClass("selected");
      }
    }

    close();
  }

  /**
   *
   */
  function initRealSelect() {
    itemList.html("");

    var options = realSelect.find("option");

    if (
      realSelect.attr("disabled") ||
      realSelect.attr("readonly") ||
      realSelect.data("readonly") === true
    ) {
      selectorWrap.addClass("disabled");
      isDisabled = true;
    }

    for (var i = 0; i < options.length; i++) {
      var currentRealOption = $(options[i]),
        currentFakeOption = $("<a>"),
        currentFakeOptionWrap = $("<li>");

      currentFakeOption.text(currentRealOption.text());
      realOptions.push(currentRealOption);
      fakeOptions.push(currentFakeOption);

      currentFakeOptionWrap.append(currentFakeOption);
      currentFakeOptionWrap.appendTo(itemList);

      if (realSelect.data("allow-noselection")) {
        if (currentRealOption.attr("selected")) {
          updateSelectedOption(i);
        }
      } else {
        //if no selection the first elements selected-prop is true as default
        if (currentRealOption.is(":selected")) {
          updateSelectedOption(i);
        }
      }
    }
  }

  /**
   *
   */
  function open() {
    if (!isOpen) {
      isOpen = true;
      selectorWrap.addClass("open");
      itemList.slideDown(200);
    }
  }

  /**
   *
   */
  function close() {
    if (isOpen) {
      isOpen = false;
      selectorWrap.removeClass("open");
      itemList.slideUp(200);
    }
  }

  return __construct();
};

$.fn.extend({
  SnsSelector: function() {
    return $(this).each(function() {
      new SnsSelector(this);
    });
  }
});
