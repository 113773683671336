import quickview from "../modules/quickview";
import lazyload from "../modules/lazyload";
import "../plugin/snsslider";
import "../plugin/lightcase";
import "../plugin/tooltips";
import "../utils/imagesLoaded";

var self;

var hover = {
  init: function() {
    self = this;

    lazyload.init({
      selector: ".js-category .js-unveil-list"
    });

    $(document).on("mouseenter", ".js-variant", function(e) {
      const variantJson = $.parseJSON($(this).attr("data-variationsJson"))[
        "variant"
      ];
      const $box = $(this).closest(".js-product-holder");
      self.updatepriceInfo($box, variantJson);
      self.updateMainImage($box, $(this), variantJson);
      self.updateSizes($box, variantJson);
      self.updateDisrupter($box, variantJson);
    });

    $(document).on("mouseenter", ".js-product-holder", function() {
      const $this = $(this);

      if (this.classList.contains("hoverable")) {
        const variantsSlider = $this.find(".js_variants-slider");
        variantsSlider.SnsSlider({
          slidesPerPage: 3,
          slidesPerScroll: 1,
          controls: {
            content: {
              prev: "",
              next: ""
            },
            outer: true
          },
          pageIndicator: {
            enable: false
          }
        });

        const variantThumbs = $this.find(".js_variants-thumb");
        variantThumbs.unveil();

        $this.find(".variants-slider").css("opacity", "1");
      }
    });

    $(document).on("mouseleave", ".js-product-holder", function() {});

    $(document).on("click", ".js-product-qv", function(e) {
      var itemId = $(this).attr("data-itemId");
      $.ajax({
        url: window.iShop.config.baseUrl + "/shop/quickview/?itemId=" + itemId,
        dataType: "html",
        success: function(data) {
          lightcase.start({
            href: "",
            maxWidth: 900,
            maxHeight: 1500,
            speedIn: 100,
            shrinkFactor: 1.2,
            inline: {
              width: "800"
            },
            onFinish: {
              injectContent: function() {
                lightcase
                  .get("contentInner")
                  .children()
                  .html(data)
                  .FenixImagesLoaded()
                  .then(function() {
                    lightcase.resize();
                    lightcase.get("contentInner").css({
                      opacity: 1
                    });
                  });
                quickview.quickviewStart();
              }
            }
          });
        },
        error: function(e) {
          if (window.console) {
            window.console.log(e);
          }
        }
      });
    });
    quickview.init();
  },

  updateMainImage: function($box, $linkElement, variantJson) {
    var $mainImage = $box.find(".js-main-list-image");
    var $mainDataAttribute = $box.find(".productCardContent");
    var oldImageUrl = $mainImage.attr("src");
    var oldImagePath = oldImageUrl.substring(
      0,
      oldImageUrl.lastIndexOf("/") + 1
    );

    $mainDataAttribute.attr(
      "data-tracking",
      $linkElement.closest(".js_slide-element").attr("data-tracking")
    );
    var newImageUrl = oldImagePath + variantJson.image;
    var href = $linkElement.attr("href");
    $mainImage.attr("src", newImageUrl);
    $mainImage.closest("a").attr("href", href);
    $box
      .find(".js-product-qv")
      .attr("data-itemId", $linkElement.attr("data-itemId"));
    $box
      .find(".js_add-to-watchlist-button")
      .attr("href", href + "#add-to-wishlist");
  },

  updatepriceInfo: function($box, variantJson) {
    var omniPriceSettingsModel = variantJson.omniPriceSettingsModel;
    var isOutlet = variantJson.isOutlet;
    var isPriceDisplayVariant2 =
      omniPriceSettingsModel.priceDisplayVariant === "variant2";
    var showLowestRetailPrice = omniPriceSettingsModel.showLowestRetailPrice;
    var saleClass = variantJson.oldPrice && !isOutlet ? "is-sale" : "";
    var outletClass =
      variantJson.isOutlet && !isPriceDisplayVariant2 ? "outlet" : "";
    var stroke =
      variantJson.oldPrice && !variantJson.isOutlet
        ? '<span class="stroke">' + variantJson.oldPrice + "</span>"
        : "";

    var priceText = "";
    if (isOutlet) {
      if (isPriceDisplayVariant2) {
        if (showLowestRetailPrice) {
          priceText =
            '<div class="price-text"> <span class="lowest-retail-price">' +
            variantJson.lrpSubline +
            "</span>" +
            variantJson.outletSubline +
            "</div>";
        } else {
          priceText =
            '<div class="price-text">' + variantJson.outletSubline + "</div>";
        }
      }
    } else if (variantJson.oldPrice && showLowestRetailPrice) {
      priceText =
        '<div class="price-text"> <span class="lowest-retail-price">' +
        variantJson.lrpSubline +
        "</span> </div>";
    } else {
      priceText = '<div class="price-text"> </div>';
    }
    var basePrice = variantJson.basePrice.hasBasePrice
      ? '<span class="unit-info">(' +
        variantJson.basePrice.measurement +
        "&nbsp;" +
        variantJson.basePrice.unit +
        " = " +
        variantJson.basePrice.amountValueWithCurrency +
        ")</span>"
      : "";
    var strikePricePosition = omniPriceSettingsModel.strikePricePosition;
    var price_wrapper;
    if (strikePricePosition === "left") {
      price_wrapper =
        '<div class="price-wrapper ' +
        saleClass +
        '">' +
        stroke +
        '<span class="price  ' +
        outletClass +
        '">' +
        variantJson.price +
        "</span>" +
        basePrice +
        "</div>";
    } else {
      price_wrapper =
        '<div class="price-wrapper ' +
        saleClass +
        '">' +
        '<span class="price  ' +
        outletClass +
        '">' +
        variantJson.price +
        "</span>" +
        basePrice +
        stroke +
        "</div>";
    }
    $box.find(".price-wrapper").replaceWith(price_wrapper);
    $box.find(".price-text").replaceWith(priceText);
  },

  updateDisrupter: function($box, variantJson) {
    {
      const $productCard = $box[0];
      const awardBadges = $productCard.querySelector(".award-badges");
      awardBadges.querySelectorAll(".badge").forEach(award => {
        if (!award.dataset.length) {
          return;
        }

        if (award.dataset.itemId === variantJson.itemId) {
          award.classList.remove("hide");
        } else {
          award.classList.add("hide");
        }
      });

      const saleBadges = $productCard.querySelector(".sale-badges");
      const badge = saleBadges.querySelector(".badge");
      badge.classList.value = "badge";

      // do not show both, sale is prior, outlet too
      if (variantJson.isOutlet) {
        badge.classList.add("outlet");
        badge.firstChild.textContent = JSON.parse(
          saleBadges.dataset.translations
        ).outlet;
      } else if (variantJson.savings) {
        badge.classList.add("sale");
        badge.firstChild.textContent = variantJson.savings;
      } else if (variantJson.isnew) {
        badge.classList.add("new");
        badge.firstChild.textContent = JSON.parse(
          saleBadges.dataset.translations
        ).new;
      } else {
        badge.classList.add("hide");
      }
    }

    const awardBadges = $box.find(".js_disrupter-award");
    //hide/display the correct awardBadge
    for (let i = awardBadges.length - 1; i >= 0; i--) {
      if (awardBadges[i].id === variantJson.itemId) {
        awardBadges[i].classList.remove("hide");
      } else {
        awardBadges[i].classList.add("hide");
      }
    }

    // do not show both, sale is prior, outlet too
    var OUTLET_CLASS = ".js-outlet",
      SALE_CLASS = ".js-sale",
      NEW_CLASS = ".js-new";

    if (variantJson.isOutlet) {
      if ($box.find(OUTLET_CLASS).length <= 0) {
        $box
          .find(".product-inner-wrap")
          .prepend(
            self.createDisrupter(
              "js-outlet",
              self.getBadgeTranslations().translations.outlet
            )
          );
      }
      $box.find(OUTLET_CLASS).removeClass("hide");
      $box.find(SALE_CLASS).addClass("hide");
      $box.find(NEW_CLASS).addClass("hide");
    } else {
      if (variantJson.savings) {
        $box
          .find(SALE_CLASS)
          .removeClass("hide")
          .text(variantJson.savings);
        $box.find(NEW_CLASS).addClass("hide");
        $box.find(OUTLET_CLASS).addClass("hide");
      } else {
        $box.find(SALE_CLASS).addClass("hide");
        $box.find(OUTLET_CLASS).addClass("hide");
        if (variantJson.isnew) {
          $box.find(NEW_CLASS).removeClass("hide");
        } else {
          $box.find(NEW_CLASS).addClass("hide");
        }
      }
    }
  },

  createDisrupter: function(additionalClasses, text) {
    return $("<div />")
      .addClass("sale-badge disrupter " + additionalClasses)
      .text(text);
  },

  getBadgeTranslations: function() {
    return $("[data-translations]").data();
  },

  updateSizes: function($box, variantJson) {
    $box.find(".js-size").each(function() {
      var size = $(this).text();
      if ($.inArray(size, variantJson.sizes) === -1) {
        $(this).addClass("hide");
      } else {
        $(this).removeClass("hide");
      }
    });
  }
};

export default hover;
