/*globals define, document, window, */
var Datalayer = function() {
  var _self = this;

  _self._isDataLayerAvailable = function() {
    return "undefined" !== typeof window.dataLayer;
  };

  _self._log = function(msg, obj) {
    if (window.console) {
      if (msg) {
        window.console.log(msg);
      }
      if (obj) {
        window.console.log(obj);
      }
    }
  };

  _self._getBasePath = function() {
    return $("#trackingDataLayer").data("base-path");
  };

  _self._pushToDatalayer = function(data) {
    if (!isEmpty(data)) {
      window.dataLayer.push(data);
    }

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
  };

  _self._getJsonAndPushToDatalayer = function(
    ajaxUrl,
    optPromiseAlwaysCallback
  ) {
    $.ajax({
      url: ajaxUrl,
      cache: false,
      method: "GET"
    })
      .then(function(jsonObject) {
        if (false !== jsonObject) {
          _self._pushToDatalayer(jsonObject);
        }
      })
      .catch(function(error) {
        _self._log("error on getting JSON for dataLayer", error);
      })
      .always(function() {
        if ("function" === typeof optPromiseAlwaysCallback) {
          optPromiseAlwaysCallback();
        }
      });
  };

  /* callback for tracking user interaction: add item to cart */
  _self.addToCart = function(
    itemId,
    changingAmount,
    isClickAndCollect,
    optPromiseAlwaysCallback
  ) {
    var htmlId = "#" + itemId;
    var currentQuantity = $(htmlId).val();
    if (currentQuantity === "") {
      currentQuantity = "undefined";
    }
    if (!_self._isDataLayerAvailable()) {
      _self._log("dataLayer not available");
      return;
    }
    var ajaxUrl =
      _basePath +
      "datalayer/json/cart/add/sku/" +
      itemId +
      "/changed-quantity/" +
      parseInt(changingAmount) +
      "/current-quantity/" +
      currentQuantity +
      "/is-click-collect/" +
      isClickAndCollect +
      "/";
    _self._getJsonAndPushToDatalayer(ajaxUrl, optPromiseAlwaysCallback);
  };

  /* callback for tracking user interaction: remove item from cart */
  _self.removeFromCart = function(
    itemId,
    changingAmount,
    optPromiseAlwaysCallback
  ) {
    var htmlId = "#" + itemId;
    var currentQuantity = $(htmlId).val();
    var ajaxUrl =
      _basePath +
      "datalayer/json/cart/remove/sku/" +
      itemId +
      "/changed-quantity/" +
      parseInt(changingAmount) +
      /current-quantity/ +
      currentQuantity +
      "/";
    _self._getJsonAndPushToDatalayer(ajaxUrl, optPromiseAlwaysCallback);
  };

  _self.updateVoyadoCartTracking = function() {
    if (window.va) {
      var ajaxUrl = _basePath + "ajax/basket/";
      $.ajax({
        url: ajaxUrl,
        cache: false,
        method: "GET"
      }).then(function(jsonObject) {
        if (false !== jsonObject) {
          var cartData = jsonObject.cartData.cart;
          var itemsJson = [];
          let itemList = cartData.products;

          for (let i = 0; i < itemList.length; i++) {
            var itemId;
            if (
              window.iShop.currentBrand === "naturkompaniet" ||
              window.iShop.currentBrand === "partioaitta"
            ) {
              itemId = itemList[i].item;
            } else {
              itemId = itemList[i].ean;
            }
            var quantity = itemList[i].quantity;
            var itemJson = {
              itemId: itemId,
              quantity: quantity
            };
            itemsJson.push(itemJson);
          }

          window.va("cart", {
            cartRef: cartData.cartId,
            locale: jsonObject.locale,
            items: itemsJson
          });
        }
      });
    }
  };

  var _basePath = _self._getBasePath();
};

export default new Datalayer();
