let frontend_notifier = {
  addFrontendNotifier: () => {
    if (
      location.hostname.includes("localhost") ||
      location.hostname === "127.0.0.1"
    ) {
      const domString = frontend_notifier.makeElementString();
      let bodyElm = document.body;
      let localhostElm = frontend_notifier.htmlToElement(domString);
      bodyElm.appendChild(localhostElm);
    }
  },

  htmlToElement: html => {
    let template = document.createElement("div");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.firstChild;
  },

  makeElementString: () => {
    return `
        <div class="front-end-dev-notifier">
          <p class="fd-notifier-text">FD</p>
          <p class="fd-notifier-text-hover">Front-end Development Mode!</p>
        </div>
      `;
  }
};

export default frontend_notifier;
