import "../utils/selector";

// import 'selector';
var _this;
var disabledSuffix = "-disabled";
var encryptedFieldNames = [
  "generationtime",
  "holderName",
  "number",
  "cvc",
  "expiryMonth",
  "expiryYear"
];
var oneClickEncryptedFieldNames = encryptedFieldNames.filter(
  elem => elem !== "cvc" && elem !== "generationtime"
);
var recurringEncryptedFieldNames = encryptedFieldNames.filter(
  elem => elem !== "generationtime"
);
var disabledOneClickEncryptedFieldNames = encryptedFieldNames.map(
  value => value + disabledSuffix
);
var radioButtonId =
  '[id^="paymentViewModel.adyenPaymentViewModel.recurringDetailReference"],#radio-bezahlung-kreditkarte-new';

var adyenPayment = {
  init: function() {
    _this = this;
    var adyen,
      creditCardLibraryUrl = $(".adyen-creditcard").attr("data-adyen-liburl");
    _this.initCreditCardEvents();
    $.getScript(creditCardLibraryUrl, function() {
      _this.initEncryptedForm();
    });
  },

  initEncryptedForm: function() {
    var form = document.getElementById("stepModel");
    var options = {
      name: "paymentViewModel.adyenPaymentViewModel.adyenEncryptedData",
      submitButtonAlwaysEnabled: true,
      enableValidations: false,
      onsubmit(e) {
        $(".js-spinner").attr("disabled", "true");
        $(".js-spinner")
          .off("click")
          .on("click", e => true);
      }
    };
    adyen.createEncryptedForm(form, options);
  },

  enableCreditCardForms: function() {
    var adyenPaymentInputs = $("[data-encrypted-name]");
    $.each(adyenPaymentInputs, function() {
      var inputValue = $(this);
      var encryptedName = $(this).attr("data-encrypted-name");
      var indexInArray = $.inArray(
        encryptedName,
        disabledOneClickEncryptedFieldNames
      );
      if (encryptedName.endsWith(disabledSuffix) && indexInArray > -1) {
        inputValue.prop("disabled", false);
        inputValue.attr(
          "data-encrypted-name",
          encryptedFieldNames[indexInArray]
        );
      }
    });
    $("#checkout-payment-type-box .selector").removeClass("disabled");
    $("#checkout-payment-type-box .selector").SnsSelector();
  },
  disableCreditCardForms: function(oneClickType) {
    var encryptedFieldNames =
      oneClickType === "ONECLICK"
        ? oneClickEncryptedFieldNames
        : recurringEncryptedFieldNames;
    var adyenPaymentInputs = $("[data-encrypted-name]");
    $.each(adyenPaymentInputs, function() {
      var inputValue = $(this);
      var encryptedName = $(this).attr("data-encrypted-name");
      if ($.inArray(encryptedName, encryptedFieldNames) > -1) {
        inputValue.prop("disabled", true);
        inputValue.attr("data-encrypted-name", encryptedName + "-disabled");
      }
    });
    $("#checkout-payment-type-box .selector .header").unbind();
    $("#checkout-payment-type-box .selector").addClass("disabled");
  },

  initCreditCardEvents: function() {
    $(radioButtonId).click(function() {
      var radioButton = $(this);
      var radioButtonValue = radioButton.val();
      var hiddenInput = $(
        "input[name=paymentViewModel\\.adyenPaymentViewModel\\.oneClickType]"
      );
      if ("" === radioButtonValue) {
        hiddenInput.val("");
        _this.enableCreditCardForms();
      } else {
        var oneClickType = radioButton.attr("data-type");
        hiddenInput.val(oneClickType);
        _this.disableCreditCardForms(oneClickType);
      }
    });
  }
};

export default adyenPayment;
