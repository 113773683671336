/**
 * Loads the recaptcha-Script from google
 */

import config from "../config/config";
import importer from "../utils/external_script_loader";

export default {
  init: function() {
    window.onloadCallback = function() {
      var identifier = $(document).find("[data-require-captcha]");
      $.each(identifier, function(i) {
        var recaptchaId = $(this).attr("id");
        grecaptcha.render(recaptchaId, {
          sitekey: $(".g-recaptcha").attr("data-sitekey")
        });
      });
    };
    // gt.require([config.google.recatpcha.script]);
    importer.urls([config.google.recatpcha.script]);
  },

  renderRecaptcha: function(captchaId) {
    // render new recaptcha widget after form replacement
    grecaptcha.render(captchaId, {
      sitekey: $(".g-recaptcha").attr("data-sitekey")
    });
  }
};
