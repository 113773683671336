import ads from "../modules/ads";
import klarnaPaymentWidget from "../modules/klarnaPaymentWidget";
import imagezoom from "../modules/imagezoom";
import "../plugin/watchlistlayer";

var ctx = {
  view: "quickview"
};
var _self = this;
export default {
  init: function() {
    _self = this;

    $(document).on("click", ".quickview-context .add-to-cart", function(e) {
      e.preventDefault();
      ads.submit(ctx);
    });

    $(document).on("click", ".quickview-context .js-variation-item", function(
      e
    ) {
      e.preventDefault();
      if ($(this).hasClass("is-active")) {
        return;
      }
      ads.toggelSelection($(this), ctx);
      ads.changeVariation($(this), ctx);
      lightcase.resize();
    });
  },

  quickviewStart: function() {
    ads.initWatchlist(ctx);
    imagezoom.init(ctx.view);
    imagezoom.quickviewImageSwipe();
    klarnaPaymentWidget.init();
    $(".js-unveil-thumb").unveil();
    lightcase.resize();
  }
};
