import noUiSlider from "../../../../../../../../fenix-shop/node_modules/nouislider/distribute/nouislider";
import wNumb from "../../../../../../../../fenix-shop/node_modules/wnumb/wNumb";

export default {
  init: function() {
    for (const filterSliderElement of document.querySelectorAll(
      ".js_filterSlider"
    )) {
      this.initNoUiRangeSlider(
        filterSliderElement,
        filterSliderElement.parentElement.querySelector(".js_minValue"),
        filterSliderElement.parentElement.querySelector(".js_maxValue"),
        Number(filterSliderElement.dataset.minValue),
        Number(filterSliderElement.dataset.maxValue),
        filterSliderElement.dataset.decimal === "true" ? 1 : 0
      );
    }
  },
  initNoUiRangeSlider: function(
    slider,
    minElement,
    maxElement,
    totalMin,
    totalMax,
    decimals
  ) {
    if (totalMin === totalMax) {
      return;
    }

    noUiSlider.create(slider, {
      start: [minElement.value, maxElement.value],
      connect: true,
      range: {
        min: totalMin,
        max: totalMax
      },
      format: wNumb({
        decimals: decimals
      })
    });

    const anchor = slider.parentElement.querySelector("a");

    minElement.addEventListener("change", function() {
      slider.noUiSlider.setHandle(0, this.value);
    });

    maxElement.addEventListener("change", function() {
      slider.noUiSlider.setHandle(1, this.value);
    });

    slider.noUiSlider.on("update", function(values) {
      const min = Number(values[0]);
      minElement.value = min;
      const max = Number(values[1]);
      maxElement.value = max;

      const value = min === totalMin && max === totalMax ? "" : min + "-" + max;

      let url = new URL(anchor.href);
      const params = url.searchParams;

      if (!value.length) {
        params.delete(`filter.${anchor.dataset.filter}`);
      } else {
        params.set(`filter.${anchor.dataset.filter}`, value);
      }

      anchor.href = url.toString();
    });
  }
};
