/*globals define, document, window, $, lightcase */

import qrcode from "../modules/qrcode";

function qrcodebuilder() {}

qrcodebuilder.prototype = {
  init: function() {
    self = this;
    self.buildQrCode();
  },

  buildQrCode: function() {
    var qrCodeText = "";
    var data = $("#generate-qr-code").data();
    if (typeof data !== "undefined") {
      qrCodeText = data["qrvalue"].toString();
    }

    var qr = qrcode(4, "H");
    qr.addData(qrCodeText);
    qr.make();
    document.getElementById("generate-qr-code").innerHTML = qr.createImgTag(5);
  }
};

export default new qrcodebuilder();
