(function($) {
  /**
   * Currently the RequestSpinner is very simple and just for
   * non ajax request. But it can be easily extended for ajax request.
   * For ajax request it is required to catch the response and enable the
   * button on page again. A distinction between non ajax and ajax request
   * can be made due to a option field.
   * The corresponding request spinner markup looks like this:
   * <div class="loading-spinner">
   *   <svg version="1.1"
   *     id="loader-1"
   *     xmlns="http://www.w3.org/2000/svg"
   *     x="0px"
   *     y="0px"
   *     width="20px"
   *     height="20px"
   *     viewBox="0 0 50 50"
   *     style="enable-background:new 0 0 50 50;"
   *     xml:space="preserve">
   *     <path fill="#fff"
   *       d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,
   *       8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,
   *       0,14.615,6.543,14.615,14.615H43.935z">
   *       <animateTransform attributeType="xml"
   *         attributeName="transform"
   *         type="rotate"
   *         from="0 25 25"
   *         to="360 25 25"
   *         dur="0.6s"
   *         repeatCount="indefinite">
   *       </animateTransform>
   *     </path>
   *   </svg>
   * </div>
   * The request spinner markup must be the first child of enclosing tag
   * which triggers the request.
   */
  var requestSpinner = function(element, options) {
    var self = this;
    var wrapper;
    var $spinner = $(element[0].firstElementChild);
    $spinner.hide();

    this.setOn = function() {
      element.prop("disabled", true);
      $spinner.show();
    };

    this.setOff = function() {
      element.prop("disabled", false);
      $spinner.hide();
    };

    function __construct() {
      wrapper = $(element);

      if (wrapper.data().requestSpinner) {
        return wrapper.data().requestSpinner;
      }

      if ((options.elementType = "form")) {
        $(document).on("submit", "form", function() {
          element.prop("disabled", true);
          $spinner.show();
        });
      }

      wrapper.data("requestSpinner", self);
      return self;
    }

    return __construct();
  };

  $.fn.extend({
    requestSpinner: function(options) {
      return $(this).each(function() {
        new requestSpinner($(this), options);
      });
    }
  });
})(jQuery);
