import "../utils/imagesLoaded";
import "../plugin/texttocanvas";
import datalayer from "../modules/datalayer";
import ads from "../modules/ads.js";
import global from "../global";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

var _this, map, $container, optionList, interimsStoreDataStorage;
var storeLocation = {
  init: function() {
    _this = this;

    $(document).on(
      "click",
      ".js-mobile-collapse button.js-show-map",
      function() {
        $(this).text(
          $(this).text() == $(this).attr("data-open-text")
            ? $(this).attr("data-close-text")
            : $(this).attr("data-open-text")
        );
        $("#findStoreMap").slideToggle("fast", function() {});
      }
    );

    $(document).on("click", ".js-store-availability-layer", function(e) {
      e.preventDefault();
      lightcase.start({
        href: $(this).attr("data-href"),
        showSequenceInfo: false,
        type: "ajax",
        ajax: {
          width: window.innerWidth * 0.8,
          height: window.innerHeight * 0.9
        },
        maxWidth: 1200,
        maxHeight: 750,
        shrinkFactor: 1,
        onFinish: {
          injectContent: function() {
            if (
              typeof google != "undefined" &&
              typeof google.maps != "undefined"
            ) {
              _this.initMap();
            } else {
              $.getScript(
                "https://maps.google.com/maps/api/js?key=AIzaSyC9x6A50aPGn5dsr_RNr6pjqvgICKIwW3M&callback",
                function() {
                  _this.initMap();
                }
              );
            }
            $(".selector").SnsSelector();
            $container = $(".js-store-availability-finder");
            optionList = $container.find("ul.options");
            $container.find(".js_color-slider").SnsSlider({
              controls: {
                generate: false
              },
              pageIndicator: {
                enable: false
              },
              shortListPosition: "left",
              isClickAndCollect: true,
              breakpoints: {
                339: {
                  slidesPerPage: 3,
                  slidesPerScroll: 3
                },
                417: {
                  slidesPerPage: 4,
                  slidesPerScroll: 4
                },
                501: {
                  slidesPerPage: 5,
                  slidesPerScroll: 5
                },
                581: {
                  slidesPerPage: 6,
                  slidesPerScroll: 6
                },
                665: {
                  slidesPerPage: 6,
                  slidesPerScroll: 6
                }
              }
            });
          }
        }
      });
    });

    $(document).on("click", ".js-show-store-on-map", function() {
      let $storeEntry = $(this);
      let isChangeFormToClickAndCollect =
        $storeEntry.find(".open-click-collect").length > 0;
      if (isChangeFormToClickAndCollect) {
        // Click should change the Store layer to Click and Collect layer
        _this.changeFormToClickAndCollect($storeEntry.attr("data-store-id"));
      } else if ($("#isClickAndCollectOverlay").val() === "true") {
        // Store details should not be shown for Click and Collect layer
        let radioButton = document.getElementById(
          "radio-" + $storeEntry.attr("data-store-id")
        );
        radioButton.click();
      } else {
        // chooseStoreAjax is checked so that "Choose store" can be clicked on Store layer
        if (!$(event.target).is("#chooseStoreAjax")) {
          let storeId = $storeEntry.attr("data-store-id"),
            wrapper = $(".js-store-list-details-wrapper"),
            detailsCard = $(".js-store-details");

          wrapper.addClass("details-active");
          detailsCard.find('[data-store-id="' + storeId + '"]').show();
          lightcase.resize();

          let data = $storeEntry.data();
          let latLng = new google.maps.LatLng(
            data.mapLatidude,
            data.mapLongitude
          );
          map.panTo(latLng);
          map.setZoom(13);
        }
      }
    });

    $(document).on("click", ".js-back-to-storelist", function() {
      let wrapper = $(".js-store-list-details-wrapper"),
        detailsCard = $(".js-store-details");

      wrapper.removeClass("details-active");
      detailsCard.find("[data-store-id]").hide();

      _this.initMap({
        refreshOnly: true
      });
      lightcase.resize();
    });

    $(document).on("selectchange", ".js-city-selector", function(e) {
      e.preventDefault();
      _this.replaceForm("city", true);
    });

    $(document).on("click", ".js-store-info-near-to-you", function(e) {
      document.getElementById("isClickAndCollectOverlay").value = "false";
      document.getElementById("button-container").classList.add("hide");
      _this.replaceForm("ip");
    });

    $(document).on(
      "click",
      ".js-store-availability-finder .js-variation-item",
      function() {
        let inputData = $(this).data();
        $(document)
          .find('.js-variation-item[data-name="' + inputData.name + '"]')
          .removeClass("is-active");
        $(this).addClass("is-active");
        $(this)
          .next()
          .prop("checked");
        _this.replaceForm("city");
      }
    );

    $(document).on("click", ".js-store-variation-change", function() {
      let inputData = $(this).data();
      $container.find(".js-color-value").text(inputData.display);
      $container
        .find('.js-store-variation-change[data-name="' + inputData.name + '"]')
        .removeClass("is-active");

      $(this).addClass("is-active");
      $(this)
        .next()
        .click();

      let selectedOptionValue = optionList.find("input:checked").val();
      optionList.empty(); // required, if deactivated, the sizes are available multiple times

      let availableSizesForColor = inputData.variations.size;
      $.each(availableSizesForColor, function(key, value) {
        $container
          .find("ul.options")
          .append(createListItem(value, inputData.name));
      });

      let selectedOptionElement = optionList.find(
        'li :input[value="' + selectedOptionValue + '"]'
      );
      if (selectedOptionElement.length <= 0) {
        optionList
          .find("li")
          .eq(0)
          .addClass("is-active");
        optionList
          .find("li input")
          .eq(0)
          .prop("checked", true);
      } else {
        selectedOptionElement.parent().addClass("is-active");
        selectedOptionElement.prop("checked", true);
      }
      _this.replaceForm("city");
    });

    $(document).on("click", "#chooseStoreAjax", function(e) {
      e.preventDefault();
      let storeId = $(this).attr("data-store-id");
      $.ajax({
        type: "GET",
        url: window.iShop.config.baseUrl + "/account/ajax/changeStore/",
        data: { storeId: storeId },
        success: function() {
          window.location.reload();
        }
      });
    });

    $(document).on("click", ".store-selection-radio", function() {
      let showStoreRow = $(".js-show-store-on-map");
      showStoreRow.removeClass("highlight-border");
      let anyRadioSelected = $(".store-selection-radio:checked").size() > 0;
      if (anyRadioSelected) {
        let radioId = $(this).attr("id");
        showStoreRow.has("#" + radioId).addClass("highlight-border");
      }
      let button = document.getElementById("store-finder-atc");
      if (button) {
        button.disabled = !anyRadioSelected;
      }
    });

    $(document).on("submit", "#store-finder-atc-form", function(e) {
      e.preventDefault();
      let form = $("#store-finder-atc-form");
      ads.submit(form);
      fireDataLayer();
      let selectedStoreId = document.querySelector(
        'input[name="store-selection-radio"]:checked'
      ).value;
      saveCncStoreId(selectedStoreId);
    });

    function fireDataLayer() {
      let itemId = $("#itemId");
      if (itemId) {
        datalayer.addToCart(itemId.val(), 1, true);
      }
    }

    function saveCncStoreId(selectedStoreId) {
      if (selectedStoreId !== undefined) {
        $.ajax({
          url: "/ajax/checkout/rememberClickAndCollectStore/",
          method: "POST",
          headers: global.headers,
          data: {
            store: selectedStoreId,
            isClickAndCollectOverlay: true
          }
        });
      }
    }

    function createListItem(value, inputName) {
      let listItem = $("<li>", {
          class: "js-variation-item option-item",
          "data-size-selection": "",
          "data-name": inputName
        }),
        inputItem = $("<input>", {
          type: "radio",
          value: value,
          name: "import:dim_GROESSE"
        }),
        label = $("<label>", {
          for: "",
          text: value
        });
      return listItem.append(inputItem).append(label);
    }
  },

  replaceForm: function(path, newStoreData) {
    let storInfoSelection = $("#store-info-selection"),
      storeListWrap = $(".js-store-list-details-wrapper"),
      existingStoreList = $(".js-store-result-list");
    let dataArray = storInfoSelection.serializeArray();
    for (let i = 0; i < dataArray.length; i++) {
      dataArray[i]["name"] = dataArray[i]["name"].replace(/var/g, "");
      dataArray[i]["name"] = dataArray[i]["name"].replace("[", "");
      dataArray[i]["name"] = dataArray[i]["name"].replace("]", "");
    }

    let query = "";
    for (let j = 0; j < dataArray.length - 1; j++) {
      query += dataArray[j]["name"] + "=" + dataArray[j]["value"] + "&";
    }

    query +=
      dataArray[dataArray.length - 1]["name"] +
      "=" +
      dataArray[dataArray.length - 1]["value"];

    //The selected color text is uppercase, so we need to make it lowercase on form change
    let selectedColor = $("#selectedColor");
    if (selectedColor.length > 0) {
      selectedColor.text(selectedColor.text().toLowerCase());
    }

    $.ajax({
      url: storInfoSelection.attr("action") + path + "/",
      data: query,
      beforeSend: function() {
        storeListWrap.addClass("fetching");
      },
      success: function(data) {
        let resultStoreList = ".js-store-result-list",
          storeList = $(data)
            .find(resultStoreList)
            .clone();

        existingStoreList.replaceWith(storeList);
        _this.initMap({
          refreshOnly: true,
          forceGet: newStoreData
        });
        storeListWrap.removeClass("fetching");
        _this.updateAtcButtonWithNewItemId($(data));
      },
      error: _this.errorHandler
    });
    _this.disableAtcButton();
  },

  changeFormToClickAndCollect: function(storeId) {
    let storInfoSelection = $("#store-info-selection"),
      existingStoreList = $(".js-store-result-list"),
      storeAvailabilityFinder = $(".js-store-availability-finder");

    //The selected color text is uppercase, so we need to make it lowercase on form change
    let selectedColor = $("#selectedColor");
    if (selectedColor.length > 0) {
      selectedColor.text(selectedColor.text().toLowerCase());
    }

    let ajaxCnc = $("#ajax-cnc");
    $.ajax({
      url: storInfoSelection.attr("action"),
      data: {
        productId: ajaxCnc.data("product-id"),
        sku: ajaxCnc.data("sku"),
        color: ajaxCnc.data("color"),
        categoryId: ajaxCnc.data("category-id"),
        isClickAndCollectOverlay: true,
        isChangeStoreOverlay: false,
        beforeSend: function() {
          storeAvailabilityFinder.addClass("fetching");
        }
      },
      success: function(data) {
        _this.replaceInForm(data, "#button-container");
        _this.replaceInForm(data, "#isClickAndCollectOverlay");

        let resultStoreList = ".js-store-result-list",
          storeList = $(data)
            .find(resultStoreList)
            .clone();
        existingStoreList.replaceWith(storeList);
        _this.initMap({
          refreshOnly: true,
          forceGet: false
        });
        _this.updateAtcButtonWithNewItemId($(data));
        storeAvailabilityFinder.removeClass("fetching");

        let radioButton = document.getElementById("radio-" + storeId);
        radioButton.click();
      },
      error: _this.errorHandler
    });
    _this.disableAtcButton();
  },

  replaceInForm: function(data, container) {
    let currentContainer = container,
      newContainer = $(data)
        .find(currentContainer)
        .clone();
    $(container).replaceWith(newContainer);
  },

  extractLocations: function(data) {
    let locations = [];
    let center = {
      lat: 0.0,
      lng: 0.0
    };
    let bounds = {
      east: 0.0,
      north: 0.0,
      south: 180.0,
      west: 180.0
    };
    $.each(data, function(i, item) {
      locations.push({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude)
      });
      center.lat = center.lat + parseFloat(item.latitude);
      center.lng = center.lng + parseFloat(item.longitude);
      bounds.east = Math.max(bounds.east, parseFloat(item.longitude));
      bounds.north = Math.max(bounds.north, parseFloat(item.latitude));
      bounds.south = Math.min(bounds.south, parseFloat(item.latitude));
      bounds.west = Math.min(bounds.west, parseFloat(item.longitude));
    });
    center.lat = center.lat / locations.length;
    center.lng = center.lng / locations.length;
    return {
      center: center,
      locations: locations,
      bounds: bounds
    };
  },

  initMap: function(options) {
    let config = {
      refreshOnly: false,
      forceGet: false
    };
    $.extend(true, config, options);
    let storInfoSelection = $("#store-info-selection");

    if (config.refreshOnly && config.forceGet == false) {
      map.setCenter({
        lat: interimsStoreDataStorage.center.lat,
        lng: interimsStoreDataStorage.center.lat
      });
      map.fitBounds(interimsStoreDataStorage.bounds);
    } else {
      $.ajax({
        url:
          storInfoSelection.attr("action") +
          "data/?city=" +
          $("#city-selection option:selected").val(),
        dataType: "json",
        success: function(data) {
          if ($.isEmptyObject(data)) {
            return false;
          }
          let image =
              window.iShop.config.assetPath +
              "/images/layout/icons/fallback/nakpar-pin.png",
            locations = _this.extractLocations(data);

          interimsStoreDataStorage = locations;
          map = new google.maps.Map(document.getElementById("findStoreMap"), {
            zoom: 7,
            center: locations.center
          });
          map.fitBounds(locations.bounds);

          let markers = locations.locations.map(function(location, i) {
            return new google.maps.Marker({
              position: location,
              icon: image
            });
          });

          $.each(markers, function(i, item) {
            item.addListener("click", function() {
              //
            });
          });

          var renderer = {
            render: ({ count, position }) =>
              new google.maps.Marker({
                label: {
                  text: String(count),
                  color: "black",
                  fontSize: "12px"
                },
                position,
                icon:
                  window.iShop.config.assetPath +
                  "/images/googlemaps/clusteredmarker/m1.png",
                // adjust zIndex to be above other markers
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
              })
          };

          var markerCluster = new MarkerClusterer({ markers, map, renderer });
        },
        error: this.errorHandler
      });
    }
  },

  updateAtcButtonWithNewItemId: function(data) {
    let newButton = $(data).find(".button-wrap button[data-item-id]");
    let newItemId = newButton.attr("data-item-id");
    $(".button-wrap button[data-item-id]").attr("data-item-id", newItemId);
    $(".button-wrap input[name='itemId']").val(newItemId);
  },

  disableAtcButton: function() {
    let button = document.getElementById("store-finder-atc");
    if (button) {
      button.disabled = true;
    }
  },

  errorHandler: function(e) {
    if (window.console) window.console.log(e);
  }
};
export default storeLocation;
