/*globals define, document, window, $, google, jQuery*/

import gmaps_service from "../modules/gmaps_service";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

(function($) {
  var SnsStoreLocator = function(element, settings) {
    var self = this,
      NO_ZOOM = 0,
      config = {
        sidebar: true,
        customMarker: true,
        detailLink: true,
        defaultStartPosition: {
          lat: 53.5863017,
          lng: 10.0456754
        },
        maxZoom: 17,
        marker: {
          clickAction: {
            zoom: NO_ZOOM
          },
          useMarkerCluster: false
        }
      },
      mapElement = element,
      elementParent = mapElement.parent(),
      map = null,
      intLat = 0,
      intLng = 0,
      sidebar = true,
      mapId = mapElement.data("mapstyle-id"),
      centerMapElement = false, //from novomind
      sidebarElements = [],
      mapIndex = 0,
      markers = [],
      latlngBounds = null,
      infoWindows = [],
      resizeTimeout = null,
      autocomplete,
      geoCoderResultFormatted;

    function __construct() {
      if (mapElement.data("SnsStoreLocator")) {
        return mapElement.data("SnsStoreLocator");
      }

      $.extend(true, config, settings);
      sidebar = !!mapElement.data("sidebar");
      centerMapElement = !!mapElement.data("center");
      mapIndex = mapElement.data("map-index");
      if (!sidebar) {
        const currentElements = $(
          `.js_info-panel[data-map-index="${mapIndex}"]`
        );
        if (currentElements.length > 1) {
          sidebar = true;
        }
      }

      if (!sidebar || centerMapElement) {
        intLat = mapElement.data("lat")
          ? parseFloat(mapElement.data("lat"))
          : config.defaultStartPosition.lat;
        intLng = mapElement.data("lng")
          ? parseFloat(mapElement.data("lng"))
          : config.defaultStartPosition.lng;
      } else {
        intLat = config.defaultStartPosition.lat;
        intLng = config.defaultStartPosition.lng;
      }

      mapBuild();
      buildAdditionales();
      initMapEvents();
      if (window.iShop.config.googlemapsGeoCoderEnabled) {
        initGoogleGeocoder();
      }

      mapElement.data("SnsStoreLocator", self);
    }

    /**
     * Sets up a new google map
     */
    function mapBuild() {
      map = new google.maps.Map(mapElement[0], {
        center: {
          lat: intLat,
          lng: intLng
        },
        mapId: mapId,
        maxZoom: config.maxZoom,
        zoom: config.maxZoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: !sidebar
      });
    }

    function initMapEvents() {
      $(window).on("resize", function() {
        if (resizeTimeout !== null) {
          window.clearTimeout(resizeTimeout);
          resizeTimeout = null;
        }

        resizeTimeout = window.setTimeout(function() {
          resizeTimeout = null;
          setMapCenter();
        }, 100);
      });

      if (markers.length === 1) {
        markers[0].infoWindow.open({
          anchor: markers[0],
          shouldFocus: false
        });
      }

      google.maps.event.addListener(map, "idle", function() {
        filterSidebarToVisibleMarker();
        scrollSitebarToOpenItem();
      });
    }

    /**
     *
     * @param {Object} marker
     */
    function setMapCenter(marker) {
      if (sidebar && typeof marker === "undefined") {
        map.fitBounds(latlngBounds);
      } else {
        marker = typeof marker !== "undefined" ? marker : markers[0];

        map.setCenter(marker.getPosition());
        if (NO_ZOOM !== config.marker.clickAction.zoom) {
          map.setZoom(config.marker.clickAction.zoom);
        }
      }
    }

    function initGoogleGeocoder() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          // success cb
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          var google_maps_geocoder = new google.maps.Geocoder();
          google_maps_geocoder.geocode(
            {
              latLng: new google.maps.LatLng(lat, lng)
            },
            function(results, status) {
              if (status === google.maps.GeocoderStatus.OK && results[0]) {
                geoCoderResultFormatted = results[0].formatted_address;
                const $input = $("#js-store-finder-input");
                if ($input.length) {
                  $input.val(geoCoderResultFormatted);
                }
              }
            }
          );
        });
      }
    }

    function initSearchbox() {
      gmaps_service.setBaseCountry(elementParent.data("country"));
      autocomplete = gmaps_service.addAutocomplete("js-store-finder-input");
      gmaps_service.addListener(autocomplete, "place_changed", onPlaceChanged);
      $("#js-store-finder-submit").click(function() {
        gmaps_service.findCurrentPlaceByInputValue(
          "js-store-finder-input",
          showCurrentPlaceResult,
          showNoResult
        );
      });
    }

    function onPlaceChanged() {
      var currentPlace = autocomplete.getPlace();
      // user hit enter, check for the first entry of the suggest-list again
      if (typeof currentPlace.geometry === "undefined") {
        gmaps_service.findCurrentPlaceByInputValue(
          "js-store-finder-input",
          showCurrentPlaceResult,
          showNoResult
        );
      } else if (currentPlace.geometry) {
        // show result
        showCurrentPlaceResult(currentPlace);
      } else {
        showNoResult();
      }
    }

    function showCurrentPlaceResult(currentPlace) {
      $(".js-no-result").remove();
      map.setCenter(currentPlace.geometry.location);
      map.setZoom(10);
    }

    function showNoResult() {
      var noResultMessage = elementParent.data("msg-searchbox-noresults");
      $(
        '<div class="js-no-result no-result txt-center">' +
          noResultMessage +
          "</div>"
      ).insertAfter($("#js-store-finder-input").parent());
    }

    /**
     * Checks if a sidabr is already in the DOM otherwise it'll be created.
     * Add marker data to the sidebar.
     *
     */
    function buildSidebar() {
      let html;
      var listElement;
      const infoElements = $(`.js_info-panel[data-map-index="${mapIndex}"]`);
      const markerElements = [];

      if (elementParent.find(".js_maps-sidebar").length) {
        listElement = elementParent.find(".js_maps-marker-list");
      } else {
        var sidebarWrapper = $("<div>", {
          class: "gmap-sidebar js_maps-sidebar"
        });
        listElement = $("<ul>", {
          class: "gmap-sidebar-data js_maps-marker-list"
        });
        listElement.appendTo(sidebarWrapper);
        elementParent.prepend(sidebarWrapper);

        if (elementParent.data("searchbox")) {
          var placeholderMessage = elementParent.data(
            "msg-searchbox-placeholder"
          );
          html = '<div class="input-wrapper">';
          html +=
            '<input type="button" id="js-store-finder-submit" class="search-input-submit">';
          html +=
            '<input id="js-store-finder-input" class="search-input text" placeholder="' +
            placeholderMessage +
            '" type="text" autocomplete="off"/>';
          html += "</div>";
          sidebarWrapper.prepend(html);
          initSearchbox();
        }
      }

      for (var i = 0, max = infoElements.length; i < max; i++) {
        var listObject = $(infoElements[i]);
        var marker = buildMarker(listObject);

        markerElements.push(marker);

        if (!listObject.hasClass("maps-marker-list-item")) {
          const openingHours = !marker.openingHours.length
            ? `<p class="opening-wrapper">${marker.openingHours}</p>`
            : "";

          const openingHoursSpecial = !marker.specialOpeningHours.length
            ? `<p class="special-opening-wrapper">${marker.specialOpeningHours}</p>`
            : "";
          let html;
          if (marker.currentOpenState) {
            html = `
<li class="maps-marker-list-item js_maps-marker-list-item">
  <div class="pan-to-marker header">
    <span class="title">${marker.location}</span>
    <span class="type">${marker.locationType}</span>
    <address>${marker.address}</address>
    <p class="opening-info ${marker.currentOpenState}">${marker.openingStatusText}</p>
    <p class="phone">${marker.phone}</p>
    <span class="mail">${marker.mail}</span>
    <div class="hidden-infos">
      <div class="content">
        ${openingHours}
        ${openingHoursSpecial}
      </div>
    </div>
  </div>
</li>`;
          } else {
            html = `
<li class="maps-marker-list-item js_maps-marker-list-item">
  <div class="pan-to-marker header">
    <span class="title">${marker.location}</span>
    <span class="type">${marker.locationType}</span>
    <address>${marker.address}</address>
    <p class="phone">${marker.phone}</p>
    <span class="mail">${marker.mail}</span>
    <div class="hidden-infos">
      <div class="content">
        ${openingHours}
        ${openingHoursSpecial}
      </div>
    </div>
  </div>
</li>`;
          }

          listObject = $(html);

          listElement.append(listObject);
        }

        listObject.data("mapIndex", mapIndex);
        listObject.data("markerIndex", i);

        sidebarElements.push(listObject);
      }

      setMarker(markerElements);
    }

    function filterSidebarToVisibleMarker() {
      var bounds = map.getBounds();
      // Iterate over markers that are displayed on the *entire* map.
      for (var i = 0, l = markers.length; i < l; i++) {
        var current_marker = markers[i];

        if (sidebarElements[current_marker.sidebarRelatedItemIndex]) {
          if (bounds.contains(current_marker.getPosition())) {
            sidebarElements[current_marker.sidebarRelatedItemIndex].removeClass(
              "off-bounds"
            );
          } else {
            sidebarElements[current_marker.sidebarRelatedItemIndex].addClass(
              "off-bounds"
            );
          }
        }
      }
    }

    /**
     * Build google map info window
     *
     * @param {Object} infoElement
     * @returns {google.maps.InfoWindow}
     */
    function buildInfoWindow(infoElement) {
      var generalInfoWrap = $("<div>", {
          class: "gmaps-info-window"
        }),
        elementsInfoWrap = $("<div>", {
          class: "inner-info"
        }),
        infoImage = $("<img>", {
          src: infoElement.image,
          style: "max-width : 100px"
        }),
        textElement = $("<p>", {
          class: "paragraph-small"
        }),
        title = $("<span>", {
          class: "title"
        }).html(infoElement.location),
        text = $("<span>").html(infoElement.locationType),
        address = $("<div>").html(infoElement.address);

      title.appendTo(textElement);
      text.appendTo(textElement);
      if (infoElement.route) {
        var $route = $("<div>").html(infoElement.route);
        $route.appendTo(textElement);
        $route.find("a").click(clickRouteLink);
      }
      address.appendTo(textElement);

      if (
        infoElement.image !== "" &&
        typeof infoElement.image !== "undefined"
      ) {
        infoImage.appendTo(elementsInfoWrap);
      }

      textElement.appendTo(elementsInfoWrap);

      var $routeButton;
      if (infoElement.routeButton && infoElement.routeText) {
        $routeButton = $("<a>", {
          style: "margin-bottom : 1rem",
          class: "js-route-link btn small block",
          href: infoElement.routeButton
        }).html(infoElement.routeText);

        $routeButton.appendTo(elementsInfoWrap);
        $routeButton.click(clickRouteLink);
      }

      if (sidebar) {
        var blockClass = $routeButton ? "block" : ""; //needed for same look as route button
        var $filialLink = $("<a>", {
          class: "btn small" + " " + blockClass,
          href: infoElement.linkHref
        }).html(infoElement.linkText);

        $filialLink.appendTo(elementsInfoWrap);
      }

      elementsInfoWrap.appendTo(generalInfoWrap);

      return new google.maps.InfoWindow({
        content: generalInfoWrap[0]
      });
    }

    function clickRouteLink(event) {
      event.preventDefault();
      var startAddressParams = geoCoderResultFormatted
        ? encodeURI("&saddr=" + geoCoderResultFormatted)
        : "";
      window.open($(this).attr("href") + startAddressParams, "_blank");
    }

    /**
     * Build marker and info window information based on the current given info panel
     *
     * @param {Object} $element
     * @returns {Object}
     */
    function buildMarker($element) {
      const element = (typeof $element !== "undefined"
        ? $element
        : $(`.js_info-panel[data-map-index="${mapIndex}"]`))[0];
      const currentInfo = {};

      if (typeof $element !== "undefined") {
        currentInfo.location = element.querySelector(
          ".js_gmap_info_name"
        ).innerText;
        currentInfo.locationType = element.querySelector(
          ".js_gmap_info_type"
        ).innerText;
      } else {
        currentInfo.locationType = element.querySelector(
          ".js_gmap_info_name"
        ).innerText;
        currentInfo.location = element.querySelector(
          ".js_gmap_info_type"
        ).innerText;
      }

      currentInfo.lat = element.dataset.lat;
      currentInfo.lng = element.dataset.lng;

      const linkElement = element.querySelector(".js_gmap_info_link");
      if (linkElement) {
        currentInfo.linkHref = linkElement.href;
        currentInfo.linkText = linkElement.innerText;
      }
      const imageElement = element.querySelector(".js_gmap_info_image");
      if (imageElement) {
        currentInfo.image = imageElement.src;
      }
      const routeElement = element.querySelector(".js_gmap_info_route");
      currentInfo.route = routeElement ? routeElement.innerHTML : false;
      const addressElement = element.querySelector(".js_gmap_info_address");
      if (addressElement) {
        currentInfo.routeButton = addressElement.dataset.routeLink;
        currentInfo.routeText = addressElement.dataset.routeLinkText;
        currentInfo.address = addressElement.innerHTML;
      }
      let phoneElement = element.querySelector(".js_gmap_info_phone");
      if (phoneElement) {
        currentInfo.phone = phoneElement.innerText;
      }
      const mailElement = element.querySelector(".js_gmap_info_mail");
      if (mailElement) {
        currentInfo.mail = mailElement.innerHTML;
      }
      const infoOpenElement = element.querySelector(".js_gmap_info_open");
      if (infoOpenElement) {
        currentInfo.openingStatusText = infoOpenElement.innerHTML;
        currentInfo.currentOpenState = infoOpenElement.classList.item(
          infoOpenElement.classList.length - 1
        );
      }
      const openingHoursElement = element.querySelector(
        ".js_gmap_info_opening"
      );
      currentInfo.openingHours = openingHoursElement
        ? openingHoursElement.innerHTML
        : "";

      const specialOpeningHoursElement = element.querySelector(
        ".js_gmap_info_specialOpening"
      );
      currentInfo.specialOpeningHours = specialOpeningHoursElement
        ? specialOpeningHoursElement.innerHTML
        : "";

      currentInfo.infoWindow = buildInfoWindow(currentInfo);
      currentInfo.icon =
        window.iShop.config.assetPath +
        "/images/layout/icons/fallback/nakpar-pin.png";

      return currentInfo;
    }

    /**
     * Set the marker on the map
     *
     * @param {Object} marker
     */
    function setMarker(marker) {
      markers = [];
      infoWindows = [];
      latlngBounds = new google.maps.LatLngBounds();
      for (var i = 0, max = marker.length; i < max; i++) {
        var currentLatLng = new google.maps.LatLng(
            parseFloat(marker[i].lat),
            parseFloat(marker[i].lng)
          ),
          buildMarker = new google.maps.Marker({
            position: currentLatLng,
            map: map,
            title: marker[i].location,
            type: marker[i].locationType,
            image: marker[i].image,
            icon: {
              url: marker[i].icon
            },
            infoWindow: marker[i].infoWindow,
            sidebarRelatedItemIndex: i
          });

        latlngBounds.extend(currentLatLng);

        infoWindows.push(marker[i].infoWindow);

        google.maps.event.addListener(
          buildMarker,
          "click",
          markerEvent(buildMarker, i)
        );

        google.maps.event.addListener(
          buildMarker.infoWindow,
          "closeclick",
          closeInfoWindow(i)
        );

        markers.push(buildMarker);
      }

      map.fitBounds(latlngBounds);

      if (true === config.marker.useMarkerCluster) {
        var renderer = {
          render: ({ count, position }) =>
            new google.maps.Marker({
              label: { text: String(count), color: "black", fontSize: "12px" },
              position,
              icon:
                window.iShop.config.assetPath +
                "/images/googlemaps/clusteredmarker/m1.png",
              // adjust zIndex to be above other markers
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
            })
        };

        var markerCluster = new MarkerClusterer({ markers, map, renderer });
      }
    }

    /**
     * On click at a marker all other marker info windows will be closed
     * and the given marker info window will be opened.
     *
     * @param {Object} marker
     * @param {Number} i
     * @returns {Function}
     */
    function markerEvent(marker, i) {
      return function() {
        for (var curr = infoWindows.length; curr--; ) {
          infoWindows[curr].close();
        }
        marker.infoWindow.open({
          anchor: marker,
          shouldFocus: false
        });

        if (sidebar) {
          openSidebarInfo(i);
          scrollToSidebarInfo(i);
        }
      };
    }

    /**
     * If the current marker info window will be closed the sidebar element will be deactivated
     *
     * @param {Number} i
     * @returns {Function}
     */
    function closeInfoWindow(i) {
      return function() {
        if (sidebar) {
          sidebarElements[i].removeClass("active");
          if (NO_ZOOM !== config.marker.clickAction.zoom) {
            map.fitBounds(latlngBounds);
          }
        }
      };
    }

    /**
     * Adds events listener to sidebar elements, triggers click on according marker
     */
    function bindSidebarItemEvents() {
      $(".js_maps-marker-list-item").on("click", function() {
        if (!$(this).hasClass("active")) {
          var markerIndex = $(this).data("markerIndex");
          openInfoWindow(markerIndex);
          openSidebarInfo(markerIndex);
        }
      });
    }

    /**
     * Opens info window above marker according to marker index
     *
     * @param {Number} index
     */
    function openInfoWindow(index) {
      // close other
      for (var i = 0; i < infoWindows.length; i++) {
        infoWindows[i].close(map, markers[i]);
      }

      //workaround for wrong info window position if marker is within cluster
      var currentMarker = markers[index];
      if (true === config.marker.useMarkerCluster && !currentMarker.getMap()) {
        var latLng = currentMarker.getPosition();
        //create the dummy-marker on first click
        if (!map.get("dummy")) {
          map.set(
            "dummy",
            new google.maps.Marker({
              map: map,
              visible: false
            })
          );
        }
        //set position of the dummy
        map.get("dummy").setPosition(latLng);
        //use dummy as infowindow-anchor
        currentMarker = map.get("dummy");
      }

      // open by index
      infoWindows[index].open(map, currentMarker);
    }

    /**
     * Opens sidebar element according to marker index
     *
     * @param {Number} index
     */
    function openSidebarInfo(index) {
      if (sidebarElements[index].hasClass("active")) {
        return;
      }

      for (var i = sidebarElements.length; i--; ) {
        sidebarElements[i].removeClass("active");
      }

      var currentActiveElement = sidebarElements[index];
      currentActiveElement.addClass("active");
    }

    /**
     * Scrolls to sidebar element according to marker index
     *
     * @param {Number} index
     */
    function scrollToSidebarInfo(index) {
      var currentActiveElement = sidebarElements[index];
      currentActiveElement.addClass("active");
      scrollSitebarToPos(currentActiveElement.position().top);
    }

    /**
     * Scrolls to position
     */
    function scrollSitebarToPos(position) {
      $(".js_maps-sidebar").animate(
        {
          scrollTop: position
        },
        1000
      );
    }

    /**
     * Scrolls to open sidebar element
     */
    function scrollSitebarToOpenItem() {
      var currentActiveElement = sidebarElements.find(function(el) {
        return el.hasClass("active") && !el.hasClass("off-bounds");
      });
      if (!currentActiveElement) {
        return;
      }
      scrollSitebarToPos(currentActiveElement.position().top);
    }

    /**
     * After check build sidebar or set single marker
     */
    function buildAdditionales() {
      if (sidebar) {
        elementParent.removeClass("map-only");
        buildSidebar();
        bindSidebarItemEvents();

        if (elementParent.find(".js_maps-sidebar").is(":hidden")) {
          elementParent.addClass("map-only");
        }
      } else {
        elementParent.addClass("map-only");
        var singleMarker = [];
        singleMarker.push(buildMarker());
        setMarker(singleMarker);
      }
    }

    function isGoogleUndefined() {
      return typeof google == "undefined" || typeof google.maps == "undefined";
    }

    this.makeVisible = function() {
      google.maps.event.trigger(map, "resize");
      setMapCenter();
    };

    return __construct();
  };

  $.fn.extend({
    SnsStoreLocator: function(options) {
      return $(this).each(function() {
        new SnsStoreLocator($(this), options);
      });
    }
  });
})(jQuery);
