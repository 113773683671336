import gmaps_service from "../modules/gmaps_service";
import "../plugin/lightcase";

const MODE_DPAG = 0x01;
const MODE_HERMES = 0x02;

const MARKER_ICON_RED = "pin.png";
const MARKER_ICON_GREEN = "pin-hover.png";

const GOOGLE_MAPS_API = "https://maps.googleapis.com/maps/api/geocode/json";

let self;

let imagePath = null;
let map;
let autocomplete;
let infoWindow;
let markers = [];
let stores = {};
let currentMode = MODE_DPAG;
let currentPlace = null;
let currentPlaceId = null;
let selectedMarker = null;

let $locationFinder, $locationFinderDOM;

let adoptLocationHandler; // handler when user selects a store/location
let adoptLocationLabel = "Übernehmen";

// some type specific values (set them by data-* attributes)
let showExpandedInfo;

const delivery_finder = {
  init: function(data) {
    self = this;

    imagePath = data.imagePath;

    // set the $locationFinder DOM Container
    //            $locationFinder = $('.js-location-finder-modal');
    $locationFinder = $("#location-container");
    $locationFinderDOM = $locationFinder.html();

    // bind the click event to open the store finder
    self.bindLocationFinderOpener();

    // init the gmap service with the right handler
    gmaps_service.init("location-finder-gmaps-loaded");

    // bind a gmaps sources loaded event - so we can create the map when gmaps sources are ready
    $(window).bind("location-finder-gmaps-loaded", self.createMap);

    // setup the adopt location handler - default its the adoptLocation function - but modules can define another
    self.setAdoptLocationHandler(self.adoptLocation);
  },

  /**
   * setter for the adopt location handler
   *
   * @param handler
   */
  setAdoptLocationHandler: function(handler) {
    adoptLocationHandler = handler;
  },

  /**
   * setter for the adopt location label
   *
   * @param label
   */
  setAdoptLocationLabel: function(label) {
    adoptLocationLabel = label;
  },

  /**
   * caller for the adopt location/store handler
   */
  callAdoptLocationHandler: function(event) {
    event.preventDefault();

    const btn = this;
    const storeId = parseInt(
      $(btn)
        .closest(".js-location-finder-result")
        .find(".js-storeId")
        .text()
    );

    // return if selected store equals current store
    if (currentPlaceId === storeId) {
      return;
    }

    // update current store id
    currentPlaceId = storeId;

    // and call the handler
    adoptLocationHandler(btn, storeId);
  },

  createMap: function() {
    // build map on given element
    map = gmaps_service.createMap("js-location-finder-map");

    // Create the autocomplete object and associate it with the UI input control.
    autocomplete = gmaps_service.addAutocomplete("js-location-finder-input");
    gmaps_service.addListener(
      autocomplete,
      "place_changed",
      self.onPlaceChanged
    );

    // add a info pop up to the map
    infoWindow = gmaps_service.addInfoWindow("js-location-finder-info");
  },

  loadMaps: function() {
    /* global GMAPS_LOADED */

    // just create the map when sources are loaded
    if (gmaps_service.GMAPS_LOADED) {
      return self.createMap();
    }

    // otherwise load the gmaps sources
    gmaps_service.loadMaps();
  },

  bindLocationFinderOpener: function() {
    // open the DPAG finder
    $(".js_dhl-lightcase").on("click", function(ev) {
      ev.preventDefault();
      self.displayDhlFinder();
    });

    // open the HERMES finder
    $(".js_hermes-lightcase").on("click", function(ev) {
      ev.preventDefault();
      self.displayHermesFinder();
    });

    // search action btn
    $(document)
      .off(
        "click",
        ".js-location-finder-submit",
        self.findCurrentPlaceByInputValue
      )
      .on(
        "click",
        ".js-location-finder-submit",
        self.findCurrentPlaceByInputValue
      );
  },

  bindLocationFinderResultActions: function() {
    // show expanded info from location
    $(document)
      .off("click", ".js-show-expanded-info", self.displayExpandedInfo)
      .on("click", ".js-show-expanded-info", self.displayExpandedInfo);

    // submit action btn
    $(document)
      .off("click", ".js-btn-adopt-location", self.adoptLocation)
      .on("click", ".js-btn-adopt-location", self.callAdoptLocationHandler);
  },

  displayDhlFinder: function() {
    // define mode
    currentMode = MODE_DPAG;
    gmaps_service.setBaseCountry("de");
    self.displayFinder();
  },

  displayHermesFinder: function() {
    // define mode
    currentMode = MODE_HERMES;
    const country =
      $(
        'select[name="billingAddressViewModel.invoiceAddress.country"] option:selected'
      ).val() || "de";
    gmaps_service.setBaseCountry(country.toLowerCase());
    self.displayFinder();
  },

  displayFinder: function() {
    // setup type (dpag, store) specific vars
    showExpandedInfo = $(this).data("expandedInfo") || false;

    lightcase.start({
      href: "#location-container",
      showSequenceInfo: false,
      shrinkFactor: 0.9,
      disableShrink: false,
      inline: {
        width: "auto",
        height: "600"
      },
      onFinish: {
        displayCarrierLogo: function() {
          if (currentMode === MODE_HERMES) {
            $(".location-finder-header-hermes").show();
            showExpandedInfo = $(this).data("expandedInfo") || true;
          } else if (currentMode === MODE_DPAG) {
            $(".location-finder-header-dpag").show();
          }
        },
        injectContent: function() {
          self.loadMaps();
          lightcase.resize();
        }
      },
      onClose: {
        close: function() {
          self.resetLocationFinderModal();
        }
      }
    });
  },

  displayLocationFinderHeader: function() {
    if (!currentMode) {
      return;
    }

    const $headerDPAG = $(".js-location-finder-header-dpag");
    const $headerHermes = $(".js-location-finder-header-hermes");

    switch (currentMode) {
      case MODE_HERMES:
        {
          $headerHermes.removeClass("hide");
        }
        break;

      case MODE_DPAG:
        {
          $headerDPAG.removeClass("hide");
        }
        break;
    }
  },

  displayExpandedInfo: function() {
    const $btn = $(this);

    if (!$btn || $btn.length === 0) {
      return;
    }

    $btn
      .parents(".js-location-finder-result")
      .find(".js-location-expanded-info")
      .toggleClass("open");
  },

  adoptLocation: function(btn) {
    const $btn = $(btn);

    if ($btn.length === 0) {
      return;
    }

    const infix = currentMode === MODE_HERMES ? "hermesShop" : "packstation";
    const $container = $btn.closest(".js-location-finder-result");

    const description = $container.find(".js-description").text();
    const street = $container.find(".js-street").text();
    const streetNumber = $container.find(".js-streetNumber").text();
    const zipCode = $container.find(".js-zipCode").text();
    const city = $container.find(".js-city").text();

    $(".js-" + infix + "-name").text(description);
    $(".js-" + infix + "-name-hidden").val(description);

    $(".js-" + infix + "-street").text(street + " " + streetNumber);
    $(".js-" + infix + "-street-hidden").val(street);
    $(".js-" + infix + "-streetNumber-hidden").val(streetNumber);

    $(".js-" + infix + "-city").text(zipCode + " " + city);
    $(".js-" + infix + "-zipCode-hidden").val(zipCode);
    $(".js-" + infix + "-city-hidden").val(city);

    const country = gmaps_service.getBaseCountry() || "de";
    $(".js-" + infix + "-country-hidden").val(country.toUpperCase());

    if (currentMode === MODE_HERMES) {
      const storeId = $container.find(".js-storeId").text();
      $(".js-hermesShop-number").text(storeId);
      $(".js-hermesShop-number-hidden").val(storeId);
    }

    /** To disable the error-message from the backend validation (in case no hermes shop was selected)
     * is disabled the customer has chosen a hermes shop**/
    $(".js_error_no_station").hide();

    lightcase.close();
  },

  resetLocationFinderModal: function() {
    // move html from the $locationFinder back to its parent container and empty the modal layer
    $("#location-container")
      .empty()
      .html($locationFinderDOM);
  },

  onPlaceChanged: function() {
    currentPlace = autocomplete.getPlace();
    // user hit enter, check for the first entry of the suggest-list again
    if (typeof currentPlace.geometry === "undefined") {
      self.findCurrentPlaceByInputValue();
    } else if (currentPlace.geometry) {
      // show result
      self.showCurrentPlaceResult();
    } else {
      document.getElementById("js-location-finder-input").placeholder =
        "Ort oder PLZ";
    }
  },

  showCurrentPlaceResult: function(response) {
    // if we have a response (maybe a call from findCurrentPlaceByInputValue) set the currentPlace by its result - otherwise use module var
    if (response) {
      currentPlace = response;
    }

    map.setCenter(currentPlace.geometry.location);
    map.setZoom(10);
    self.search();
  },

  // click on icon in search-input
  findCurrentPlaceByInputValue: function() {
    gmaps_service.findCurrentPlaceByInputValue(
      "js-location-finder-input",
      self.showCurrentPlaceResult
    );
  },

  clearMarkers: function() {
    for (var i = 0, s = markers.length; i < s; ++i) {
      if (markers[i]) {
        markers[i].setMap(null);
      }
    }
    markers = [];
  },

  clearResults: function() {
    var locationFinderResults = $(".js-location-finder-results")[0];

    while (locationFinderResults.childNodes[0]) {
      locationFinderResults.removeChild(locationFinderResults.childNodes[0]);
    }
  },

  clearStores: function() {
    stores = {};
  },

  search: function() {
    self.clearMarkers();
    self.clearResults();

    const infix = currentMode === MODE_HERMES ? "hermes" : "dpag";
    const address = self.resolveAddressByUnformatedAddress(
      currentPlace.formatted_address
    );
    const params = [];

    if (address.city) {
      params.push("city=" + encodeURIComponent(address.city));
    }

    if (address.zip) {
      params.push("zip=" + encodeURIComponent(address.zip));
    }

    if (address.country) {
      params.push("country=" + encodeURIComponent(address.country));
    }

    if (params.length > 0) {
      const path =
        $("#location-container").attr("data-basepath") +
        "ajax/deliveryfinder/" +
        infix +
        "/?" +
        params.join("&");
      $.get(path, function(data) {
        self.setContent(data);
      });
    }
  },

  extractPostalCodeFromAddress: function(address) {
    const arrAddress = address.split(" ");
    let postalcode = null;

    $.each(arrAddress, function(key, entry) {
      entry = entry.trim();

      if ($.isNumeric(entry) && entry.length === 5) {
        postalcode = entry;

        return false;
      }
      return true;
    });
    return postalcode;
  },

  setContent: function(data) {
    const bounds = gmaps_service.newBounds();
    const markerIcon = imagePath + MARKER_ICON_RED;

    $.each(data, function(index, store) {
      const markerIndex = markers.length;
      const markerPosition = gmaps_service.setMapPosition(store.lat, store.lng);
      bounds.extend(markerPosition);
      const marker = gmaps_service.addMarker({
        position: markerPosition,
        map: map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        title: store.additional,
        icon: markerIcon
      });

      marker.set("id", index);

      gmaps_service.addListener(marker, "click", function() {
        this.setIcon(imagePath + MARKER_ICON_GREEN);

        /*
         * reset a previously selected marker
         * */
        if (selectedMarker) {
          if (selectedMarker === this) {
            return;
          }
          selectedMarker.setIcon(imagePath + MARKER_ICON_RED);
        }
        selectedMarker = this;

        self.buildMapsInfoWindowContent(this);

        infoWindow.open(map, this);

        $("#js-location-finder-info")
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass("hidden-xs");
      });

      gmaps_service.addListener(marker, "mouseover", function() {
        gmaps_service.triggerEvent(marker, "click");
      });

      markers[markerIndex] = marker;

      stores[index] = store;

      self.addResult(store, marker);
    });

    // after add the result html do some bindings on the action buttons in the result
    self.bindLocationFinderResultActions();

    if (markers.length > 0) {
      map.fitBounds(bounds);
    }
  },

  resolveAddressByUnformatedAddress: function(unformatedAddress) {
    let address = {
      city: null,
      zip: null,
      lat: null,
      lng: null,
      country: null
    };

    if (unformatedAddress !== null && unformatedAddress !== "") {
      address = self.fulfillAddress(
        GOOGLE_MAPS_API +
          "?key=AIzaSyC9x6A50aPGn5dsr_RNr6pjqvgICKIwW3M&address=" +
          encodeURIComponent(unformatedAddress) +
          "&sensor=false",
        address
      );

      if (!address.zip) {
        address.zip = self.extractPostalCodeFromAddress(unformatedAddress);
      }

      if (!address.zip && address.lat && address.lng) {
        address = self.fulfillAddress(
          GOOGLE_MAPS_API +
            "?key=AIzaSyC9x6A50aPGn5dsr_RNr6pjqvgICKIwW3M&latlng=" +
            address.lat +
            "," +
            address.lng +
            "&sensor=false",
          address
        );
      }
    }
    return address;
  },

  fulfillAddress: function(path, address) {
    $.ajax({
      url: path,
      type: "GET",
      data: {},
      async: false,
      dataType: "json",
      success: function(data) {
        if (data.status === google.maps.GeocoderStatus.OK) {
          let loopNext = true;

          $.each(data.results, function(key, entry) {
            if (entry.address_components) {
              $.each(entry.address_components, function(index, component) {
                if (component.types) {
                  $.each(component.types, function(typeId, type) {
                    if (type === "locality") {
                      address.city = component.long_name;
                    }

                    if (type === "postal_code") {
                      address.zip = component.long_name;
                    }

                    if (type === "country") {
                      address.country = component.short_name.toLowerCase();
                    }

                    // negate the result => stop looping
                    loopNext = !(address.city && address.zip);

                    return loopNext;
                  });
                }
                return loopNext;
              });
            }

            if (entry.geometry.location.lat && entry.geometry.location.lng) {
              address.lat = entry.geometry.location.lat;
              address.lng = entry.geometry.location.lng;
            }

            if (address.city && address.lat && address.lng) {
              loopNext = false;
            }
            return loopNext;
          });
        }
      }
    });
    return address;
  },

  addResult: function(result, marker) {
    const isHermes = currentMode === MODE_HERMES;

    const locationFinderResults = $(".js-location-finder-results")[0];

    const tr = document.createElement("tr");

    tr.onclick = function() {
      gmaps_service.triggerEvent(marker, "click");
    };

    tr.className = "js-location-finder-result";

    const nameTd = document.createElement("td");
    const distanceTd = document.createElement("td");

    distanceTd.className += " location-finder-results-distance";

    let row1Txt;

    if (isHermes) {
      row1Txt = result.additional;
    } else {
      row1Txt = "Packstation " + result.packstationid;
    }

    const distanceTxt = document.createTextNode(self.getDistanceAsText(result));

    $(nameTd).append('<span class="js-description">' + row1Txt + "</span>");
    distanceTd.appendChild(distanceTxt);

    let locationOpenings;
    if (result.openinghours) {
      locationOpenings =
        (result.openinghours.mon && result.openinghours.mon.open
          ? "<li><span>Mo: </span>" +
            result.openinghours.mon.open +
            "-" +
            result.openinghours.mon.close +
            "</li>"
          : "<li><span>Mo:</span> geschlossen</li>") +
        (result.openinghours.tue && result.openinghours.tue.open
          ? "<li><span>Di: </span>" +
            result.openinghours.tue.open +
            "-" +
            result.openinghours.tue.close +
            "</li>"
          : "<li><span>Di: </span>geschlossen</li>") +
        (result.openinghours.wed && result.openinghours.wed.open
          ? "<li><span>Mi: </span>" +
            result.openinghours.wed.open +
            "-" +
            result.openinghours.wed.close +
            "</li>"
          : "<li><span>Mi: </span>geschlossen</li>") +
        (result.openinghours.thu && result.openinghours.thu.open
          ? "<li><span>Do: </span>" +
            result.openinghours.thu.open +
            "-" +
            result.openinghours.thu.close +
            "</li>"
          : "<li><span>Do: </span>geschlossen</li>") +
        (result.openinghours.fri && result.openinghours.fri.open
          ? "<li><span>Fr: </span>" +
            result.openinghours.fri.open +
            "-" +
            result.openinghours.fri.close +
            "</li>"
          : "<li><span>Fr: </span>geschlossen</li>") +
        (result.openinghours.sat && result.openinghours.sat.open
          ? "<li><span>Sa: </span>" +
            result.openinghours.sat.open +
            "-" +
            result.openinghours.sat.close +
            "</li>"
          : "<li><span>Sa: </span>geschlossen</li>") +
        (result.openinghours.sun && result.openinghours.sun.open
          ? "<li><span>So: </span>" +
            result.openinghours.sun.open +
            "-" +
            result.openinghours.sun.close +
            "</li>"
          : "<li><span>So: </span>geschlossen</li>");
    }

    $(nameTd).append(
      "<address>" +
        '<span class="js-street">' +
        result.street +
        '</span>&nbsp;<span class="js-streetNumber">' +
        result.streetno +
        "</span><br>" +
        '<span class="js-zipCode">' +
        result.zip +
        '</span>&nbsp;<span class="js-city">' +
        result.city +
        "</span>" +
        "</address>" +
        '<ul class="list-inline location-finder-actions">' +
        '   <li><a href="#" class="btn small js-btn-adopt-location js_depends">' +
        adoptLocationLabel +
        "</a></li>" +
        '   <li><a class="js-show-expanded-info show-expanded-info-' +
        showExpandedInfo +
        '"><svg class="svg-icon info" data-fallback-img="images/layout/icons/fallback/info.png">' +
        '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icons--info">' +
        "</use></svg></a></li>" +
        "</ul>" +
        '<div class="location-expanded-info js-location-expanded-info">' +
        '   <span class="expanded-info-openings">Öffnungszeiten</span> ' +
        "   <ul>" +
        (locationOpenings ? locationOpenings : "") +
        "</ul>" +
        "</div>" +
        '<span class="js-storeId hide">' +
        result.id +
        "</span>"
    );

    tr.appendChild(nameTd);
    tr.appendChild(distanceTd);

    locationFinderResults.appendChild(tr);
  },

  getDistanceAsText: function(result) {
    return Math.round(result.dst * 10) / 10 + " km";
  },

  buildMapsInfoWindowContent: function(marker) {
    if (stores[marker.get("id")]) {
      const isStoreMode = currentMode === MODE_HERMES;

      const store = stores[marker.get("id")];

      const descrTxt = isStoreMode
        ? store.additional
        : "Packstation " + store.packstationid;

      $("#iw-img img").removeClass("open");
      switch (currentMode) {
        case MODE_HERMES:
          {
            $(".js-iw-img-store").addClass("open");
          }
          break;

        case MODE_DPAG:
          {
            $(".js-iw-img-dpag").addClass("open");
          }
          break;
      }

      $("#iw-descr", infoWindow.content).text(descrTxt);
      $("#iw-address", infoWindow.content).text(
        store.street + " " + store.streetno
      );
      $("#iw-city", infoWindow.content).text(store.zip + " " + store.city);

      if (store.additional && currentMode === MODE_DPAG) {
        $("#iw-additional", infoWindow.content).text(store.additional);
      }
      $("#iw-distance", infoWindow.content).text(self.getDistanceAsText(store));
    }
  }
};

export default delivery_finder;
