import "../lib/jquery.unveil";

function Lazyload() {
  this.defaults = {
    threshold: 100,
    selector: ".js-unveil",
    callback: ""
  };
}

Lazyload.prototype = {
  init: function(data) {
    if (!$.fn.unveil) {
      return;
    }
    var myData = $.extend(this.defaults, data);
    $(myData.selector).unveil(myData.threshold, function() {
      $(this).load(function() {
        this.style.opacity = 1;
      });
    });
  }
};
export default new Lazyload();
