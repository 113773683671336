var fenixImagesLoaded = function($element) {
  function __init() {
    var $images = $element.find('img[src!=""]');
    if (!$images.length) {
      return $.Deferred()
        .resolve()
        .promise();
    }

    var dfds = [];
    $images.each(function() {
      var dfd = $.Deferred();
      dfds.push(dfd);
      var img = new Image();
      img.onload = function() {
        dfd.resolve();
      };
      img.onerror = function() {
        dfd.resolve();
      };
      img.src = this.src;
    });
    return $.when.apply($, dfds);
  }

  return __init();
};

$.fn.extend({
  FenixImagesLoaded: function() {
    return fenixImagesLoaded($(this));
  }
});
