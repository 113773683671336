import lockscroll from "../utils/lock-scroll";

function SearchSuggest() {}

var $form = $("#search_id_form");
var $searchInput = $(".js_suggest");
var $suggestBox = $(".js_suggest_box");
var translationStrings = $suggestBox.data();
var $bodyOverlay = $(".ui-body-overlay");
var _this;

SearchSuggest.prototype = {
  init: function() {
    _this = this;
    _this._keybindings();
    _this._bindOutboundClick();
    _this._displaySearch();
  },

  _displaySearch: function() {
    let $searchFocus = $("#search-focus"),
      $searchWrapper = $(".search-and-cart-wrapper");

    $(".remove-query").on("click", function() {
      $searchFocus.val("");
      $searchFocus.trigger("keyup");
      $searchFocus.focus();
    });

    $(".mobile-search-toggle").on("mouseup", function() {
      $(".js_suggest_box").hide();
      $("body").removeClass("show-mobile-menu");
      lockscroll.disable();
      $(".js-toggle-mobile-menu").removeClass("active");

      $searchWrapper.toggleClass("search-open");

      if ($searchWrapper.hasClass("search-open")) {
        $searchFocus.focus();
      }
    });
  },

  _keybindings: function() {
    let $results = $(".js_suggest_results");
    let index = -1;

    // hotkey 's' for search input focus
    $(document).keyup(function(e) {
      if (e.keyCode !== 83) {
        return;
      }
      if ($(e.target).is("input,textarea")) {
        return;
      }
      if (
        document.getElementsByTagName("sg_element_popup_outer").length === 0
      ) {
        $searchInput.trigger("focus");
      }
    });

    // default button submit
    $form.on("click", ".search-input-submit", function() {
      _this._submitForm();
    });

    // enter on submit
    $searchInput.keydown(function(e) {
      let keyFocus = $results.find("[data-keyfocus]");
      if (e.which === 13) {
        e.preventDefault();

        if (index === -1) {
          if ($searchInput.val().length <= 0) {
            return false;
          } else {
            _this._submitForm();
          }
        } else {
          $searchInput.val(keyFocus.eq(index).attr("data-value"));
          window.location.href = keyFocus.eq(index).attr("href");
        }
      }
    });

    $searchInput.keyup(function(e) {
      let keyFocus = $results.find("[data-keyfocus]");
      let indexLength = keyFocus.length - 1;

      // custom Keybindings (left, right, up, down, enter)
      if (
        e.which === 38 ||
        e.which === 40 ||
        e.which === 37 ||
        e.which === 39 ||
        e.which === 13
      ) {
        if (!$results.is(":visible")) {
          return false;
        }

        if (e.which === 39 || e.which === 40) {
          if (index >= indexLength) {
            index = -1;
          }
          index++;
        }

        if (e.which === 37 || e.which === 38) {
          if (index <= -1) {
            index = indexLength;
          }
          index--;
        }

        keyFocus.removeClass("focus");
        keyFocus.eq(index).addClass("focus");
      } else {
        // other keys for suggestions
        index = -1;
        _this._requestSuggestion(e);
      }
    });
  },

  _requestSuggestion: function(e) {
    let _this = this;

    let $searchInputValue = encodeURIComponent($searchInput.val()),
      $suggestBox = $(".js_suggest_box"),
      $searchInputWrapper = $(".search .input-wrapper"),
      $searchInputSubmit = $(".search-input-submit");

    if ($searchInputValue.length < 1) {
      $searchInputWrapper.addClass("do-not-send");
      $searchInputSubmit.attr("disabled", true);
    } else {
      $searchInputWrapper.removeClass("do-not-send");
      $searchInputSubmit.attr("disabled", false);
    }

    if ($searchInputValue.length >= 3) {
      e.preventDefault();
      $.ajax({
        url: $searchInput.attr("data-js-suggest") + "?q=" + $searchInputValue,
        cache: false
      })
        .success(function(data) {
          let $dataArray = data;
          let $suggestNoResults = $(".js_no_suggestions");
          let $suggestResults = $(".js_suggest_results");
          let $showAllResultsSearchTerm = $(".js_filled_term");
          let $showAllResultsLink = $(".js_all_results_link");
          let $searchUrl =
            $searchInput.attr("data-js-search") +
            "?q=" +
            $searchInputValue +
            "&ms=true" +
            "&qc=";

          $suggestBox.show();

          let $suggArray = $dataArray["suggestions"];
          let $productSugg = $suggArray["productSuggestions"];
          let $brandSugg = $suggArray["brandSuggestions"];
          let $categorySugg = $suggArray["categorySuggestions"];
          let $searchTermSugg = $suggArray["searchTermSuggestions"];

          if (
            $productSugg.length === 0 &&
            $categorySugg.length === 0 &&
            $brandSugg.length === 0
          ) {
            $suggestNoResults.empty();
            $suggestBox.hide();
            $bodyOverlay.hide();
          } else {
            $bodyOverlay.show();
            $suggestNoResults.empty();
            $suggestResults.show();
            let $productSuggBox = $(".js_product_suggestions");
            let $brandSuggTitle = $(".js_brand_sugg_title");
            let $categorySuggTitle = $(".js_category_sugg_title");
            let $searchTermSuggTitle = $(".js_search_term_sugg_title");
            let $brandSuggList = $(".js_brand_sugg_list");
            let $categorySuggList = $(".js_category_sugg_list");
            let $searchTermSuggList = $(".js_search_term_sugg_list");

            if ($productSugg.length > 0) {
              _this._fillProductSuggestionsPrivate($productSugg);
              $productSuggBox.show();
            } else {
              $productSuggBox.hide();
            }
            if ($brandSugg.length > 0) {
              _this._fillBrandSuggestionsPrivate($brandSugg, $brandSuggList);
              $brandSuggTitle.show();
            } else {
              $brandSuggTitle.hide();
              $brandSuggList.empty();
            }
            if ($categorySugg.length > 0) {
              _this._fillCategorySuggestionsPrivate(
                $categorySugg,
                $categorySuggList
              );
              $categorySuggTitle.show();
            } else {
              $categorySuggTitle.hide();
              $categorySuggList.empty();
            }
            if ($searchTermSugg.length > 0) {
              _this._fillSearchTermSuggestionsPrivate(
                $searchTermSugg,
                $searchTermSuggList
              );
              $searchTermSuggTitle.show();
            } else {
              $searchTermSuggTitle.hide();
              $searchTermSuggList.empty();
            }
            $showAllResultsLink.attr("data-value", $searchInput.val());
            $showAllResultsLink.attr("href", $searchUrl);
            $showAllResultsSearchTerm.html('"' + $searchInput.val() + '"');
          }
        })
        .fail(function(error) {
          if (window.console) {
            console.log(error);
          }
        });
    } else {
      $suggestBox.hide();
      $bodyOverlay.hide();
    }
  },

  _highlightSearchString: function(sugg) {
    let sRegExInput = new RegExp($searchInput.val(), "i");
    return sugg.replace(
      sRegExInput,
      "<strong>" + $searchInput.val() + "</strong>"
    );
  },

  _fillProductSuggestionsPrivate: function(productSugg) {
    let $productContainer = $(".js_product_sugg_container");
    $productContainer.empty();

    productSugg.forEach(function(sugg) {
      $productContainer.append(
        '<div class="search-item c-no-gutter-6">' +
          '<a data-keyfocus data-value="' +
          sugg.value +
          '" href="' +
          sugg.link +
          '">' +
          '<span class="image-wrapper">' +
          '<img src="' +
          sugg.productImagePath +
          '" alt="' +
          sugg.value +
          '">' +
          "</span>" +
          '   <span class="info-text">' +
          '   <span class="paragraph-small">' +
          sugg.productBrand +
          "</span>" +
          '   <span class="paragraph">' +
          sugg.value +
          " " +
          sugg.gender +
          "</span>" +
          "</span>" +
          "</a>" +
          "</div>"
      );
    });
  },

  _fillBrandSuggestionsPrivate: function(brandSugg, brandSuggList) {
    brandSuggList.empty();
    let windowWidth = $(window).width();

    brandSugg.forEach(function(sugg) {
      let markup = _this._highlightSearchString(sugg.value);

      if (windowWidth >= 801) {
        brandSuggList.append(
          '<li class="c-12"><a data-keyfocus data-value="' +
            sugg.value +
            '" href="' +
            sugg.link +
            '" class="paragraph-small">' +
            '<img class="brand-image" src="' +
            sugg.brandImagePath +
            '" /><span class="brand-name">' +
            markup +
            "</span></a>" +
            "</li>"
        );
      } else {
        brandSuggList.append(
          '<li class="c-12"><a data-keyfocus data-value="' +
            sugg.value +
            '" href="' +
            sugg.link +
            '" class="paragraph-small">' +
            '<span class="brand-name">' +
            markup +
            "</span> <i>" +
            translationStrings.brandSuggestion +
            "</i></a>" +
            "</li>"
        );
      }
    });
  },

  _fillCategorySuggestionsPrivate: function(categorySugg, categorySuggList) {
    categorySuggList.empty();
    categorySugg.forEach(function(sugg) {
      let markup = _this._highlightSearchString(sugg.value);

      categorySuggList.append(
        '<li><a data-keyfocus data-value="' +
          sugg.value +
          '" href="' +
          sugg.link +
          '" class="paragraph-small">' +
          markup +
          " <i>" +
          translationStrings.categorySuggestion +
          "</i></a></li>"
      );
    });
  },

  _fillSearchTermSuggestionsPrivate: function(
    searchTermSugg,
    searchTermSuggList
  ) {
    searchTermSuggList.empty();
    searchTermSugg.forEach(function(sugg) {
      let markup = _this._highlightSearchString(sugg.value);

      searchTermSuggList.append(
        '<li><a data-keyfocus data-value="' +
          sugg.value +
          '" href="' +
          sugg.link +
          '" class="paragraph-small">' +
          markup +
          " <i>" +
          translationStrings.termSuggestion +
          "</i></a></li>"
      );
    });
  },

  _bindOutboundClick: function() {
    $(document).mouseup(function(e) {
      let $suggestInnerBox = $(".js_suggest_box .inner-box");
      let $suggestBox = $(".js_suggest_box");
      if (
        !$suggestInnerBox.is(e.target) && // if the target of the click isn't the container...
        $suggestBox.has(e.target).length === 0
      ) {
        // ... nor a descendant of the container
        $suggestBox.hide();
        $bodyOverlay.hide();
      }
    });
  },

  _submitForm: function() {
    let ajaxUrl = $form.attr("action") + "ajax/get/";
    let $searchValue = $searchInput.val();

    $.ajax({
      url: ajaxUrl,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      data: {
        q: $searchValue,
        ms: "true"
      },
      success: function(response) {
        if (response != null) {
          let target = $form.attr("target");
          let isOpenInNewTab = response["isOpenInNewTab"];
          if (isOpenInNewTab) {
            if (target !== "_blank") {
              $form.attr("target", "_blank");
            }
          } else {
            if (target === "_blank") {
              $form.removeAttr("target");
            }
          }
        }
        $form.submit();
      },
      error: function(e) {
        console.log(e.message);
      }
    });
  }
};
export default new SearchSuggest();
