import "parsleyjs/dist/parsley";
import "../config/validateconfig";

/**
 * config/validateconfig: Customize Classnames/Wrappers/Functions
 *
 * The actual rules are located at the input elements
 *
 * Example Form-Element for input-fields:
 */

/** <div data-require-module="validate">
 <form data-validate>
 <div class="input-wrapper">
 <label>LABEL</label>
 <textarea class="text"
 data-validate-min="140"
 data-validate-trigger="focusout"
 data-validate-error-message="Die Bewertung muss mindestens 140 Zeichen lang sein."
 data-validate-required></textarea>
 </div>
 <input type="submit" />
 </form>
 </div>
 **/

var validate = {
  init: function() {
    this.validateForm();
    this.customValidators();
    this.giftCardInvalidator();
    this.resetPromotionFormValidationOnSubmit();
  },

  validateForm: function() {
    $(document)
      .find("form")
      .each(function() {
        let $validate = $(this).attr("data-validate");
        if (typeof $validate !== typeof undefined && $validate !== "false") {
          $(this).parsley(ParsleyConfig);
        }
      });
  },

  customValidators: function() {
    window.Parsley.addValidator("ispinrequired", {
      validate: function(value) {
        var pinCodeInput = $("[data-pin-code]");
        if (pinCodeInput.length) {
          pinCodeInput.parsley();
          if (value.length == 16) {
            pinCodeInput.options.required = false;
            return true;
          } else if (value.length > 16) {
            pinCodeInput.options.required = true;
            return true;
          }
        }
      }
    });

    window.Parsley.addValidator("prohibited_billing_zipcode", {
      validate: function() {
        var zipCodeInput = $("[data-validate-prohibited_billing_zipcode]");
        var prohibitedList = JSON.parse(
          zipCodeInput.attr("data-validate-prohibited_billing_zipcode")
        ).prohibited_zipcode_list;
        zipCodeInput.parsley();
        if (
          zipCodeInput.attr("data-validate-required") == "true" &&
          zipCodeInput.length &&
          $("#radio-lieferadresse-rechnungsadresse").is(":checked") &&
          prohibitedList.includes(zipCodeInput.val())
        ) {
          return false;
        }
        return true;
      }
    });

    window.Parsley.addValidator("prohibited_shipping_zipcode", {
      validate: function() {
        var zipCodeInput = $("[data-validate-prohibited_shipping_zipcode]");
        var prohibitedList = JSON.parse(
          zipCodeInput.attr("data-validate-prohibited_shipping_zipcode")
        ).prohibited_zipcode_list;
        zipCodeInput.parsley();
        if (
          zipCodeInput.attr("data-validate-required") == "true" &&
          zipCodeInput.length &&
          prohibitedList.includes(zipCodeInput.val())
        ) {
          return false;
        }
        return true;
      }
    });

    window.Parsley.addValidator("prohibited_billing_city", {
      validate: function() {
        var cityInput = $("[data-validate-prohibited_billing_city]");
        var prohibitedList = JSON.parse(
          cityInput.attr("data-validate-prohibited_billing_city")
        ).prohibited_city_list;
        cityInput.parsley();
        if (
          cityInput.attr("data-validate-required") == "true" &&
          cityInput.length &&
          $("#radio-lieferadresse-rechnungsadresse").is(":checked") &&
          prohibitedList.includes(cityInput.val().toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });

    window.Parsley.addValidator("prohibited_shipping_city", {
      validate: function() {
        var cityInput = $("[data-validate-prohibited_shipping_city]");
        var prohibitedList = JSON.parse(
          cityInput.attr("data-validate-prohibited_shipping_city")
        ).prohibited_city_list;
        cityInput.parsley();
        if (
          cityInput.attr("data-validate-required") == "true" &&
          cityInput.length &&
          prohibitedList.includes(cityInput.val().toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });

    window.Parsley.addValidator("creditcardexpiry", {
      validate: function() {
        var selectedExpiryDate = {
            month: "",
            year: ""
          },
          currentDate = new Date();

        $.each(selectedExpiryDate, function(key) {
          selectedExpiryDate[key] = $(
            '[data-validate-creditcardexpiry="' + key + '"]'
          ).val();
        });

        var currentDateToCompare = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth()
        );

        if (selectedExpiryDate.month && selectedExpiryDate.year) {
          var selectedDate = new Date(
            selectedExpiryDate.year,
            selectedExpiryDate.month - 1
          );

          if (selectedDate >= currentDateToCompare) {
            $.each($("[data-validate-creditcardexpiry]"), function() {
              $(this)
                .parsley()
                .reset();
            });
            return true;
          }
        }
        return false;
      }
    });

    window.Parsley.addValidator("emailnospecialchars", {
      validate: function(value) {
        let regex = /^([A-Za-z0-9_\.-]+\@[\dA-Za-z0-9\.-]+\.[A-Za-z\.]{2,6})$/gm;
        return regex;
      }
    });

    window.getCreditCardBrand = function(number, check_length) {
      var _j,
        _len1,
        card_name = [],
        number = number.replace(/[ -]/g, "");

      if (!number.length) {
        return;
      }

      var check_length =
          typeof check_length !== "undefined" ? check_length : false,
        card_types = [
          {
            name: "amex",
            pattern: /^3[47]/,
            valid_length: [15]
          },
          {
            name: "china_union_pay",
            pattern: /^(62|88)/,
            valid_length: [16, 17, 18, 19]
          },
          {
            name: "dankort",
            pattern: /^5019/,
            valid_length: [16]
          },
          {
            name: "diners_club_carte_blanche",
            pattern: /^30[0-5]/,
            valid_length: [14]
          },
          {
            name: "diners_club_international",
            pattern: /^(30[0-5]|309|36|38|39)/,
            valid_length: [14]
          },
          {
            name: "diners_club_us_and_canada",
            pattern: /^(54|55)/,
            valid_length: [16]
          },
          {
            name: "discover",
            pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
            valid_length: [16]
          },
          {
            name: "jcb",
            pattern: /^35(2[89]|[3-8][0-9])/,
            valid_length: [16]
          },
          {
            name: "maestro",
            pattern: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}/, // source for this regex: http://www.richardsramblings.com/regex/credit-card-numbers/
            valid_length: [12, 13, 14, 15, 16, 17, 18, 19]
          },
          {
            name: "mastercard",
            pattern: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)/,
            valid_length: [16]
          },
          {
            name: "visa",
            pattern: /^4/,
            valid_length: [16]
          },
          {
            name: "visa_electron",
            pattern: /^(4026|417500|4508|4844|491(3|7))/,
            valid_length: [16]
          }
        ];

      for (_j = 0, _len1 = card_types.length; _j < _len1; _j++) {
        var card = card_types[_j];

        if (card.pattern.test(number)) {
          if (check_length) {
            if (card.valid_length.indexOf(number.length) > -1) {
              card_name.push(card.name);
            }
          } else {
            card_name.push(card.name);
          }
        }
      }

      if (card_name.length) {
        return card_name.join(" ");
      }

      return null;
    };

    window.Parsley.addValidator(
      "creditcard",
      function(value, requirement) {
        var digit,
          n,
          _ref2,
          valid,
          _j,
          _len1,
          sum = 0;

        value = value.replace(/[ -]/g, "");
        _ref2 = value.split("").reverse();

        for (n = _j = 0, _len1 = _ref2.length; _j < _len1; n = ++_j) {
          digit = _ref2[n];
          digit = +digit;

          if (n % 2) {
            digit *= 2;

            if (digit < 10) {
              sum += digit;
            } else {
              sum += digit - 9;
            }
          } else {
            sum += digit;
          }
        }
        valid = sum % 10 === 0;

        // Checks for specific brands
        if (valid && requirement.length) {
          var valid_cards = requirement.split(","),
            valid = false,
            card = getCreditCardBrand(value, true).split(" ");

          for (var c in card) {
            if (requirement.indexOf(card[c]) > -1) {
              valid = true;
            }
          }
        }

        return valid;
      },
      32
    );

    window.Parsley.addValidator(
      "cvv",
      function(value) {
        return /^[0-9]{3,4}$/.test(value);
      },
      32
    );
  },

  resetPromotionFormValidationOnSubmit: function() {
    let formPromotionSubmitElm = $("#js-promotion-form-submit-button");
    let promotionForm = $("#PromotionModel");
    if (promotionForm && formPromotionSubmitElm) {
      formPromotionSubmitElm.click(function(e) {
        promotionForm.parsley().reset();
      });
    }
  },

  giftCardInvalidator: function() {
    let giftCardErrorNotifierElm = document.querySelector(
      "#js-gift-card-error-notifier"
    );
    let giftCardPhoneNumberInputElm = $("#giftCardPhoneNumber").parsley();
    let giftCardPinCodeInputElm = $("#pinCode").parsley();
    const error_giftcard_number = "giftcard-number-error";
    const error_pin_code = "pin-code-error";
    let InvalidGiftCardNoError = $("#InvalidGiftCardNoErrorMsg").val();
    let InvalidPINNoError = $("#InvalidPINNoErrorMsg").val();
    if (giftCardErrorNotifierElm) {
      giftCardPhoneNumberInputElm.addError(error_giftcard_number, {
        message: InvalidGiftCardNoError
      });
      giftCardPinCodeInputElm.addError(error_pin_code, {
        message: InvalidPINNoError
      });
    }
  }
};
export default validate;
