(function($) {


  var SnsImageFit = (function(element, settings) {
    var self = this,

      config = {
        'imageSelector': '.stage-image',
        'wideClass': 'wider-content'
      },

      resizeTimeout = null,

      isInitial = true,
      wrapper = null,
      image = null,
      isWide = false,

      realImageRatio = 9999;

    /**
     *
     * @returns {SnsImageFit}
     * @protected
     */
    function __construct() {
      config = $.extend(config, settings);

      wrapper = $(element);
      image = wrapper.find(config.imageSelector);

      getImageRealWidth();

      // reload imageRatio in case the image was not loaded
      image.one('load', getImageRealWidth);

      checkWidth();

      bindEvents();

      wrapper.data('SnsImageFit', self);

      return self;
    }

    /**
     * @protected
     */
    function getImageRealWidth() {
      realImageRatio = image.outerWidth() / image.outerHeight();
      checkWidth();
    }

    /**
     * @protected
     */
    function checkWidth() {
      var wrapperRatio = wrapper.outerWidth() / wrapper.outerHeight();

      if ((isInitial || !isWide) && wrapperRatio > realImageRatio) {
        image.addClass(config.wideClass);
        isWide = true;
        isInitial = false;
      } else if ((isInitial || isWide) && wrapperRatio < realImageRatio) {
        image.removeClass(config.wideClass);
        isWide = false;
        isInitial = false;
      }
    }

    /**
     * @protected
     */
    function bindEvents() {
      $(window).on('resize', function() {
        if (resizeTimeout !== null) {
          window.clearTimeout(resizeTimeout);
        }

        window.setTimeout(function() {
          resizeTimeout = null;
          checkWidth();
        }, 10);
      });
    }

    /**
     * @public
     */
    this.recalc = function() {
      checkWidth();
    };

    return __construct();
  });

  $.fn.extend({
    'SnsImageFit': function(settings) {
      return $(this).each(function() {
        new SnsImageFit($(this), settings);
      });
    }
  });
})(jQuery);

// define(function() {
//   (function($) {
//     'use strict';
//
//     var SnsImageFit = (function(element, settings) {
//       var self = this,
//
//         config = {
//           'imageSelector': '.stage-image',
//           'wideClass': 'wider-content'
//         },
//
//         resizeTimeout = null,
//
//         isInitial = true,
//         wrapper = null,
//         image = null,
//         isWide = false,
//
//         realImageRatio = 9999;
//
//       /**
//        *
//        * @returns {SnsImageFit}
//        * @protected
//        */
//       function __construct() {
//         config = $.extend(config, settings);
//
//         wrapper = $(element);
//         image = wrapper.find(config.imageSelector);
//
//         getImageRealWidth();
//
//         // reload imageRatio in case the image was not loaded
//         image.one('load', getImageRealWidth);
//
//         checkWidth();
//
//         bindEvents();
//
//         wrapper.data('SnsImageFit', self);
//
//         return self;
//       }
//
//       /**
//        * @protected
//        */
//       function getImageRealWidth() {
//         realImageRatio = image.outerWidth() / image.outerHeight();
//         checkWidth();
//       }
//
//       /**
//        * @protected
//        */
//       function checkWidth() {
//         var wrapperRatio = wrapper.outerWidth() / wrapper.outerHeight();
//
//         if ((isInitial || !isWide) && wrapperRatio > realImageRatio) {
//           image.addClass(config.wideClass);
//           isWide = true;
//           isInitial = false;
//         } else if ((isInitial || isWide) && wrapperRatio < realImageRatio) {
//           image.removeClass(config.wideClass);
//           isWide = false;
//           isInitial = false;
//         }
//       }
//
//       /**
//        * @protected
//        */
//       function bindEvents() {
//         $(window).on('resize', function() {
//           if (resizeTimeout !== null) {
//             window.clearTimeout(resizeTimeout);
//           }
//
//           window.setTimeout(function() {
//             resizeTimeout = null;
//             checkWidth();
//           }, 10);
//         });
//       }
//
//       /**
//        * @public
//        */
//       this.recalc = function() {
//         checkWidth();
//       };
//
//       return __construct();
//     });
//
//     $.fn.extend({
//       'SnsImageFit': function(settings) {
//         return $(this).each(function() {
//           new SnsImageFit($(this), settings);
//         });
//       }
//     });
//   })(jQuery);
// });
