/*globals define, document, window, $*/

import "../utils/equalizeheights";

export default {
  init: function() {
    let self = this;
    self.initFilter();
    self.equalizeHeights();
    self.initTextareaCounterMin();
    self.initTextareaCounterMax();
  },

  equalizeHeights: function() {
    $(".js_same-height-row-wrap").SnsEqualizeHeights();
  },

  initFilter: function() {
    let filterForm = $(".js_event-filter-form");
    $(document).on("selectchange", filterForm.find(".selector"), function() {
      filterForm.submit();
    });
  },

  initTextareaCounterMin: function() {
    $(document).on("keyup", ".js-count-chars", function(e) {
      let validateMinlength = $(this).attr("data-validate-minlength");
      if (validateMinlength !== undefined) {
        let characterCounter = $(this)
          .parent()
          .find(".js-length-value");
        let charCount = validateMinlength - $(this).val().length;
        characterCounter.text(charCount);

        /*
         * Initial error message must also be adjusted, because parsley reinitializes the message
         * with "data-validate-error-message" on every trigger.
         */
        let validateErrorMessage = $(this).attr("data-validate-error-message");
        let newMessageBefore = validateErrorMessage.substr(
          0,
          validateErrorMessage.indexOf('<span class="js-length-value">')
        );
        let newMessageAfter = validateErrorMessage.substr(
          validateErrorMessage.indexOf("</span>"),
          validateErrorMessage.length + 1
        );
        let newMessage =
          newMessageBefore +
          '<span class="js-length-value">' +
          charCount +
          newMessageAfter;
        $(this).attr("data-validate-error-message", newMessage);
      }
    });
  },

  initTextareaCounterMax: function() {
    $(document).on("keyup", ".js-count-chars", function() {
      let validateMaxlength = $(this).attr("data-validate-maxlength");
      if (validateMaxlength !== undefined) {
        let characterCounter = $(this)
          .parent()
          .find(".js-length-value");
        let charCount = validateMaxlength - $(this).val().length;
        characterCounter.text(charCount);
        if (charCount < 0) {
          characterCounter.text("0");
        }
      }
    });
  }
};
