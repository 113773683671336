import global from "../global";

/*globals define, document, window, $, lightcase */
function Tracking() {}

Tracking.prototype = {
  init: function() {
    let self = this;
    self.productClickEvent();
    self.promotionClickEvent();
    self.loginEvent();
    self.registerEvent();
    self.loyaltyClubSubscriptionEvent();
    self.optEvents();
    self.checkoutBasketEvent();
  },

  productClickEvent: function() {
    $(document).on("click", ".js-product-click-event", function() {
      var eventType = "clickProduct";
      var googleTrackingData = $(this).data();
      var completeData = googleTrackingData["tracking"];
      if (typeof completeData === "string" || completeData instanceof String) {
        completeData = completeData.replace(/(?:\r\n|\r|\n)/g, "");
        completeData = JSON.parse(completeData);
      }

      var itemId = completeData["id"];
      var gtin = completeData["gtin"];
      var itemName = completeData["name"];
      var itemPrice = completeData["price"].split(" ")[0] * 1;
      var variant = completeData["variant"];
      var itemBrand = completeData["brand"];
      var itemCategoryPath = completeData["categoryPath"];
      var position = completeData["position"];
      var list = completeData["list"];
      if (window.va) {
        const $dataTracking = $.parseJSON($(this).attr("data-tracking"));
        window.va("productview", {
          categoryName: itemCategoryPath,
          itemId: $dataTracking["gtin"]
        });
      }
      var url = "/";
      if (completeData["productUrl"] != null) {
        url = completeData["productUrl"];
      }
      dataLayer.push({
        event: eventType,
        ecommerce: {
          products: [
            {
              price: itemPrice,
              name: itemName,
              variant: variant,
              gtin: gtin,
              id: itemId,
              category: itemCategoryPath,
              brand: itemBrand,
              position: position,
              list: list
            }
          ]
        }
      });
    });
  },

  promotionClickEvent: function() {
    $(document).on("click", ".js-promotion-click-event", function() {
      var googleTrackingData = $(this).data();
      var eventType = "eec.promotionClick";
      var action = "click";
      var eventCategory = "promotion_click";
      var completeData = googleTrackingData["tracking"];
      var itemId = completeData["id"].replace(/\D/g, "");
      var itemName = completeData["name"];
      var itemPrice = completeData["price"].split(" ")[0];
      var siteCurrency = completeData["siteCurrency"];
      var siteBrand = completeData["siteBrand"];
      var siteCountryCode = completeData["siteCountryCode"];
      var accountPool = completeData["siteAccountPool"];
      var pageTitle = completeData["pageTitle"];
      var pageNavigationPath = completeData["pageNavigationPath"];
      var trackingPageType = completeData["trackingPageType"];
      var trackingPageCategory = completeData["trackingPageCategory"];
      var customerStatus = completeData["customerStatus"];
      var visitorType = completeData["visitorType"];
      var visitorLanguage = completeData["visitorLanguage"];
      var visitorDevice = completeData["visitorDevice"];
      var teaserId = completeData["teaserId"];
      var teaserName = completeData["teaserName"];
      var teaserType = completeData["teaserType"];
      var teaserPosition = completeData["teaserPosition"];
      var url = "/";
      if (completeData["productUrl"] != null) {
        url = completeData["productUrl"];
      }

      if (trackingPageType === "") {
        trackingPageType = "home";
      }

      if (trackingPageCategory === "") {
        trackingPageCategory = "shop";
      }

      dataLayer.push({
        site: {
          brandName: siteBrand,
          countryCode: siteCurrency,
          accountPool: accountPool,
          currencyCode: siteCountryCode
        },
        product: {
          price: [itemPrice],
          name: [itemName],
          id: [itemId]
        },
        page: {
          name: pageTitle,
          navigationPath: pageNavigationPath,
          category: trackingPageCategory,
          type: trackingPageType
        },
        customer: {
          customerStatus: customerStatus,
          visitorType: visitorType,
          language: visitorLanguage,
          device: visitorDevice
        },
        event: eventType,
        eventValue: {
          action: action,
          label: itemName,
          category: eventCategory
        },
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: teaserId,
                name: teaserName,
                creative: teaserType,
                position: teaserPosition
              }
            ]
          },
          currencyCode: siteCurrency
        },
        eventCallback: function() {
          document.location = url;
        }
      });
    });
  },

  checkoutBasketEvent: function() {
    $(document).on("click", ".js-checkout-event", function(e) {
      e.preventDefault();
      var openCart = $(".cart--cartOpen").length;
      if (openCart === 1) {
        var checkoutEventUrl = $(this).data("checkouteventpath");
        var pageTitle = $(this).data("pageTitle");
        var ajaxUrl =
          window.iShop.config.baseUrl + checkoutEventUrl + pageTitle + "/";
        $.ajax({
          type: "POST",
          headers: global.headers,
          url: ajaxUrl,
          success: function(jsonData) {
            dataLayer.push(jsonData);
          },
          error: function() {
            return false;
          }
        });
      }
    });
  },

  loginEvent: function() {
    $(".js-login-event").on("click", function() {
      var loginEventData = {
        event: "click",
        eventValue: {
          category: "account",
          action: "login",
          label: "",
          value: ""
        }
      };

      dataLayer.push(loginEventData);
    });
  },

  registerEvent: function() {
    $(".js-register-event").on("click", function() {
      var registerEventData = {
        event: "click",
        eventValue: {
          category: "account",
          action: "register",
          label: "",
          value: ""
        }
      };

      dataLayer.push(registerEventData);
      var newsletterSubscribe = $("#newsletter-subscribe").is(":checked");
      var kindOfNewsletter = "newsletter";
      if (newsletterSubscribe) {
        var newsletterEventData = {
          event: "click",
          eventValue: {
            category: "newsletter",
            action: "register",
            label: kindOfNewsletter,
            value: ""
          }
        };
        dataLayer.push(newsletterEventData);
      }
    });
  },

  clickOnProductVariation: function(itemUrl) {
    var skuAttrnamePosition = itemUrl.split("sku=");
    var sku = skuAttrnamePosition[1];
    var ajaxUrl =
      window.iShop.config.baseUrl +
      "/datalayer/json/product-variation/sku/" +
      sku +
      "/position/" +
      0;
    $.ajax({
      url: ajaxUrl,
      success: function(data) {
        var jsonData = JSON.parse(data);
        dataLayer.push(jsonData);
      },
      error: function(event) {
        if (window.console) {
          window.console.log(event);
        }
      }
    });
  },

  loyaltyClubSubscriptionEvent: function() {
    $(".js-subscribe-club365-event").on("click", function() {
      var loginEventData = {
        event: "click",
        eventValue: {
          category: "clubcard",
          action: "register",
          label: "365 Klubi",
          value: ""
        }
      };

      dataLayer.push(loginEventData);
    });
  },

  optEvents: function() {
    $(document).on("click", ".js_close-notice", function() {
      $(document)
        .find(".cookies--root")
        .removeClass("cookies--open");
    });

    $(document).on("click", ".js_display-optout", function(e) {
      e.preventDefault();
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: window.iShop.config.baseUrl + "/ajax/optout",
        success: function(response) {
          var isPresentDialog = $(".js-dialog");
          if (isPresentDialog.is(":visible")) {
            isPresentDialog.remove();
          }
          $("body").prepend(response);
          $(".js-dialog").slideDown("fast");
        },
        error: function() {
          return false;
        }
      });
    });

    $(document).on("click", ".js_display-optin", function(e) {
      e.preventDefault();
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: window.iShop.config.baseUrl + "/ajax/optin",
        success: function(response) {
          var isPresentDialog = $(".js-dialog");
          if (isPresentDialog.is(":visible")) {
            isPresentDialog.remove();
          }
          $("body").prepend(response);
          $(".js-dialog").slideDown("fast");
        },
        error: function() {
          return false;
        }
      });
    });

    function closenotice() {
      $(document)
        .find(".cookies--root")
        .removeClass("cookies--open");
    }

    $(document).on("click", ".js_confirm-opt", function(e) {
      e.preventDefault();
      $(document)
        .find(".dialog-notice")
        .remove();
      var urlSuffix = "/ajax/optOutConfirm";
      if (e.target.id === "trackingOptIn") {
        urlSuffix = "/ajax/optInConfirm";
      }

      $.ajax({
        type: "POST",
        headers: global.headers,
        url: window.iShop.config.baseUrl + urlSuffix,
        success: function(response) {
          closenotice();
          $("body").prepend(response);
          $(".js-dialog").slideDown("fast");
          setTimeout(function() {
            closenotice();
          }, 3200);
        },
        error: function() {
          return false;
        }
      });
    });
  }
};

export default new Tracking();
