var _self;

var newslettersRegisterForm = {
  init: function() {
    _self = this;
    _self.newsletterInterestGroupAccountForm();
  },

  newsletterInterestGroupAccountForm: function() {
    let toggleWrapper = $('[id^="sub-"]');
    toggleWrapper.each(function() {
      let toggleWrap = $(this),
        currentId = toggleWrap.attr("id"),
        toggleCheckboxId = currentId.replace("sub-", "");

      $('[id^="checkbox"][id$="' + toggleCheckboxId + '"]').on(
        "change update click",
        {
          toggleWrap: toggleWrap
        },
        function(ev) {
          if ($(this).is(":checked")) {
            ev.data.toggleWrap.removeClass("hide");
          } else {
            ev.data.toggleWrap.addClass("hide");
          }
        }
      );
    });

    $.each($(document).find("input:checked"), function() {
      $(this).trigger("change");
    });
  }
};
export default newslettersRegisterForm;
