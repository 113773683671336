import "../plugin/passwordstrength";

var _self;

var password = {
  init: function() {
    _self = this;
    _self.showPassword();
    _self.passwordStrength();
    _self.checkPassword();
  },

  passwordStrength: function() {
    var $pwdField = $(".js_password-strength");

    $pwdField.val("");
    $pwdField.passwordStrength(
      {
        minimumChars: 8,
        strengthScaleFactor: 1,
        banMode: "strict"
      },
      function(valid, complexity) {
        if ($(this).hasClass("js_no-indicator")) {
          return;
        }

        var bar = $(".js_passwort-indikator").find(".visibility"),
          barWidth = 100 - complexity + "%";
        bar.css("width", barWidth);

        if (valid) {
          $(".js_submit, .js_btn-accept").removeAttr("disabled");
        } else {
          $(".js_submit, .js_btn-accept").attr("disabled", "disabled");
        }
      }
    );
  },
  checkPassword: function() {
    _self.showOrHideErrorMessageForOmniPasswordReset();
    _self.toggleOmniResetSubmitButton();
  },
  toggleOmniResetSubmitButton: function() {
    $("#passwordRepeat, #passwordInput").on("keyup", function(e) {
      e.preventDefault();
      var isPasswordRepeatEmpty = $("#passwordRepeat").val() === "";
      var isInputAndRepeatPassSame =
        $("#passwordInput").val() !== $("#passwordRepeat").val();
      if (isInputAndRepeatPassSame && !isPasswordRepeatEmpty) {
        $("#omniPassResetBtn").attr("disabled", "disabled");
      } else {
        if (!isPasswordRepeatEmpty) {
          $("#omniPassResetBtn").removeAttr("disabled");
        }
      }
    });
  },
  showOrHideErrorMessageForOmniPasswordReset: function() {
    $("#passwordRepeat, #passwordInput").on("blur", function(e) {
      e.preventDefault();
      var isPasswordRepeatEmpty = $("#passwordRepeat").val() === "";
      var isInputAndRepeatPassSame =
        $("#passwordInput").val() !== $("#passwordRepeat").val();
      if (isInputAndRepeatPassSame && !isPasswordRepeatEmpty) {
        $("#errorMsg").show();
      } else {
        $("#errorMsg").hide();
      }
    });
  },
  showPassword: function() {
    $(".js_make-visible").on("change", function(e) {
      if ($(this).is(":checked")) {
        $(".js_make-visible-element").attr("type", "text");
      } else {
        $(".js_make-visible-element").attr("type", "password");
      }
    });
  }
};
export default password;
