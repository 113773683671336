import { ContinousSlider } from "./continousSlider";

const ProductSlider = function(_element) {
  /**
   * @type {HTMLElement}
   */
  const element = _element;

  element.ProductSlider = this;

  const hoverContainerElement = element.querySelector(".hover-container");

  const onMouseEnter = function(event) {
    if (hoverContainerElement.originProductCardContainerElement) {
      return;
    }

    event.preventDefault();

    const productCardContainerElement = event.currentTarget;

    const productCardContainerElementRect = productCardContainerElement.getBoundingClientRect();
    const productCardElement = productCardContainerElement.firstElementChild;
    hoverContainerElement.appendChild(productCardElement);
    hoverContainerElement.originProductCardContainerElement = productCardContainerElement;

    const itemsElement = element.querySelector(".items");

    productCardElement.style.left =
      productCardContainerElement.offsetLeft - itemsElement.scrollLeft + "px";
    productCardElement.style.width = `${productCardContainerElementRect.width}px`;
    productCardElement.style.height = `${productCardContainerElementRect.height}px`;
    productCardElement.addEventListener("mouseleave", onMouseLeave);
  };

  const onMouseLeave = function(event) {
    if (!hoverContainerElement.originProductCardContainerElement) {
      return;
    }

    event.preventDefault();

    const productCardElement = event.currentTarget;

    productCardElement.removeEventListener("mouseleave", onMouseLeave);

    const productCardContainerElement =
      hoverContainerElement.originProductCardContainerElement;
    productCardContainerElement.appendChild(productCardElement);
    hoverContainerElement.originProductCardContainerElement = undefined;
    productCardElement.style.left = "";
    productCardElement.style.width = "";
    productCardElement.style.height = "";
  };

  const bpMmin = getComputedStyle(document.documentElement).getPropertyValue(
    "--bp-m-min"
  );
  const mediaQueryList = matchMedia(`screen and (min-width: ${bpMmin})`);
  const onChange = event => {
    const productCardContainerElements = element.querySelectorAll(
      ".productCard-container"
    );
    if (event.matches) {
      for (const productCardContainerElement of productCardContainerElements) {
        productCardContainerElement.addEventListener(
          "mouseenter",
          onMouseEnter
        );
      }
    } else {
      for (const productCardContainerElement of productCardContainerElements) {
        productCardContainerElement.removeEventListener(
          "mouseenter",
          onMouseEnter
        );
      }
    }
  };
  mediaQueryList.addEventListener("change", onChange);

  onChange(mediaQueryList);

  const itemsElement = element.querySelector(".items");
  const onScroll = () => {
    if (itemsElement.timeoutId) {
      clearTimeout(itemsElement.timeoutId);
      itemsElement.timeoutId = undefined;
    } else {
      const productCardContainerElements = element.querySelectorAll(
        ".productCard-container"
      );
      for (const productCardContainerElement of productCardContainerElements) {
        productCardContainerElement.removeEventListener(
          "mouseenter",
          onMouseEnter
        );
      }
    }

    itemsElement.timeoutId = setTimeout(() => {
      itemsElement.timeoutId = undefined;
      onChange(mediaQueryList);
    }, 100);
  };
  itemsElement.addEventListener("scroll", onScroll);

  if (element.closest(".product-detail-page")) {
    $(document).on("addToCartLayer.ready", (event, data) => {
      init(data.element[0]);
    });
  }

  return this;
};

const init = element => {
  element = element || document;
  for (const productSlider of element.querySelectorAll(
    ".continous-slider.product-slider"
  )) {
    new ContinousSlider(productSlider);
    new ProductSlider(productSlider);
  }
};

export default {
  init: data => {
    init();
  }
};
