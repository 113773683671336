/*globals define, document, window, $, ShoeSizeMe*/
export default {
  init: function() {
    var _self = this;

    /* step 1: init callback in global scope */
    window.gtShoeSizeMe = window.gtShoeSizeMe || {};
    (function() {
      /**
       * callback used by ShoeSize.me
       * see original ssm_select_size()
       * @param recommendation JSON object with the following structure:
       * {
       *   "size": { "eu":"38.0", "uk":"5.5", "us_m":"5.5", "us_w":"7.5" },
       *   "available": true,
       *   "scale": "eu",
       *   "shoeId": "136518"
       * }
       */
      this.selectVariation = function(recommendation) {
        if (true !== recommendation.available) {
          return;
        }
        var recommendedSize = parseInt(recommendation.size.eu, 10);
        var classToSelect =
          ".js-variation-item[data-size-eu='" + recommendedSize + "']";
        var sizeButton = $(classToSelect);

        if (!sizeButton.hasClass("is-active")) {
          sizeButton.trigger("click");
        }
      };

      /**
       * callback used by ShoeSize.me
       * see original ssm_add_to_cart()
       * @param recommendation JSON object with the following structure:
       * {
       *   "size": { "eu":"38.0", "uk":"5.5", "us_m":"5.5", "us_w":"7.5" },
       *   "available": true,
       *   "scale": "eu",
       *   "shoeId": "136518"
       * }
       */
      this.addToCart = function(recommendation) {
        if (true !== recommendation.available) {
          return;
        }

        if ("undefined" !== typeof ShoeSizeMe) {
          ShoeSizeMe.close();
        }
        $(".js-add-to-cart").trigger("click");
      };
    }.apply(window.gtShoeSizeMe));

    /* step 2: init ShoeSizeMe */
    var _ssmButton = $("span.ShoeSizeMe");
    var _productId = _ssmButton.data("product-id");
    var _scale = _ssmButton.data("scale");

    (function(d, t, s, a, f) {
      a = d.createElement(t);
      f = d.getElementsByTagName(t)[0];
      a.async = 1;
      a.src = s;
      a.text =
        "{ shopID: 279375,  " + // your shop_id as provided by ShoeSize.Me
        'shoeID: "' +
        _productId +
        '",' + // your webshop's id for the current shoe
        'locale: "de",' + // current locale (downcase)
        'scale: "' +
        _scale +
        '",' + // size scale of the current product (downcase)
        "newRecommendation: function(recommendation) {gtShoeSizeMe.selectVariation(recommendation)}," +
        "inCart: function(recommendation) {gtShoeSizeMe.addToCart(recommendation)}}";
      f.parentNode.insertBefore(a, f);
    })(document, "script", "https://shoesize.me/assets/plugin/loader.js");

    /* step 3: attach events for interaction with ShoeSizeMe */
    _self._bindEvents();
  },

  _bindEvents: function() {
    $(document).on("variationChanged", function(event, data) {
      //rebuild of the button has to be done after switching color or size
      ShoeSizeMe.rebuildButton(data.productId);

      //check of available sizes has to be done just after switching color
      if ("DIM1" === data.dimension) {
        ShoeSizeMe.checkAvailability(data.productId);
      }
    });
  }
};
