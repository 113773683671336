var SnsHeatpoints = function(element, userConfig) {
  var self = this,
    wrapper = element,
    imageElements = null,
    imagePoints = [],
    timeout = null,
    windowE = null;

  var config = {};

  function __construct() {
    windowE = $(window);
    imageElements = wrapper.find(".js_heat-element");
    for (var i = 0; i < imageElements.length; i++) {
      var obj = $(imageElements[i]);
      imagePoints[i] = currentImageElements(obj);
    }
    bindEvents();
  }

  function currentImageElements(source) {
    var obj = {};
    obj.point = source.find(".js_heat-point");
    obj.content = source.find(".js_heat-info");
    obj.listelement = wrapper.find(
      '.js_heat-mobile[data-heat-source="' + source.data().heatTarget + '"]'
    );
    obj.timeout = null;

    return obj;
  }

  function bindEvents() {
    for (var i = 0; i < imagePoints.length; i++) {
      var obj = imagePoints[i];
      desktop(obj, i);
    }
  }

  function desktop(obj, i) {
    var currentPoint = obj.point,
      currentInfo = obj.content;

    currentPoint.on("click", function(e) {
      if ($(this).hasClass("js-disable-click")) e.preventDefault();
    });

    currentPoint.on(
      "mouseover",
      {
        object: obj,
        index: i
      },
      function(e) {
        if (windowE.width() > 801) {
          mouseOver(
            e.data.object.point,
            e.data.object.content,
            e.data.object.listelement,
            e.data.index
          );
        }
      }
    );
    currentPoint.on(
      "mouseleave",
      {
        object: obj,
        index: i
      },
      function(e) {
        if (windowE.width() > 801) {
          mouseOut(e.data.object.point, e.data.object.content, e.data.index);
        }
      }
    );
    currentInfo.on(
      "mouseover",
      {
        object: obj,
        index: i
      },
      function(e) {
        if (windowE.width() > 801) {
          mouseOver(
            e.data.object.point,
            e.data.object.content,
            e.data.object.listelement,
            e.data.index
          );
        }
      }
    );
    currentInfo.on(
      "mouseleave",
      {
        object: obj,
        index: i
      },
      function(e) {
        if (windowE.width() > 801) {
          mouseOut(e.data.object.point, e.data.object.content, e.data.index);
        }
      }
    );
  }

  function mouseOver(point, info, source, i) {
    if (info.find(".js_heat-mobile").is(":empty")) {
      return false;
    }
    point.addClass("active");
    info.addClass("active");
    point.parent().css("z-index", "10");

    clearTimeout(imagePoints[i].timeout);

    if (info.html() === "") {
      var currentElement = source.clone();
      currentElement.removeAttr("data-heat-source").removeClass("heat-mobile");

      if (currentElement.find("[data-rel]").length) {
        var innerLinks = currentElement.find("a");
        var currentSlideshowRelation = $(innerLinks[0]).data().rel;
        currentSlideshowRelation =
          currentSlideshowRelation + parseInt(Math.random());
        for (var j = 0; j < innerLinks.length; j++) {
          var obj = $(innerLinks[j]);
          obj.attr("data-rel", currentSlideshowRelation);
        }
        innerLinks.lightcase();
      }

      info.html(currentElement);
    }
  }

  function mouseOut(point, info, i) {
    point.removeClass("active");
    imagePoints[i].timeout = window.setTimeout(function() {
      info.removeClass("active");
      point.parent().css("z-index", "0");
    }, 200);
  }

  return __construct();
};

$.fn.extend({
  SnsHeatpoints: function(options) {
    return $(this).each(function() {
      new SnsHeatpoints($(this), options);
    });
  }
});

$(".js_heat-points").SnsHeatpoints();
