import "../plugin/customer_search";

export default {
  init: function() {
    var checkout =
      $(location)
        .attr("pathname")
        .indexOf("checkout") !== -1;
    var subject = "globalbanner";
    var iCustomerSearchFormId = "#" + subject + "-icustomer-search-form";
    var iCustomerSelectionFormId = "#" + subject + "-icustomer-selection-form";
    var globalBannerAxCustomerSearchBtnId =
      "#global-banner-ax-customer-search-toggle-btn";
    var globalBannerCustomerSearchBtnId =
      "#global-banner-customer-search-toggle-btn";

    $(document).CustomerSearch({
      subject: subject,
      searchFormId: iCustomerSearchFormId,
      selectionFormId: iCustomerSelectionFormId
    });

    var $globalBannerCustomerSearchBtnId = $(globalBannerCustomerSearchBtnId);
    var $globalBannerAxCustomerSearchBtnId = $(
      globalBannerAxCustomerSearchBtnId
    );
    if (checkout) {
      $globalBannerCustomerSearchBtnId.prop("disable", "true");
    } else {
      $globalBannerCustomerSearchBtnId.prop("disable", "false");
      $globalBannerCustomerSearchBtnId.click(function() {
        $("#global-banner-customer-search-pannel").toggle();
      });
    }
    $globalBannerAxCustomerSearchBtnId.click(function() {
      $("#global-banner-customer-search-pannel").toggle();
    });
  }
};
