const playground = {
  init: function() {
    const form = document.getElementById("playground");
    form.addEventListener("submit", event => {
      event.preventDefault();

      return false;
    });

    const onChange = () => {
      console.log(`formData:`);
      const formData = new FormData(form);
      formData.forEach((value, key) => {
        console.log(`  ${key}: ${value}`);
      });

      const outputContainer = form.querySelector("#output-container");

      outputContainer.classList.remove("info-box");
      if (formData.has("background") && formData.get("background").length) {
        outputContainer.classList.add(formData.get("background"));
      }

      if (outputContainer.firstElementChild) {
        outputContainer.removeChild(outputContainer.firstElementChild);
      }

      let element = document.createElement(formData.get("element"));
      outputContainer.appendChild(element);

      let text = document.createElement("span");
      text.appendChild(document.createTextNode(formData.get("text")));

      element.appendChild(text);

      if (formData.has("href")) {
        element.href = "#";
      }
      if (formData.has("disabled")) {
        element.setAttribute("disabled", "");
      }

      element.className = "";
      if (
        formData.has("classes.color") &&
        formData.get("classes.color").length
      ) {
        element.classList.add(formData.get("classes.color"));
      }

      formData.forEach((value, key) => {
        if (key !== "classes") {
          return;
        }
        if (!value || !value.length) {
          return;
        }

        element.classList.add(value);
      });
    };

    form.addEventListener("change", onChange);
    onChange();
  }
};

export default playground;
