/*globals define, document, window, $ */

import recaptcha from "../modules/recaptcha";
import global from "../global";

var self;
export default {
  origFormButton: null,
  init: function() {
    self = this;

    $(document).on("click", ".js_appointment_button", function(e) {
      e.preventDefault();
      self.submitForm();
    });
  },
  submitForm: function() {
    var origWrapper = $("#js_appointment"),
      origForm = origWrapper.find("form");
    $.ajax({
      url: origForm.attr("action"),
      method: "POST",
      headers: global.headers,
      data: origForm.serialize(),
      dataType: "html",
      success: function(data) {
        // replace form
        origWrapper.replaceWith(data);
        var identifier = $(data).find("[data-require-captcha]");
        $.each(identifier, function(i) {
          var recaptchaId = $(this).attr("id");
          recaptcha.renderRecaptcha(recaptchaId);
        });
      },
      error: function(e) {
        if (window.console) {
          window.console.log(e);
        }
      }
    });
  }
};
