import "core-js/features/promise";
import "core-js/features/object";
import "core-js/features/array/includes";
import "core-js/features/string/includes";
import "core-js/features/url-search-params";

import base from "./base";

/**
 *  RequireJS main config
 *
 *  namespace       To avoid compatibility problems with external javascript.
 *                  The namespace is set automatically, you do not have to set in your modules
 *
 *  path            Modules to be load
 *  shim            For non-AMD scripts
 *  modules         To create the script bundle and set the namespace of the modules
 *
 */

$(document).ready(function() {
  // Do my stuff here...
  base.init();
});
