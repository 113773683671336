var checkout_sub_toggle = {
  subFormToggleElement: function(element, toggleWrap, validatedForm) {
    if (toggleWrap.length) {
      element.on(
        "change update",
        {
          toggleWrap: toggleWrap
        },
        function(ev) {
          var subformWrapper = ev.data.toggleWrap;
          if ($(this).is(":checked")) {
            subformWrapper.removeClass("hide");
          } else {
            subformWrapper.addClass("hide");
          }
          validatedForm.parsley().refresh();
        }
      );
    }
  }
};
export default checkout_sub_toggle;
