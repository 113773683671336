import "../lib/jquery.typeahead";

$.fn.extend({
  AddressCompletion: function(conf) {
    return $(this).each(function() {
      new AddressCompletion($(this), conf);
    });
  }
});

var AddressCompletion = function(element, settings) {
  var conf = {};

  function __construct() {
    element = $(element);
    var wrapper = $(element).closest(".address-autocompletion-wrapper");
    conf = $.extend(true, conf, settings);

    var easyux_results = new Bloodhound({
      datumTokenizer: function(easyuxdata) {
        return Bloodhound.tokenizers.whitespace(easyuxdata.value);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      limit: 15,
      remote: {
        url:
          "https://api.easyux.de/globe_hh_TBmnuMiK6cMFL/de/broadsearch/%QUERY",
        wildcard: "%QUERY",
        filter: function(easyux_results) {
          return $.map(easyux_results.details, function(easyux_result) {
            return {
              street_name: easyux_result.street_name,
              city_name: easyux_result.city_name,
              zipcode: easyux_result.zipcode
            };
          });
        }
      }
    });
    easyux_results.initialize();
    $(element)
      .typeahead(null, {
        limit: 15,
        displayKey: function(easyuxdata) {
          return easyuxdata.street_name;
        },
        templates: {
          suggestion: function(easyuxdata) {
            return (
              "<p><strong>" +
              easyuxdata.street_name +
              "</strong> " +
              easyuxdata.zipcode +
              " " +
              easyuxdata.city_name +
              "</p>"
            );
          }
        },
        source: easyux_results.ttAdapter()
      })
      .on("typeahead:selected", function(obj, easyuxdata) {
        $.each(easyuxdata, function(key, value) {
          wrapper
            .find('[data-easyux-address-type="' + key + '"]')
            .val(value)
            .addClass("success");
        });
      });

    wrapper.find(".js-street-number");
  }
  return __construct();
};

/**
 * Auto-Init
 */
$(".js-address-autocompletion").AddressCompletion();

export default AddressCompletion;
