(function($) {
  const SnsStickyNavi = function(element) {
    const self = this;

    let stickyNavigationWrap = null;
    let stickyNavigationContent = null;

    function __construct() {
      stickyNavigationWrap = element;
      stickyNavigationContent = stickyNavigationWrap.querySelector(
        ".js_sticky-navi-content"
      );

      $.SnsStickyNaviCollector().add(self);

      setTimeout(init);

      return self;
    }

    function init() {
      stickyNavigationWrap.style.height =
        stickyNavigationContent.getBoundingClientRect().height + "px";

      document
        .getElementById("header-and-navi")
        .addEventListener("headerAndNavi-resized", updateFixedPosition);

      window.addEventListener("scroll", updateFixedPosition);

      updateFixedPosition();

      window.addEventListener("resize", () => {
        stickyNavigationContent.classList.remove("is-open");
        stickyNavigationWrap.style.height =
          stickyNavigationContent.getBoundingClientRect().height + "px";
      });

      for (const stickyNavigationLink of stickyNavigationContent.querySelectorAll(
        ".js_sticky-navi-link"
      )) {
        stickyNavigationLink.addEventListener("click", function(ev) {
          ev.preventDefault();

          stickyNavigationContent.classList.remove("is-open");

          scrollToHash(stickyNavigationLink.getAttribute("href"));
        });
      }

      const stickyNavigationLabel = stickyNavigationContent.querySelector(
        ".js_sticky-head"
      );
      stickyNavigationLabel.addEventListener("click", () => {
        stickyNavigationContent.classList.toggle("is-open");
      });

      const bpMmin = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bp-m-min");
      const mediaQueryList = matchMedia(`screen and (min-width: ${bpMmin})`);
      const onChange = () => {
        setTimeout(() => {
          scrollToHash(document.location.hash);
        }, 100);
      };
      mediaQueryList.addEventListener("change", onChange);

      window.addEventListener("hashchange", onChange);

      onChange();
    }

    const scrollToHash = uri => {
      let target = uri.split("#")[1];

      if (!target) {
        return;
      }

      if (document.location.hash !== uri) {
        const url = new URL(document.location.href);
        url.hash = uri;

        document.location.replace(url);
        return;
      }

      const elementToScrollTo = document.getElementById(target);
      const top =
        elementToScrollTo.offsetTop -
        stickyNavigationContent.getBoundingClientRect().bottom;
      window.scrollTo({ top: top, behavior: "smooth" });
    };

    const updateFixedPosition = () => {
      const headerHeight = document
        .getElementById("header-and-navi")
        .getBoundingClientRect().height;

      const stickyNavigationPlaceHolder = document.querySelector(
        ".sticky-navi-placeholder"
      );
      const stickyNavigationPlaceHolderTop = stickyNavigationPlaceHolder.getBoundingClientRect()
        .top;

      const stickyNavigation = document.querySelector(
        ".js_sticky-navi-content"
      );
      if (window.scrollY > 0 && stickyNavigationPlaceHolderTop < headerHeight) {
        const top = parseFloat(getComputedStyle(stickyNavigation).top);
        if (top !== headerHeight) {
          stickyNavigation.style.top = headerHeight + "px";
          stickyNavigation.style.position = "fixed";
        }
      } else {
        stickyNavigation.style.top = "";
        stickyNavigation.style.position = "";
      }
    };

    /**
     * @public
     * @returns {number}
     */
    this.getTop = function() {
      return stickyNavigationWrap.getBoundingClientRect().top;
    };

    /**
     * @public
     * @returns {number}
     */
    this.getHeight = function() {
      return stickyNavigationContent.getBoundingClientRect().height;
    };

    return __construct();
  };

  const SnsStickyNaviCollector = function() {
    const navigations = [];

    /**
     * @public
     * @param {object} navigation
     */
    this.add = function(navigation) {
      navigations.push(navigation);
    };

    /**
     * @public
     * @returns {Array}
     */
    this.get = function() {
      return navigations;
    };

    /**
     * @public
     * @param {number} start
     * @returns {number}
     */
    this.getSpaceAbove = function(start) {
      let space = 0;
      for (let i = navigations.length; i--; ) {
        const navigation = navigations[i];

        if (navigation.getTop() < start) {
          space += navigation.getHeight();
        }
      }

      return space;
    };

    return this;
  };

  const singleton = new SnsStickyNaviCollector();

  $.extend({
    SnsStickyNaviCollector: function() {
      return singleton;
    }
  });

  $.fn.extend({
    SnsStickyNavi: function() {
      return $(this).each(function() {
        new SnsStickyNavi(this);
      });
    }
  });
})(jQuery);
