import datalayer from "../modules/datalayer";
import "../plugin/lightcase";
import minicart from "../modules/minicart";
import global from "../global";
import insurance from "./insurance";

function Cart() {}

Cart.prototype = {
  init: function() {
    let _this = this;
    _this._countHandler();
    _this._countInputFieldHandler();
    _this._deleteHandler();
    _this._addInsuranceHandler();
    _this._cartButtonsHandler();
    _this.changeRecommendedProductVariation();
    _this.addRecommendedProductToCart();
    _this.changeVariation();
    _this.toCheckoutBtnStickyness();
  },

  el: {
    PRODUCT_POSITION: "product-position",
    EDITING_CLASS: "editing",
    PRODUCT_LIST: "cart-module"
  },

  toCheckoutBtnStickyness: function() {
    window.onscroll = function() {
      let sumWrapElement = document.getElementById("cart-sum-wrap");
      let visibleScreen = window.innerHeight;

      if (sumWrapElement != null) {
        let btnPos = sumWrapElement.getBoundingClientRect().top;
        let stickyButtonElement = $("#cart-sticky-button");

        if (btnPos - visibleScreen > 0) {
          stickyButtonElement.removeClass("hide");
        } else if (btnPos < 0) {
          stickyButtonElement.removeClass("hide");
        } else {
          stickyButtonElement.addClass("hide");
        }
      }
    };
  },

  _countHandler: function() {
    var _this = this;
    $(document).on("click", ".count-btn", function() {
      var position = _this.getPosition($(this));
      var parentDiv = $(this).closest("div");
      var currentTotalAmount = $(parentDiv)
        .find(".js-position-count")
        .val();
      var changingAmount = $(this).attr("data-value");
      var count = +currentTotalAmount + +changingAmount;
      var ajaxUrl = parentDiv.attr("data-target") + "&amount=" + count;
      var itemId = parentDiv.attr("data-item-id");

      $.ajax({
        url: ajaxUrl,
        cache: false,
        beforeSend: function() {
          position.addClass(_this.el.EDITING_CLASS);
        }
      })
        .then(function(data) {
          if (data.success) {
            _this.fireDataLayer(itemId, changingAmount);
            $.get(location.href, function(data) {
              _this.insertUpdatedCart(data);
            });
          } else {
            _this.unableCartAction();
            position.removeClass(_this.el.EDITING_CLASS);
          }
        })
        .catch(function(error) {
          if (window.console) {
            window.console.log(error);
          }
        });
    });
  },

  _countInputFieldHandler: function() {
    var _this = this;

    $(document).on("keyup focusout", ".js-position-count", function(e) {
      if (e.keyCode === 13 || e.type === "focusout") {
        var position = _this.getPosition($(this));
        var parentDiv = $(this).closest("div");
        var positionCountElement = $(parentDiv).find(".js-position-count");
        var count = positionCountElement.val();
        var initCount = positionCountElement.data("initial-value");
        var itemId = parentDiv.data("item-id");

        if (count === 0) {
          var parentParentDiv = $(this).closest(".counter-row");
          var trashIcon = $(parentParentDiv).find(".js-basket-trash-icon");
          return trashIcon.trigger("click");
        }

        if (count < 0 || window.isNaN(count)) {
          $(this).val(1);
          count = 1;
        }

        var ajaxUrl = parentDiv.attr("data-target") + "&amount=" + count;
        var changingAmount = count - initCount;

        $.ajax({
          url: ajaxUrl,
          cache: false,
          beforeSend: function() {
            position.addClass(_this.el.EDITING_CLASS);
          }
        })
          .then(function(data) {
            if (data.success) {
              _this.fireDataLayer(itemId, changingAmount);
              $.get(location.href, function(data) {
                _this.insertUpdatedCart(data);
              });
            } else {
              _this.unableCartAction();
            }
          })
          .catch(function(error) {
            if (window.console) {
              window.console.log(error);
            }
          });
      }
    });
  },

  _deleteHandler: function() {
    var _this = this;
    $(document).on("click", ".js-basket-trash-icon", function(e) {
      e.preventDefault();

      var position = _this.getPosition($(this));
      var ajaxUrl = $(this).attr("data-target");
      var itemId = $(this).attr("data-item-id");
      //we need the basket url without possible param voucher
      var reloadUrl = $(this).attr("data-reload-target");
      var changingAmount = -$(this).data("initial-value");
      if (
        !(ajaxUrl.includes("scoutupdate") || ajaxUrl.includes("suomenupdate"))
      ) {
        _this._submitAjax(ajaxUrl, reloadUrl, itemId, changingAmount, position);
      }
    });

    $(document).on("click", ".js-freeitem-trash-icon", function() {
      e.preventDefault();

      var ajaxUrl = $(this).attr("data-target");
      var reloadUrl = $(this).attr("data-reload-target");
      let csrf = global.headers["X-CSRF-TOKEN"];
      $.ajax({
        url: ajaxUrl + "?_csrf=" + csrf,
        cache: false,
        method: "POST",
        data: {
          promotion: $(this).attr("data-promotion"),
          itemId: $(this).attr("data-item-id")
        }
      })
        .then(function() {
          window.location.href = reloadUrl;
        })
        .catch(function(error) {
          if (window.console) {
            window.console.log(error);
          }
        });
    });
  },

  _addInsuranceHandler: function() {
    let _this = this;
    $(document).on("click", ".js_insurance-add", function() {
      let position = _this.getPosition($(this));
      let ajaxUrl = $(this).attr("data-target");
      let itemId = $(this).attr("data-item-id");
      //we need the basket url without possible param voucher
      let reloadUrl = $(this).attr("data-reload-target");
      let changingAmount = $(this).data("initial-value");

      _this._submitAjax(ajaxUrl, reloadUrl, itemId, changingAmount, position);
    });
  },

  _submitAjax: function(ajaxUrl, reloadUrl, itemId, changingAmount, position) {
    var _this = this;
    $.ajax({
      url: ajaxUrl,
      cache: false,
      beforeSend: function() {
        position.addClass(_this.el.EDITING_CLASS);
      }
    })
      .success(function(data) {
        _this.insertUpdatedCart(data);
        _this.fireDataLayer(itemId, changingAmount);
        insurance.initInsuranceHintLayer();
      })
      .fail(function(error) {
        if (window.console) {
          window.console.log(error);
        }
      });
  },

  fireDataLayer: function(itemId, changingAmount, optSuccessCallback) {
    var _this = this;
    if (+changingAmount < 0) {
      _this.fireDataLayerRemove(itemId, changingAmount, optSuccessCallback);
    } else {
      _this.fireDataLayerAdd(itemId, changingAmount, optSuccessCallback);
    }
    datalayer.updateVoyadoCartTracking();
  },
  fireDataLayerRemove: function(itemId, changingAmount, optSuccessCallback) {
    datalayer.removeFromCart(itemId, changingAmount, optSuccessCallback);
  },
  fireDataLayerAdd: function(itemId, changingAmount, optSuccessCallback) {
    datalayer.addToCart(itemId, changingAmount, false, optSuccessCallback);
  },

  _cartButtonsHandler: function(countField) {
    // History back
    $(document).on("click", ".js-basket-history-back", function(e) {
      if (1 < history.length && document.referrer) {
        e.preventDefault();
      }
      window.history.back();
    });

    // Proceed to checkout
    $(document).on("click", ".js-basket-proceed-checkout", function() {
      var url = $(this).attr("data-target");
      window.location.href = url;
    });
  },

  addRecommendedProductToCart: function() {
    var _this = this;
    $(document).on("click", ".js-add-to-cart", function(e) {
      e.preventDefault();
      var form = $("#recommended-form");
      var itemId = $(this).attr("data-item-id");

      var responseCode200Callback = function() {
        window.location.reload(true);
      };

      $.ajax({
        url: form.attr("action"),
        data: form.serialize(),
        success: function(data) {
          _this.fireDataLayerAdd(itemId, +1, responseCode200Callback);
          minicart._fetchMetadata();
          datalayer.updateVoyadoCartTracking();
        },
        error: function(e) {
          if (window.console) {
            window.console.log(e);
          }
        }
      });
    });
  },

  changeRecommendedProductVariation: function() {
    const _this = this;
    let colorSelect = document.getElementById("dim_attribute_1");

    $(document).on("change", "#dim_attribute_1", function() {
      colorSelect = document.getElementById("dim_attribute_1");
      if (colorSelect != null) {
        const selectedColorOption =
          colorSelect.options[colorSelect.selectedIndex];
        const sizeSelect = document.getElementById("dim_attribute_2");

        //update color
        document.getElementById("recommended-product-image").src =
          selectedColorOption.dataset.colorImage;

        const selectedColorSizes = selectedColorOption.dataset.variationSizes
          .replaceAll("[", "")
          .replaceAll("]", "")
          .split(", ");
        const sizeChildren = sizeSelect.childNodes;

        for (let i = 0; i < sizeChildren.length; i++) {
          sizeSelect.remove(sizeSelect.selectedIndex);
        }

        for (let i = 0; i < selectedColorSizes.length; i++) {
          const sizesEntry = selectedColorSizes[i];

          const sizesEntryArray = sizesEntry.split("|", 2);
          const sizeEntryItemId = sizesEntryArray[0].trim();
          const sizeEntrySize = sizesEntryArray[1].trim();

          const optionElement = document.createElement("option");
          optionElement.value = sizeEntrySize;
          optionElement.text = sizeEntrySize;
          optionElement.setAttribute("data-size-id", sizeEntryItemId);

          sizeSelect.append(optionElement);
        }
        _this.updateSizeSelect(_this);
      }
    });

    $(document).on("change", "#dim_attribute_2", function() {
      _this.updateSizeSelect(_this);
    });
  },

  updateSizeSelect: function(_this) {
    const sizeSelect = document.getElementById("dim_attribute_2");
    if (sizeSelect != null) {
      const selectedSizeOption = sizeSelect.options[sizeSelect.selectedIndex];

      document.getElementById("reco-article-submit").dataset.itemId =
        selectedSizeOption.dataset.sizeId;
      document.getElementById("hidden-id-input").value =
        selectedSizeOption.dataset.sizeId;
      document.getElementById("hidden-id-input").dataset.itemId =
        selectedSizeOption.dataset.sizeId;

      _this.updateRecoPriceInfo(selectedSizeOption);
    }
  },

  updateRecoPriceInfo: function(selectedSizeOption) {
    const recoPriceContainer = document.getElementById("reco-price-container");
    const priceMap = document.getElementById("price-map");
    if (recoPriceContainer !== null && priceMap !== null) {
      const recoPriceList = priceMap.dataset.priceMap
        .replaceAll("{", "")
        .replaceAll(";}", "")
        .split(";,");
      for (let i = 0; i < recoPriceList.length; i++) {
        if (recoPriceList[i].includes(selectedSizeOption.dataset.sizeId)) {
          const minPrice = recoPriceList[i].split("|")[0].split("=")[1];
          const isSale = recoPriceList[i].split("|")[1].replace("sale_", "");
          const rrpPrice = recoPriceList[i]
            .split("|")[2]
            .replace("rrp_price_", "");
          const basePriceInfo = recoPriceList[i]
            .split("|")[3]
            .replace("base_price_info_", "");

          var saleClass = isSale === "true" ? "is-sale" : "";
          var stroke =
            rrpPrice !== "undefined"
              ? '<span class="stroke">' + rrpPrice + "</span>"
              : "";

          var basePrice = basePriceInfo
            ? '<span class="unit-info">' + basePriceInfo + "</span>"
            : "";

          var price_wrapper =
            '<div class="price-wrapper ' +
            saleClass +
            '" id="reco-price-container">' +
            stroke +
            '<span class="price">' +
            minPrice +
            "</span>" +
            basePrice +
            "</div>";
          $("#reco-price-container").replaceWith(price_wrapper);
        }
      }
    }
  },

  changeVariation: function() {
    $(document).on("selectchange", ".selector.js-variation", function(event) {
      var $form = $(this).closest("form");
      var variation_url = $form.attr("action") + "?" + $form.serialize();
      $.ajax({
        url: variation_url,
        success: function(data) {
          $form.closest(".js-giveaway-position").replaceWith(data);
          $(".selector").SnsSelector();
        },
        error: function(error) {
          if (window.console) {
            window.console.log(error);
          }
        }
      });
    });
  },

  /**
   * Returns the product position within the clicked element for example.
   * @param el
   */
  getPosition: function(el) {
    var _this = this;
    return el.closest("." + _this.el.PRODUCT_POSITION);
  },

  /**
   * Copies card elements from request response.
   * ToDo: Don't crab the whole website, just the cart or JSON.
   * @param updatedCart
   */
  insertUpdatedCart: function(updatedCart) {
    var _this = this;
    var copiedCart = $(updatedCart).find("." + _this.el.PRODUCT_LIST);
    $(document)
      .find("." + _this.el.PRODUCT_LIST)
      .replaceWith(copiedCart);

    minicart.reset();
    minicart._fetchMetadata();
    insurance.initInsuranceHintLayer();
  },

  /**
   * If amount change can't be applied to the card
   */
  unableCartAction: function() {
    var _this = this;
    lightcase.start({
      href: "#amount-warning-modal",
      onClose: {
        reload: function() {
          $.get(location.href, function(data) {
            _this.insertUpdatedCart(data);
          });
        }
      }
    });
  }
};
export default new Cart();
