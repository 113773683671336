import anchor from "./anchor";

function closeCategoryDropdown(element) {
  element.querySelectorAll(".open").forEach(function(openElement) {
    openElement.classList.remove("open");
  });
}

export default {
  init: function() {
    let self = this;
    self.initAddMoreProducts();
    self.initDeleteFunction();
    self.initMarkProductAsReady();
    self.updateIbanField();
    self.initCategorySelect();
    self.replaceForCompanySalutation();
    self.initRemoveServerError();
    self.initFormSubmit();
    self.slideToFirstErrorIfNeccessary();
  },

  initAddMoreProducts: function() {
    const elements = document.querySelectorAll(".sell-products-wrap.hide");
    elements.forEach(function(element) {
      element.querySelectorAll("select").forEach(function(selectElement) {
        selectElement.setAttribute("data-validate-required", "false");
      });
    });
    let addMoreButton = document.getElementById("addProductForSell");

    addMoreButton.addEventListener("click", function() {
      let firstHidden = getFirstHidden(elements);
      if (firstHidden != null) {
        firstHidden.querySelectorAll("select").forEach(function(selectElement) {
          selectElement.setAttribute("data-validate-required", "true");
        });
        firstHidden.classList.remove("hide");
        if (getFirstHidden(elements) === null) {
          document.getElementById("addMoreMax").classList.remove("hide");
          addMoreButton.style.display = "none";
        }
      }
    });

    function getFirstHidden(list) {
      for (let i = 0; i < list.length; i++) {
        let style = window.getComputedStyle(list[i]);
        if (!(style.display === "block")) {
          return list[i];
        }
      }
      return null; // no hidden elements were found
    }
  },

  initDeleteFunction: function() {
    let childElements = document.querySelectorAll(".icon-trash");
    childElements.forEach(function(childElement) {
      childElement.addEventListener("click", function() {
        let formWrap = this.parentElement.parentElement;
        formWrap.classList.add("hide");
        const selects = this.parentElement.parentElement.querySelectorAll(
          "select"
        );
        selects.forEach(function(select) {
          select.setAttribute("data-validate-required", "false");
          select.selectedIndex = -1;
          select.parentElement.querySelectorAll(
            ".js_selected_name"
          )[0].innerHTML = "";
        });
        closeCategoryDropdown(formWrap);
        document.getElementById("addMoreMax").classList.add("hide");
        document
          .getElementById("addProductForSell")
          .style.removeProperty("display");
      });
    });
  },

  initMarkProductAsReady: function() {
    let sellProductsSelects = document.querySelectorAll(".sell-products-wrap");
    sellProductsSelects.forEach(function(select) {
      select.addEventListener("click", function() {
        let allFieldsFilled = true;
        let allSelectNames = this.querySelectorAll(".js_selected_name");
        allSelectNames.forEach(function(name) {
          if (name.innerHTML === "") {
            allFieldsFilled = false;
          }
        });
        if (allFieldsFilled) {
          select.querySelector(".product-ready").classList.remove("hide");
        } else {
          select.querySelector(".product-ready").classList.add("hide");
        }
      });
    });
  },

  updateIbanField: function() {
    const payoutOptionDropdown = document
      .getElementById("form-select-payout")
      .querySelector("ul");
    const payoutOptionSelect = document.getElementById("payOutOption");

    payoutOptionDropdown.addEventListener("click", function() {
      const ibanWrap = document.getElementById(
        "sub-inputs-bezahlung-lastschrift"
      );
      const ibanInput = document.getElementById("debitModel.iban");
      if (payoutOptionSelect.selectedIndex === 2) {
        ibanWrap.classList.remove("hide");
        ibanInput.setAttribute("data-validate-required", "true");
      } else {
        ibanWrap.classList.add("hide");
        ibanInput.setAttribute("data-validate-required", "false");
      }
    });
  },

  initCategorySelect: function() {
    function addDropdownListeners(button) {
      document.querySelectorAll(button).forEach(function(el) {
        el.addEventListener("click", function() {
          if (this.nextElementSibling.classList.contains("open")) {
            this.classList.remove("open");
            this.nextElementSibling.classList.remove("open");
          } else {
            this.classList.add("open");
            this.nextElementSibling.classList.add("open");
          }
        });
      });
    }

    addDropdownListeners(".dropdown-level-1-button");
    addDropdownListeners(".dropdown-level-2-button");

    document.querySelectorAll(".option").forEach(function(option) {
      option.addEventListener("click", function() {
        let $this = $(this);
        let categoryPath = $this.attr("data-category-value");
        let selectId = $this.attr("data-select-id");
        let selector = document.getElementById(selectId);
        let selectorWrap = selector.parentElement;
        selector.value = categoryPath;
        console.log(categoryPath);
        console.log(selectId);
        selectorWrap.querySelector(
          ".js_selected_name"
        ).innerText = this.innerText;
        $(selectorWrap.querySelector("ul")).slideUp(200);
        selectorWrap.querySelector(".selector").classList.remove("open");
      });
    });
  },

  replaceForCompanySalutation: function() {
    let salutations = document
      .getElementById("salutationHolder")
      .querySelector("ul");
    salutations.addEventListener("click", function() {
      let firstNameInput = document.querySelector(".firstname-switch");
      let lastNameInput = document.querySelector(".lastname-switch");
      if (this.querySelector(".selected").text === "Firma") {
        firstNameInput.innerHTML = "Firma*";
        lastNameInput.innerHTML = "Name*";
      } else {
        firstNameInput.innerHTML = "Vorname*";
        lastNameInput.innerHTML = "Nachname*";
      }
    });
  },

  initFormSubmit: function() {
    let self = this;

    function findFirstError(array) {
      return array
        .first()
        .parent()
        .attr("id");
    }

    $("#submit-secondhand-form").on("click", function() {
      let triggered = false;
      $("#secondhand-form")
        .parsley()
        .on("field:error", function() {
          setTimeout(function() {
            // This global callback will be called for any field that fails validation.
            if (!triggered) {
              let $errorFields = $(".error-txt.filled"),
                firstFormSelectId = findFirstError($errorFields);
              if (
                $errorFields.length > 1 &&
                firstFormSelectId === "form-select-payout"
              ) {
                self.moveArrayElement($errorFields, 0, $errorFields.length - 1);
              }

              firstFormSelectId = findFirstError($errorFields);
              if (
                $errorFields.length > 1 &&
                firstFormSelectId === "form-select-payout-iban"
              ) {
                self.moveArrayElement($errorFields, 0, $errorFields.length - 1);
              }

              firstFormSelectId = findFirstError($errorFields);
              anchor.triggerAnchor(firstFormSelectId);
              triggered = true;
            }
          }, 100);
        });
    });
  },

  slideToFirstErrorIfNeccessary: function() {
    $(document).ready(function() {
      let formSelectId = $(".error-txt")
        .parent()
        .parent()
        .attr("id");
      if (typeof formSelectId !== "undefined") {
        anchor.triggerAnchor(formSelectId);
      }
    });
  },

  initRemoveServerError: function() {
    $(".js_text-switch-item")
      .find(".js_selected_name")
      .on("DOMSubtreeModified", function() {
        $(this)
          .closest(".js_text-switch-item")
          .parent()
          .find(".error-txt")
          .remove();
      });
  },

  moveArrayElement: function(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0]);
  }
};
