const config = {
  // Context-Path for OnsiteEdit Script
  contextPath: $(document)
    .find("#onsiteedit")
    .attr("data-context-path"),

  // JS for Google Recaptcha
  google: {
    recatpcha: {
      script:
        "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit",
      staffDisabled: true
    }
  }
};
export default config;
