function StaffCategory() {}

StaffCategory.prototype = {
  init: function() {
    let _this = this;
    _this.staffShowAllItemsCheck();
    _this.staffInfoEnabledCheck();
  },

  staffShowAllItemsCheck: function() {
    $(".js-on-staff-category-click").click(function(e) {
      $(this)
        .find(".js-checkbox")
        .toggleClass("checked");
      let toggledCheckBoxVal = $(this)
        .find(".js-checkbox")
        .hasClass("checked");
      e.preventDefault();
      let ajaxUrl = $(this).attr("data-url");
      $.post(
        ajaxUrl,
        {
          showAllHistoryItems: toggledCheckBoxVal
        },
        function() {
          location.reload();
        }
      );
    });
  },

  staffInfoEnabledCheck: function() {
    $(".js-on-staff-info-click").click(function(e) {
      $(this)
        .find(".js-checkbox")
        .toggleClass("checked");
      let toggledCheckBoxVal = $(this)
        .find(".js-checkbox")
        .hasClass("checked");
      e.preventDefault();
      let ajaxUrl = $(this).attr("data-url");
      $.post(
        ajaxUrl,
        {
          staffInfoEnabled: toggledCheckBoxVal
        },
        function() {
          location.reload();
        }
      );
    });
  }
};
export default new StaffCategory();
