import "jquery-ui/ui/widgets/datepicker";
import "../modules/validate";

let _this,
  $form = $("#value_selection_form");

let valueSelection = {
  init: function() {
    _this = this;
    _this.eventHanders();
  },

  eventHanders: function() {
    let $option = $form.find(".option-item");
    let $valueSelectionAmount = $form.find(".value_selection_amount");
    let $valueSelectionPreconfiguredValue = $form.find(
      ".value_selection_preconfigured_value"
    );
    $valueSelectionAmount.val("");

    $option.click(function() {
      $valueSelectionAmount.val("");
      $form.parsley().validate();
    });

    $valueSelectionAmount.click(function() {
      $valueSelectionAmount.attr("data-parsley-required", true);
      $("input[name='value']:checked").removeAttr("checked");
      _this.toggleValueError();
    });

    $valueSelectionAmount.on("change keydown focusout", function() {
      _this.styleParsleyError();
    });

    $valueSelectionPreconfiguredValue.click(function() {
      $valueSelectionAmount.attr("data-parsley-required", false);
      $valueSelectionAmount.removeClass("error");
      _this.toggleValueError();
    });

    $form.on("submit", function(e) {
      e.preventDefault();

      $form.parsley().validate();
      if ($form.parsley().isValid()) {
        let selectedValue = _this.getSelectedValue($valueSelectionAmount);
        $.ajax({
          url: $form.attr("action"),
          cache: false,
          method: "POST",
          data: { value: selectedValue }
        })
          .then(function(data) {
            if (data.success) {
              _this.toggleValueError("success-text", data.message);
              $form.hide();
            } else {
              _this.toggleValueError("error-txt", data.message);
            }
            _this.enableButton();
            $valueSelectionAmount.text("");
          })
          .catch(function(error) {
            if (window.console) {
              console.log(error);
            }
          });
      } else {
        _this.enableButton();
        _this.styleParsleyError();
      }
    });
  },

  toggleValueError: function(msgClass, msgText) {
    let $error = $(".js-value-response");
    $error.removeClass("success-text");
    $error.removeClass("error-txt");
    if (typeof msgClass !== "undefined" && typeof msgText !== "undefined") {
      $error.removeClass("hide");
      $error.addClass(msgClass);
      $error.text(msgText);
    } else {
      $error.addClass("hide");
      $error.text("");
    }
  },

  enableButton: function() {
    $(document).ready(function() {
      $form.find(".loading-spinner").hide();
      $form.find("#value_selection_btn").removeAttr("disabled");
    });
  },

  styleParsleyError: function() {
    $form.find(".parsley-errors-list").addClass("error-txt");
  },

  getSelectedValue: function(inputField) {
    let selectedValue = null;
    let inputFieldValue = inputField.val();
    let radioValue = $("input[name='value']:checked").val();

    if (inputFieldValue !== "") {
      let value = parseInt(inputFieldValue);
      let attrMin = parseInt(inputField.attr("min"));
      let attrMax = parseInt(inputField.attr("max"));
      if (
        value >= attrMin &&
        (isNaN(attrMax) || (!isNaN(attrMax) && value <= attrMax))
      ) {
        selectedValue = value;
      }
    } else if (radioValue !== undefined) {
      selectedValue = radioValue;
    }
    return selectedValue;
  }
};
export default valueSelection;
