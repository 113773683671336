import lazyload from "../modules/lazyload";

(function($) {
  var Slider = function(element, settings) {
      var self = this,
        conf = {
          innerWrapClass: "js_slide-inner-wrap",
          slideElementClass: "js_slide-element",
          stopTransitionClass: "stop-transition",
          disabledClass: "is-disabled",
          slidesPerPage: 2,
          slidesPerScroll: 2,
          unveilBackgrounds: false,
          minimumSlides: 0, // disable minimum by setting it to 0
          ttc: false,
          cycle: false,
          fullpage: true,
          shortListPosition: "center", // left, center, right
          disable: false,
          addFakeSlides: 0, // adds more fakes to the beginning and end of the slider
          breakpoints: {
            /* example breakpoint (min-width)
             768: {
             'slidesPerPage': 4,
             'slidesPerScroll': 4,
             'slideElementClass': 'js_slide-element'
             },
             1024: {
             'disable': true
             }
             */
          },
          singlePage: {
            disableCycle: true,
            styleClass: "slider-one-page"
          },
          autoslide: {
            enable: false,
            pauseOnHover: true,
            delay: 5000
          },
          controls: {
            enable: true,
            generate: true,
            outer: false,
            prev: "js_prev",
            next: "js_next",
            content: {
              prev: "<",
              next: ">"
            },
            styleClasses: {
              inactive: "inactive",
              wrap: "slide-control-wrap",
              prev: "slide-control slide-control-prev",
              next: "slide-control slide-control-next"
            }
          },
          pageIndicator: {
            enable: true,
            outer: false,
            generate: true,
            type: "dot", // dot, line, text
            wrap: "js_indicator-wrap",
            item: "js_indicator-item",
            styleClasses: {
              wrap: "slide-indicator-wrap",
              item: "slide-indicator-item",
              current: "slide-indicator-current"
            },
            text: {
              content: "{CUR} / {MAX}"
            }
          },
          isClickAndCollect: false
        },
        skipNextAutoSlide = false,
        isAnimating = false,
        currentSlideElementClass = "",
        currentPerPage = 0,
        currentPerSlide = 0,
        currentStartSlide = 0,
        currentDisabledState = false,
        currentMinimumSlides = 0,
        windowWidth = 0,
        wrapper = null,
        innerWrap = null,
        slideElements = [],
        btnNext = null,
        btnPrev = null,
        indicatorWrap = null,
        indicatorElements = [],
        indicatorMaxPages = 0,
        isReset = true,
        paused = false,
        disableSlider = false,
        pageIndexes = [],
        slideIndexes = [],
        currentSlideIndex = 0,
        lastSlideIndex = 0;

      /**
       * @protected
       *
       * @returns {Slider}
       */
      function __construct() {
        wrapper = $(element);

        if (wrapper.data().SnsSlider) {
          return wrapper.data().SnsSlider;
        }

        conf = $.extend(true, conf, settings);

        if (conf.slidesPerScroll <= 0) {
          conf.slidesPerScroll = 1;
        }

        updateWindowWidth();
        updateSlideSettings();
        bindEvents();

        if (conf.controls.enable) {
          if (conf.controls.generate) {
            generateControls();
          } else {
            getControls();
          }
          initControlEvents();
        }

        if (conf.pageIndicator.enable) {
          initIndicators();
        }

        init();

        initAutoSlide();

        wrapper.data("SnsSlider", self);

        return self;
      }

      /**
       * @protected
       */
      function init() {
        findElements();
        buildSlidePages();

        if (
          currentMinimumSlides !== 0 &&
          slideElements.length < currentMinimumSlides
        ) {
          currentDisabledState = true;
        }

        if (currentDisabledState === true) {
          wrapper.addClass(conf.disabledClass);

          Helper.removeInlineStyle(innerWrap, ["width", "left"]);

          return;
        } else {
          wrapper.removeClass(conf.disabledClass);
        }

        if (wrapper.hasClass(conf.singlePage.styleClass)) {
          disableSlider = false;
          wrapper.removeClass(conf.singlePage.styleClass);
        }

        if (
          slideIndexes.length < 2 &&
          (!conf.cycle || (conf.cycle && conf.singlePage.disableCycle))
        ) {
          wrapper.addClass(conf.singlePage.styleClass);
          disableSlider = true;
        } else {
          if (conf.cycle) {
            currentStartSlide = 1;
            addCycleClones();
          }
        }

        if (conf.pageIndicator.enable && slideIndexes.length > 1) {
          generateIndicatorItems();
        }

        setSizes();
        self.slideTo(currentStartSlide, true, true);
        setControlsActiveStates();

        if (conf.ttc) {
          $(".js_stage-canvas").SnsTextToCanvas();
        }
      }

      /**
       * @protected
       */
      function initIndicators() {
        if (conf.pageIndicator.generate) {
          indicatorWrap = generateIndicatorWrap();
        } else {
          var indicatorParentWrap = wrapper;

          if (conf.pageIndicator.outer) {
            indicatorParentWrap = indicatorParentWrap.parent();
          }

          indicatorWrap = indicatorParentWrap.find(
            "." + conf.pageIndicator.wrap
          );
        }
      }

      /**
       * @protected
       */
      function generateIndicatorItems() {
        indicatorWrap.html("");
        indicatorElements = [];
        indicatorMaxPages = 0;

        for (var i = slideIndexes.length; i--; ) {
          if (
            slideElements[slideIndexes[i]] &&
            slideElements[slideIndexes[i]].isClone()
          ) {
            continue;
          }

          if (conf.pageIndicator.type == "text") {
            indicatorMaxPages++;
          } else {
            var indicatorItem = $("<div>", {
              class: conf.pageIndicator.styleClasses.item
            });

            indicatorItem.on(
              "click",
              {
                indicator: indicatorItem
              },
              addIndicatorEvent
            );

            indicatorElements.push(indicatorItem);
            indicatorItem.appendTo(indicatorWrap);
          }
        }

        if (conf.pageIndicator.type == "line") {
          setIndicatorsWidth();
        }
      }

      /**
       *
       * @param {Object} ev
       */
      function addIndicatorEvent(ev) {
        for (var i = slideElements.length; i--; ) {
          if (conf.unveilBackgrounds) {
            slideElements[i].unveilBackgrounds();
          }
        }
        self.slideTo(ev.data.indicator.index() + 1, false, false);
      }

      /**
       * @protected
       */
      function setIndicatorsWidth() {
        var width = 100 / indicatorElements.length + "%";
        for (var i = indicatorElements.length; i--; ) {
          $(indicatorElements[i]).width(width);
        }
      }

      /**
       * @protected
       */
      function setIndicatorItemActive() {
        if (conf.pageIndicator.enable === false) {
          return;
        }

        var currentIndex = currentSlideIndex;
        if (conf.cycle) {
          if (currentIndex === 0) {
            currentIndex = slideIndexes.length - 1;
          }

          currentIndex--;
        }

        if (conf.pageIndicator.type == "text") {
          var indicatorText = conf.pageIndicator.text.content;
          indicatorText = indicatorText
            .replace("{MAX}", indicatorMaxPages)
            .replace("{CUR}", currentIndex + 1);
          indicatorWrap.text(indicatorText);
        } else {
          for (var i = indicatorElements.length; i--; ) {
            $(indicatorElements[i]).removeClass(
              conf.pageIndicator.styleClasses.current
            );
          }

          if (indicatorElements.length > 1) {
            indicatorElements[currentIndex].addClass(
              conf.pageIndicator.styleClasses.current
            );
          }
        }
      }

      /**
       * @protected
       *
       * @returns {Object}
       */
      function generateIndicatorWrap() {
        var indicatorWrap = $("<div>", {
          class: buildClassString([
            conf.pageIndicator.wrap,
            conf.pageIndicator.styleClasses.wrap
          ])
        });

        if (conf.pageIndicator.outer) {
          indicatorWrap.insertAfter(wrapper);
        } else {
          indicatorWrap.appendTo(wrapper);
        }

        return indicatorWrap;
      }

      /**
       * @protected
       */
      function buildSlidePages() {
        slideIndexes = [];

        var slidesCount = slideElements.length,
          currentlyForPage = 0,
          currentlyForSlide = 0;

        for (var i = 0; i < slidesCount; i++) {
          if (currentlyForSlide === 0) {
            slideIndexes.push(i);
          }
          if (currentlyForPage === 0) {
            pageIndexes.push(i);
          }
          currentlyForSlide++;
          currentlyForPage++;

          if (currentlyForSlide === currentPerSlide) {
            currentlyForSlide = 0;
          }

          if (currentlyForPage === currentPerPage) {
            currentlyForPage = 0;
          }
        }

        cleanSlidePages();
      }

      /**
       * @protected
       */
      function cleanSlidePages() {
        if (conf.fullpage) {
          var maxIndex = slideElements.length - currentPerPage;
          for (var x = slideIndexes.length; x--; ) {
            if (slideIndexes[x] >= maxIndex) {
              slideIndexes.splice(x, 1);
            }
          }

          if (maxIndex < 0) {
            maxIndex = 0;
          }

          slideIndexes.push(maxIndex);
        }
      }

      /**
       * @protected
       */
      function initAutoSlide() {
        if (conf.autoslide.enable) {
          window.setInterval(autoNext, conf.autoslide.delay);
        }
      }

      /**
       * @protected
       */
      function autoNext() {
        if (!paused) {
          if (skipNextAutoSlide) {
            skipNextAutoSlide = false;
            return;
          }
          self.next();
        }
      }

      /**
       * @protected
       */
      function addCycleClones() {
        var lastPageSlides = getPageSlides(slideIndexes.length - 1),
          fakesCount = conf.addFakeSlides + 1;

        while (fakesCount > 1) {
          lastPageSlides = getPageSlides(
            slideIndexes.length - fakesCount
          ).concat(lastPageSlides);
          fakesCount--;
        }

        if (lastPageSlides.length < currentPerPage || conf.addFakeSlides > 0) {
          var missingSlidesCount = currentPerPage - lastPageSlides.length;

          if (missingSlidesCount > 0) {
            cloneSlides(missingSlidesCount);
            buildSlidePages();

            while (
              getPageSlides(slideIndexes.length - 1).length < currentPerPage &&
              slideIndexes.length > 0
            ) {
              slideIndexes.pop();
            }
          }

          cloneSlides(conf.addFakeSlides, Math.max(missingSlidesCount, 0));
        }

        for (var s = slideIndexes.length; s--; ) {
          slideIndexes[s] += lastPageSlides.length;
        }

        for (var i = lastPageSlides.length; i--; ) {
          var clonedSlide = lastPageSlides[i].clone();
          clonedSlide.getHtml().prependTo(innerWrap);
          slideElements.unshift(clonedSlide);
        }

        slideIndexes.unshift(conf.addFakeSlides);
      }

      /**
       * @protected
       *
       * @param {number} count
       * @param {number} [start=0]
       */
      function cloneSlides(count, start) {
        if (typeof start === "undefined") {
          start = 0;
        }
        var currentIndex = start,
          currentSlideElement = null;

        while (count) {
          currentSlideElement = slideElements[currentIndex].clone();
          currentSlideElement.getHtml().appendTo(innerWrap);
          slideElements.push(currentSlideElement);
          count--;
          currentIndex++;
        }
      }

      /**
       * @protected
       *
       * @param {number} pageIndex
       * @returns {Array}
       */
      function getPageSlides(pageIndex) {
        var i = slideIndexes[pageIndex],
          max = i + currentPerPage,
          foundSlides = [];

        while (i < slideElements.length && i < max) {
          foundSlides.push(slideElements[i]);
          i++;
        }

        return foundSlides;
      }

      /**
       * @protected
       */
      function bindEvents() {
        $(window).resize(function() {
          updateWindowWidth();

          if (updateSlideSettings()) {
            isReset = true;
            reset();
            init();
          }
        });

        swipeDetect(wrapper[0], function(ev, dir) {
          if (dir === "left") {
            ev.preventDefault();
            skipNextAutoSlide = true;
            self.next();
          } else if (dir === "right") {
            ev.preventDefault();
            skipNextAutoSlide = true;
            self.prev();
          }
        });

        if (conf.autoslide.enable && conf.autoslide.pauseOnHover) {
          wrapper
            .on("mouseenter", function() {
              paused = true;
            })
            .on("mouseleave", function() {
              paused = false;
            });
        }
      }

      /**
       * @protected
       *
       * @param {object} touchsurface
       * @param {function} callback
       */
      function swipeDetect(touchsurface, callback) {
        var swipeDir,
          startX,
          startY,
          distX,
          distY,
          threshold = 75, //required min distance traveled to be considered swipe
          restraint = 100, // maximum distance allowed at the same time in perpendicular direction
          allowedTime = 300, // maximum time allowed to travel that distance
          elapsedTime,
          startTime,
          handleSwipe = callback || function(swipedir) {};

        touchsurface.addEventListener(
          "touchstart",
          function(e) {
            var touchObj = e.changedTouches[0];
            swipeDir = "none";
            startX = touchObj.pageX;
            startY = touchObj.pageY;
            startTime = new Date().getTime(); // record time when finger first makes contact with surface
            //e.preventDefault();
          },
          false
        );

        touchsurface.addEventListener(
          "touchend",
          function(e) {
            var touchObj = e.changedTouches[0];
            distX = touchObj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
            distY = touchObj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime; // get time elapsed

            if (elapsedTime <= allowedTime) {
              // first condition for swipe met
              if (
                Math.abs(distX) >= threshold &&
                Math.abs(distY) <= restraint
              ) {
                // 2nd condition for horizontal swipe met
                swipeDir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
              } else if (
                Math.abs(distY) >= threshold &&
                Math.abs(distX) <= restraint
              ) {
                // 2nd condition for vertical swipe met
                swipeDir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
              }
            }

            handleSwipe(e, swipeDir);
          },
          false
        );
      }

      /**
       * @protected
       */
      function updateWindowWidth() {
        windowWidth = $(window).width();
      }

      /**
       * @protected
       */
      function updateSlideSettings() {
        var lastCheckedSize = 0,
          hasSetCurrentPerPage = false,
          hasSetCurrentPerSlide = false,
          hasSetSlideElementClass = false,
          hasSetDisabledState = false,
          hasSetMinimumSlides = false,
          oldPerPage = currentPerPage,
          oldPerSlide = currentPerSlide,
          oldSlideElementClass = currentSlideElementClass,
          oldDisabledState = currentDisabledState,
          oldMinimumSlides = currentMinimumSlides;

        for (var key in conf.breakpoints) {
          if (conf.breakpoints.hasOwnProperty(key)) {
            key = parseInt(key, 10);

            if (key <= windowWidth && key >= lastCheckedSize) {
              if (conf.breakpoints[key].slidesPerPage) {
                currentPerPage = conf.breakpoints[key].slidesPerPage;
                hasSetCurrentPerPage = true;
              }
              if (conf.breakpoints[key].slidesPerScroll) {
                currentPerSlide = conf.breakpoints[key].slidesPerScroll;
                hasSetCurrentPerSlide = true;
              }
              if (conf.breakpoints[key].slideElementClass) {
                currentSlideElementClass =
                  conf.breakpoints[key].slideElementClass;
                hasSetSlideElementClass = true;
              }
              if (typeof conf.breakpoints[key].disable !== "undefined") {
                currentDisabledState = conf.breakpoints[key].disable;
                hasSetDisabledState = true;
              }
              if (typeof conf.breakpoints[key].minimumSlides !== "undefined") {
                currentMinimumSlides = conf.breakpoints[key].minimumSlides;
                hasSetMinimumSlides = true;
              }
              lastCheckedSize = key;
            }
          }
        }

        if (!hasSetCurrentPerPage) {
          currentPerPage = conf.slidesPerPage;
        }

        if (!hasSetCurrentPerSlide) {
          currentPerSlide = conf.slidesPerScroll;
        }

        if (!hasSetSlideElementClass) {
          currentSlideElementClass = conf.slideElementClass;
        }

        if (!hasSetDisabledState) {
          currentDisabledState = conf.disable;
        }

        if (!hasSetMinimumSlides) {
          currentMinimumSlides = conf.minimumSlides;
        }

        if (currentPerPage < currentPerSlide) {
          currentPerSlide = currentPerPage;
        }

        return (
          oldPerPage !== currentPerPage ||
          oldPerSlide !== currentPerSlide ||
          oldSlideElementClass !== currentSlideElementClass ||
          oldDisabledState !== currentDisabledState ||
          oldMinimumSlides !== currentMinimumSlides
        );
      }

      /**
       * @protected
       */
      function generateControls() {
        var controlWrap = $("<div>", {
            class: conf.controls.styleClasses.wrap
          }),
          controlNext = $("<span>", {
            class: buildClassString([
              conf.controls.styleClasses.next,
              conf.controls.next
            ]),
            text: conf.controls.content.next
          }),
          controlPrev = $("<span>", {
            class: buildClassString([
              conf.controls.styleClasses.prev,
              conf.controls.prev
            ]),
            text: conf.controls.content.prev
          });

        btnNext = controlNext;
        btnPrev = controlPrev;

        controlWrap.append(controlPrev).append(controlNext);

        if (conf.controls.outer) {
          controlWrap.insertAfter(wrapper);
        } else {
          controlWrap.appendTo(wrapper);
        }
      }

      /**
       * @protected
       */
      function getControls() {
        var parentWrap = wrapper;

        if (conf.controls.outer) {
          parentWrap = wrapper.parent();
        }
        btnNext = parentWrap.find("." + conf.controls.next);
        btnPrev = parentWrap.find("." + conf.controls.prev);
      }

      /**
       * @protected
       */
      function initControlEvents() {
        btnNext.on("click", function(ev) {
          ev.preventDefault();
          skipNextAutoSlide = true;
          self.next();
        });
        btnPrev.on("click", function(ev) {
          ev.preventDefault();
          skipNextAutoSlide = true;
          self.prev();
        });
      }

      /**
       * @protected
       *
       * @param {Array} classes
       * @returns {string}
       */
      function buildClassString(classes) {
        return classes.join(" ");
      }

      /**
       * @protected
       */
      function findElements() {
        innerWrap = wrapper.children("." + conf.innerWrapClass);

        var tmpSlideElements = findSlideElements(innerWrap, 0);
        for (var i = tmpSlideElements.length; i--; ) {
          slideElements.unshift(new SlideElement(tmpSlideElements[i]));
        }
      }

      /**
       *
       * @param {Object} parent
       * @param {number} depth
       * @returns {Object}
       */
      function findSlideElements(parent, depth) {
        if (typeof depth === "undefined") {
          depth = 0;
        }

        var children = parent.children("." + currentSlideElementClass);
        if (children.length === 0 && depth < 5) {
          return findSlideElements(parent.children(), depth + 1);
        }

        return children;
      }

      /**
       * @protected
       */
      function setSizes() {
        var slidesCount = slideElements.length,
          possibleSlidesCount = slidesCount;

        innerWrap.width(Math.ceil(slidesCount / currentPerPage) * 100 + "%");

        if (possibleSlidesCount % currentPerPage) {
          possibleSlidesCount =
            currentPerPage * Math.ceil(possibleSlidesCount / currentPerPage);
        }

        for (var i = slidesCount; i--; ) {
          slideElements[i].setWidth(100 / possibleSlidesCount);
        }

        lastSlideIndex = slideIndexes.length - 1;
      }

      /**
       * @protected
       *
       * @param {number} index
       * @returns {number}
       */
      function getSlidePosition(index) {
        if (index === 0 && slideElements.length < currentPerPage) {
          switch (conf.shortListPosition) {
            case "left":
              return 0;
            case "right":
              return (
                (currentPerPage - slideElements.length) * (100 / currentPerPage)
              );
            default:
              // default == 'center'
              return (
                ((currentPerPage - slideElements.length) *
                  (100 / currentPerPage)) /
                2
              );
          }
        }

        return -(slideIndexes[index] * (100 / currentPerPage));
      }

      /**
       * @protected
       */
      function setControlsActiveStates() {
        btnPrev.removeClass(conf.controls.styleClasses.inactive);
        btnNext.removeClass(conf.controls.styleClasses.inactive);

        if ((currentSlideIndex <= 0 && !conf.cycle) || disableSlider) {
          btnPrev.addClass(conf.controls.styleClasses.inactive);
        }
        if (
          (currentSlideIndex >= lastSlideIndex && !conf.cycle) ||
          disableSlider
        ) {
          btnNext.addClass(conf.controls.styleClasses.inactive);
        }
        if (
          conf.isClickAndCollect &&
          (currentPerPage > 5 && slideElements.length < 8)
        ) {
          btnNext.addClass(conf.controls.styleClasses.inactive);
          btnNext.off("click");
        } else {
          initControlEvents();
        }
      }

      /**
       * @protected
       */
      function reset() {
        if (conf.cycle) {
          removeClones();
        }
        for (var i = slideElements.length; i--; ) {
          slideElements[i].reset();
        }
        currentStartSlide = 0;
        slideElements = [];
        slideIndexes = [];
      }

      /**
       * @protected
       */
      function removeClones() {
        for (var i = slideElements.length; i--; ) {
          if (slideElements[i].isClone()) {
            slideElements[i].remove();
            slideElements.splice(i, 1);
          }
        }
      }

      /**
       * @protected
       */
      function startSliderAnimation() {
        isAnimating = true;

        innerWrap.one(
          "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          function() {
            isAnimating = false;
          }
        );
      }

      /**
       * @protected
       *
       * @param {number} index
       * @param {boolean} withoutAnimation
       */
      function moveSlider(index, withoutAnimation) {
        if (conf.cycle && index < 0) {
          self.slideTo(lastSlideIndex, true);

          window.setTimeout(function() {
            self.slideTo(lastSlideIndex - 1, true);
          }, 15);
          return;
        } else if (conf.cycle && index > lastSlideIndex) {
          self.slideTo(0, true);

          window.setTimeout(function() {
            self.slideTo(1, true);
          }, 15);
          return;
        }

        index = Math.max(Math.min(index, lastSlideIndex), 0);

        if (!isReset && currentSlideIndex === index) {
          return;
        }
        isReset = false;

        if (!withoutAnimation) {
          startSliderAnimation();
        }

        innerWrap.css("left", getSlidePosition(index) + "%");
        currentSlideIndex = index;

        if (withoutAnimation) {
          window.setTimeout(function() {
            innerWrap.removeClass(conf.stopTransitionClass);
          }, 10);
        }

        setSlidesCurrentState();
        setControlsActiveStates();
        setIndicatorItemActive();
      }

      /**
       * @protected
       *
       * @param {number} index
       * @param {boolean} withoutAnimation
       */
      this.slideTo = function(index, withoutAnimation, noTimeout) {
        if (isAnimating) {
          return;
        }

        if (withoutAnimation) {
          innerWrap.addClass(conf.stopTransitionClass);
        }

        if (noTimeout) {
          moveSlider(index, withoutAnimation);
        } else {
          window.setTimeout(function() {
            moveSlider(index, withoutAnimation);
          }, 5);
        }
      };

      /**
       * @protected
       */
      function setSlidesCurrentState() {
        for (var i = slideElements.length; i--; ) {
          if (
            i >= slideIndexes[currentSlideIndex] &&
            i < slideIndexes[currentSlideIndex] + currentPerPage
          ) {
            slideElements[i].setIsCurrent();
            slideElements[i].lazyLoad();

            if (conf.unveilBackgrounds) {
              slideElements[i].unveilBackgrounds();
              var nextSlide = slideElements[i + 1];

              if (typeof nextSlide !== "undefined") {
                nextSlide.unveilBackgrounds();
              }
            }
          } else {
            slideElements[i].setIsNotCurrent();
          }
        }
      }

      /**
       * @public
       */
      this.next = function() {
        self.slideTo(currentSlideIndex + 1, false);
      };

      /**
       * @public
       */
      this.prev = function() {
        self.slideTo(currentSlideIndex - 1, false);
      };

      return __construct();
    },
    SlideElement = function(element, clone) {
      var self = this,
        conf = {
          currentClass: "current-slide"
        },
        isCloned = false,
        isCurrent = false,
        slideElement = null;

      /**
       * @protected
       *
       * @returns {SlideElement}
       */
      function __construct() {
        slideElement = $(element);

        if (clone) {
          isCloned = true;
        }

        self.content = slideElement.text();

        return self;
      }

      /**
       * @public
       *
       * @return {SlideElement}
       */
      this.clone = function() {
        return new SlideElement(slideElement.clone(), true);
      };

      this.lazyLoad = function() {
        var imageElement = slideElement.find(".js-unveil-list");
        if (imageElement.length) {
          lazyload.init({
            selector: imageElement
          });
        }
      };

      this.unveilBackgrounds = function() {
        var imageElement = slideElement.find(".simple-stage");
        var styleAttr = imageElement.attr("data-background");
        imageElement.attr("style", styleAttr);
      };

      /**
       * @public
       */
      this.setIsCurrent = function() {
        if (!isCurrent) {
          isCurrent = true;
          slideElement.addClass(conf.currentClass);
        }
      };

      /**
       * @public
       */
      this.setIsNotCurrent = function() {
        isCurrent = false;
        slideElement.removeClass(conf.currentClass);
      };

      /**
       * @public
       *
       * @returns {Object}
       */
      this.getHtml = function() {
        return slideElement;
      };

      /**
       * @public
       *
       * @param {boolean} hard
       */
      this.reset = function(hard) {
        if (typeof hard == "undefined") {
          Helper.removeInlineStyle(slideElement, ["width", "float"]);
        } else if (hard) {
          slideElement.removeAttr("style");
        }
      };

      /**
       * @public
       *
       * @returns {boolean}
       */
      this.isClone = function() {
        return isCloned;
      };

      /**
       * @public
       */
      this.remove = function() {
        slideElement.remove();
      };

      /**
       * @public
       *
       * @param {number} width
       */
      this.setWidth = function(width) {
        slideElement.css("float", "left");
        slideElement.css("width", width + "%");
      };

      return __construct();
    },
    Helper = {
      /**
       *
       * @param {Object} element
       * @param {Array} styleNames
       */
      removeInlineStyle: function(element, styleNames) {
        for (var i = styleNames.length; i--; ) {
          styleNames[i] = "(" + styleNames[i] + ")";
        }

        var currentStyle = element.attr("style"),
          singleStyles =
            typeof currentStyle === "undefined" ? [] : currentStyle.split(";"),
          newStyles = [],
          newStyleAttr;

        for (var x = singleStyles.length; x--; ) {
          currentStyle = $.trim(singleStyles[x]);

          if (
            !new RegExp("^" + styleNames.join("|") + ":").test(currentStyle) &&
            currentStyle !== ""
          ) {
            newStyles.push(currentStyle);
          }
        }

        newStyleAttr = newStyles.join(";");

        if (newStyleAttr === "") {
          element.removeAttr("style");
        }

        element.attr("style", newStyleAttr);
      }
    };

  $.fn.extend({
    SnsSlider: function(conf) {
      return $(this).each(function() {
        new Slider($(this), conf);
      });
    }
  });
})(jQuery);
