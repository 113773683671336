import lockscroll from "../utils/lock-scroll";

let navigationDrawer = {
  init: () => {
    navigationDrawer.events();
  },
  events: () => {
    document.querySelectorAll(".js-toggle-drawer").forEach(item => {
      item.addEventListener("click", event => {
        document.documentElement.classList.toggle("drawer-open");
        if (document.documentElement.classList.contains("drawer-open")) {
          lockscroll.enable();
        } else {
          lockscroll.disable();
        }
      });
    });
  }
};

export default navigationDrawer;
