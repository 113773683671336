import global from "../global";

export default {
  init: function() {
    var accountOrderHistoryFilterFormId = "#account-order-history-filter-form";
    var accountOrderHistoryDetailsContainerId =
      "#account-order-history-details";

    function errorHandler(e) {
      if (window.console) window.console.log(e);
      global.isProcessing(false);
    }

    function ajaxResponseHandler(data) {
      $(accountOrderHistoryDetailsContainerId).replaceWith(data);
      $(".js_accordion-table").SnsAccordion({
        multipleBody: true
      });
      global.isProcessing(false);
    }

    $(document).on("click", accountOrderHistoryFilterFormId + " a", function(
      e
    ) {
      var accountOrderHistoryFilterForm = $(accountOrderHistoryFilterFormId);
      $.ajax({
        cache: false,
        type: "post",
        data: accountOrderHistoryFilterForm.serialize(),
        dataType: "html",
        beforeSend: function() {
          global.isProcessing(true);
        },
        success: ajaxResponseHandler,
        error: errorHandler,
        url: accountOrderHistoryFilterForm.attr("action")
      });
    });
  }
};
