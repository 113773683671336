(function($) {
  var SnsAccordion = function(wrap, userConfig) {
    var self = this,
      conf = {
        multipleBody: false
      },
      wrapper = null,
      options = [];

    function __construct() {
      conf = $.extend(true, conf, userConfig);
      wrapper = $(wrap);

      findOptions();

      return self;
    }

    function findOptions() {
      var heads = wrapper.find(".js_accordion-head"),
        finalIndex = 0;

      for (var i = heads.length; i--; ) {
        var currentData = {};
        currentData.head = $(heads[i]);
        currentData.body = currentData.head.next(".js_accordion-body");

        if (conf.multipleBody) {
          currentData.body = $(
            '.js_accord-body[data-order-number="' +
              currentData.head.data().orderSource +
              '"]'
          );
        }

        currentData.isActive = currentData.head.hasClass("active");

        if (currentData.body.length === 0) {
          continue;
        }

        currentData.head.on(
          "click",
          {
            index: finalIndex
          },
          toggleContent
        );

        if (currentData.isActive === true) {
          currentData.body.addClass("active");
          if (conf.multipleBody) {
            currentData.head.addClass("active");
          }
        } else {
          currentData.body.removeClass("active");
          if (conf.multipleBody) {
            currentData.head.removeClass("active");
          }
        }

        options.push(currentData);
        finalIndex++;
      }
    }

    function toggleContent(ev) {
      var index = ev.data.index;

      if (!options[index]) {
        return;
      }

      if (options[index].isActive) {
        options[index].body.removeClass("active");
        options[index].head.removeClass("active");
        options[index].isActive = false;
      } else {
        options[index].body.addClass("active");
        options[index].head.addClass("active");
        options[index].isActive = true;
      }
    }

    return __construct();
  };

  $.fn.extend({
    SnsAccordion: function(userConfig) {
      return $(this).each(function() {
        new SnsAccordion($(this), userConfig);
      });
    }
  });
})(jQuery);
