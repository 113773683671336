import global from "../global";

var posti = {};

$(document).on("click", "#radio-lieferadresse-rechnungsadresse-0", function(
  event
) {
  $(
    "input[name='shippingAddressViewModel.postiShippingModel.officeSearch']"
  ).val(
    $("input[name='billingAddressViewModel.invoiceAddress.zipCode']").val()
  );
});

$(document).on("click", "#radio-lieferadresse-rechnungsadresse-1", function(
  event
) {
  $(
    "input[name='shippingAddressViewModel.postiShippingModel.smartPostSearch']"
  ).val(
    $("input[name='billingAddressViewModel.invoiceAddress.zipCode']").val()
  );
});

$(document).on("click", "#posti-smartpost", function(event) {
  var postiSearchValue = $("#posti-smartpost-search").val();
  var url = $(this).attr("data-binding-url");
  $.ajax({
    url: url,
    method: "POST",
    headers: global.headers,
    data: {
      postiQuery: postiSearchValue
    },
    timeout: 3000,
    success: function(responseData) {
      var marker = "#posti-smartpost-select-marker";
      $(marker).html(responseData);
      $(marker).show();
    },
    error: function(e) {
      console.log(e.message);
      return false;
    }
  });

  return false;
});

$(document).on("click", "#posti-postioffice", function(event) {
  var postiSearchValue = $("#posti-office-search").val();
  var url = $(this).attr("data-binding-url");
  $.ajax({
    url: url,
    method: "POST",
    headers: global.headers,
    data: {
      postiQuery: postiSearchValue
    },
    timeout: 3000,
    success: function(responseData) {
      var marker = "#posti-office-select-marker";
      $(marker).html(responseData);
      $(marker).show();
    },
    error: function(e) {
      console.log(e.message);
      return false;
    }
  });

  return false;
});

export default posti;
