/*globals define, document, window, $*/

import global from "../global";
import "../modules/checkbox";
import "../utils/selector";

var self;
export default {
  origFormButton: null,
  init: function() {
    self = this;
    $(document).on("click", ".js_loyalty_card_order_button", function(e) {
      e.preventDefault();
      self.submitForm($(this).data("target"));
    });
  },
  initDesignFormElements: function(targetId) {
    $(
      "#" + targetId + ".js_loyalty_card_order .js-design-checkbox"
    ).SnsDesignCheckbox();
    $("#" + targetId + ".js_loyalty_card_order .selector").SnsSelector();
  },
  submitForm: function(targetId) {
    var origWrapper = $("#" + targetId + ".js_loyalty_card_order"),
      origForm = origWrapper.find("form");
    $.ajax({
      url: origForm.attr("action"),
      method: "POST",
      headers: global.headers,
      data: origForm.serialize(),
      dataType: "html",
      success: function(data) {
        // replace form
        origWrapper.replaceWith(data);
        self.initDesignFormElements(targetId);
      },
      error: function(e) {
        if (window.console) {
          window.console.log(e);
        }
      }
    });
  }
};
