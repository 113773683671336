import "../plugin/customer_search";

export default {
  init: function() {
    var subject = "checkout";
    var iCustomerSearchFormId = "#" + subject + "-icustomer-search-form";
    var iCustomerSelectionFormId = "#" + subject + "-icustomer-selection-form";

    function errorHandler(e) {
      if (window.console) window.console.log(e);
    }

    $(document).CustomerSearch({
      subject: subject,
      searchFormId: iCustomerSearchFormId,
      selectionFormId: iCustomerSelectionFormId
    });
  }
};
