import hi_base64 from "../lib/base64.min";
import global from "../global";
import "../plugin/lightcase";
import "parsleyjs/dist/parsley";
import "../plugin/request_spinner";

var self;
var tmpreturnOrderResponse;
var success;

export default {
  init: function() {
    self = this;
    $(document).on("click", ".js_accordion-head", function() {
      $(".js-retour-error").addClass("hide");
    });
    //action on retour overview page
    $(document).on("click", ".js-retoure-btn", function(e) {
      global.isProcessing(true);
      e.preventDefault();
      if ($(this).attr("data-retour") !== "true") {
        $(".js-retour-error").removeClass("hide");
        $("html, body").animate(
          {
            scrollTop: $("#page-wrapper").offset().top
          },
          300
        );
      } else {
        $(this)
          .closest("form")
          .submit();
      }
    });

    //action on return details page
    $(document).on(
      "click",
      "a.js-retoure-print-submit-previous-btn, a.js-invoice-print-submit-btn",
      function() {
        self._printAction($(this));
      }
    );

    $(document).on("click", ".js-print-return-label", function() {
      if (tmpreturnOrderResponse) {
        self._printAction($(this), tmpreturnOrderResponse);
      }
    });

    let returnButton = $(".js-spinner");
    returnButton.requestSpinner({
      elementType: "ajax"
    });

    $(".js-retoure-print-submit-btn").on("click", function(e) {
      e.preventDefault();
      let $form = $("#retoure-form");
      let saveActionUrl = $form.attr("action");

      if ($form.parsley().validate()) {
        self._removeError();
        $.ajax({
          url: saveActionUrl,
          method: "POST",
          data: $form.serialize(),
          beforeSend: function() {
            returnButton.data().requestSpinner.setOn();
          },
          success: function(data) {
            returnButton.data().requestSpinner.setOff();
            $(".js-retoure-print-submit-btn").prop("disabled", true);
            tmpreturnOrderResponse = data.returnOrderResponse;
            self._openLightCase("#label-success-modal");
          },
          error: function() {
            returnButton.data().requestSpinner.setOff();
            self._openLightCase("#label-error-modal");
            return false;
          }
        });
      } else {
        self._showError();
      }
    });

    $(document).on("click", ".js-resend-return-label-btn", function(e) {
      e.preventDefault();
      let actionUrl = hi_base64.decode($(this).attr("data-href"));
      $.ajax({
        url: actionUrl,
        method: "GET",
        success: function(data) {
          success = data.success;
          if (success) {
            self._openLightCase("#success-resend-label-modal");
          } else {
            self._openLightCase("#label-error-resend-modal");
          }
          return success;
        },
        error: function(xhr, status, err) {
          self._openLightCase("#label-error-resend-modal");
          return false;
        }
      });
    });

    //action on qrcode creating
    $(document).on("click", ".js-retoure-qrcode-submit-btn", function(e) {
      e.preventDefault();
      let $form = $("#retoure-form");
      let qrCodeActionUrl = $(this).attr("data-action");
      $.ajax({
        url: qrCodeActionUrl,
        method: "POST",
        data: $form.serialize(),
        success: function(data) {
          self._qrCodeAction(data);
        },
        error: function() {
          self._qrCodeAction();
        }
      });
    });

    //If page contains 'download-returnlabel-error' element, open Error-Pop-Up on page load
    if (
      document.body.contains(
        document.getElementById("download-returnlabel-error")
      )
    ) {
      self._openLightCase("#download-returnlabel-error");
    }

    self._validateForm();
    let positionItems = $(".js-detail-item");
    positionItems.each(function() {
      self._addSelectionEventHandler($(this));
    });
  },

  _addSelectionEventHandler: function(positionItem) {
    let textArea = positionItem.find(".mandatory-text-area");
    positionItem.find(".js-retoure-reason").on("change", function() {
      let valueFlag = false;
      let selectedValue = $(this).find("option:selected");
      let lastOptgroup = $(this).find("optgroup:last");
      lastOptgroup.children().each(function() {
        if ($(this).val() === selectedValue.val()) {
          valueFlag = true;
        }
      });

      if (valueFlag) {
        textArea.removeClass("hide");
      } else {
        textArea.addClass("hide");
      }
      self._toggleRetoureButton();
    });

    positionItem.find(".js-retoure-amount").on("change", function() {
      self._toggleRetoureButton();
    });
  },

  _toggleRetoureButton: function() {
    let hasSelectedPosition = false;
    $(".js-detail-item").each(function() {
      let retoureReasonValue = $(this)
        .find(".js-retoure-reason")
        .val();
      let retoureAmountValue = $(this)
        .find(".js-retoure-amount")
        .val();
      if (retoureReasonValue !== "0" && retoureAmountValue !== "0") {
        hasSelectedPosition = true;
        return false;
      }
    });

    let $retoureSubmit = $(".js-retoure-submit");
    if (hasSelectedPosition) {
      $retoureSubmit.show();
    } else {
      $retoureSubmit.hide();
    }
  },

  _removeError: function() {
    $(".global-error").hide();
  },

  _openLightCase: function(target) {
    lightcase.start({
      href: target,
      width: 1000,
      maxWidth: 1000,
      inline: {
        width: 1000,
        height: "auto"
      },
      shrinkFactor: 0.99
    });
  },

  _showError: function() {
    let $global = $(".global-error");
    $global.show();
    $("html, body").animate(
      {
        scrollTop: $global.offset().top
      },
      300
    );
  },

  _validateForm: function() {
    self = this;
    $("#retoure-form").parsley();
    $.listen("parsley:form:validated", function(ParsleyForm) {
      if (ParsleyForm.$element.attr("id") === "retoure-form") {
        const retoureAmountResult = self.validateRetoureAmount();
        const retoureInsuranceResult = self.validateRetoureInsurance();
        let counter =
          retoureAmountResult.counter + retoureInsuranceResult.counter;
        let selectionValid =
          retoureAmountResult.selectionValid ||
          retoureInsuranceResult.selectionValid;

        ParsleyForm.validationResult = counter > 0 && selectionValid;
      }
    });
  },

  validateRetoureAmount: function() {
    let counter = 0,
      selectionValid = false;
    $.each($(".js-retoure-amount"), function() {
      let value = parseInt($(this).val());
      counter += 1;
      if (value > 0) {
        let group = $(this).attr("data-validate-group");
        let reason = $(document).find(
          '.js-retoure-reason[data-validate-group="' + group + '"]'
        );
        let remark = $(document).find(
          '.js-retoure-remark[data-validate-group="' + group + '"]'
        );
        let textAreaHidden = remark
          .closest(".mandatory-text-area")
          .hasClass("hide");
        if (
          reason.val() === "0" ||
          (!textAreaHidden && remark.val().length === 0)
        ) {
          reason.addClass("error");
        } else {
          selectionValid = true;
          reason.removeClass("error");
        }
      }
    });
    return { counter, selectionValid };
  },

  validateRetoureInsurance: function() {
    let counter = 0,
      selectionValid = false;
    $.each($(".js-design-checkbox"), function() {
      counter += 1;
      if ($(this).prop("checked")) {
        selectionValid = true;
      }
    });
    return { counter, selectionValid };
  },

  _printAction: function($submit_btn, id) {
    let url = hi_base64.decode($submit_btn.attr("data-href"));
    if (url) {
      if (id) {
        url = self._replaceLastUrlValue(url, id);
      }
      window.open(url, "_blank");
    }
  },

  _replaceLastUrlValue: function(url, id) {
    return url.substr(0, url.lastIndexOf("=") + 1) + JSON.parse(id);
  },

  _qrCodeAction: function(data) {
    if (data && data.qrcode) {
      $("#qrcode-success-modal .js-image-wrapper").html(
        '<img width="250" height="250" src="data:image/png;base64,' +
          data.qrcode +
          '">'
      );
      lightcase.start({
        href: "#qrcode-success-modal"
      });
    } else {
      lightcase.start({
        href: "#qrcode-error-modal"
      });
    }
  }
};
