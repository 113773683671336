
export default {
  getModulesPath: (moduleName) => {
    try {
      const modulesMap = typeof MODULES_MAP !== typeof undefined ? MODULES_MAP : null;
      if (modulesMap) {
        const jsFolders = Object.entries(modulesMap);
        let foundFolder = '';

        for (const folderWithfiles of jsFolders) {
          let searchInFolder = folderWithfiles[0];
          let filesInFolder = folderWithfiles[1];
          for (const filename of filesInFolder) {
            if (filename === moduleName) {
              foundFolder = searchInFolder;
              break;
            }
          }
        }
        return foundFolder;
      }
    }
    catch (e) {
      console.warn("Failed to fetch MODULES_MAP", e.message);
    }
  }
}
