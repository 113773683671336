export default {
  init: function() {
    var klarnaPaymentWidgetUrl =
      "https://cdn.klarna.com/1.0/code/client/all.js";

    $.cachedScript = function(url, options) {
      options = $.extend(options || {}, {
        dataType: "script",
        cache: true,
        url: url
      });
      return $.ajax(options);
    };

    $.cachedScript(klarnaPaymentWidgetUrl);

    $(document).on("variationChanged", function() {
      $.cachedScript(klarnaPaymentWidgetUrl);
    });
  }
};
