import "../plugin/instagram";
import "../utils/mosaic";

export default {
  init: function() {
    $(".js_instagram-module").SnsInstagram({
      random: true,
      demo: false
    });
  }
};
