/**if you change anything here, please check loyalty subscribtion with user who has an empty address
 * and a user who already have an address. Check on PAR and NAK.  **/
export default {
  init: function() {
    var _this = this;
    var $loyaltyPoints = $("#loyaltyPoints");
    var $acceptTerms = $("#acceptTerms");
    var $button = $("#button");
    var $magazine = $("#magazine");
    var $terms = $("#terms");
    var $addressFormInput = $(".addressFormInput");
    var $addressHeadline = $(".address-headline");
    var $jsOpenForm = $(".js_close_address");
    var $btnSaveAndSubmit = $(".btn_save-and-submit");
    var $btnSubmit = $(".btn_submit");
    var $addressAddition = $("#addressAddition");
    var $addressForm = $(".addressForm");
    var $address = $(".address");
    var $ssn = $("#ssn");
    var $subscription_in_progress = $(".subscription_in_progress");
    var $subscribeForm = $("#subscribeForm");
    var $jsSubscribeClub365Event = $(".js-subscribe-club365-event");
    var $birthdayForm = $("#birthdayForm");
    var $jsOpenBirthday = $(".js_close_birthdate");

    $loyaltyPoints.on("change", function(e) {
      $acceptTerms.show();
      $button.show();
      $birthdayForm.show();
      var clubChecked = $loyaltyPoints.is(":checked");
      _this._switchVisibilityClubEnabled(clubChecked);
      if (!clubChecked) {
        $magazine.prop("checked", false).trigger("change");
        $terms.prop("checked", false).trigger("change");
        $addressFormInput.removeAttr("data-validate-required");
        $birthdayForm.hide();
      }
    });
    $magazine.on("change", function() {
      var magazineChecked = $magazine.is(":checked");
      var isEmptyAddress =
        $addressHeadline.attr("data-emptyAddress") === "true";
      var isClubVisible = $magazine.attr("data-isClubSubscribable") === "true";
      var editMode = $jsOpenForm.hasClass("active");

      _this._switchVisibilityMagazineEnabled(magazineChecked);
      if (magazineChecked) {
        $acceptTerms.show();
        $button.show();
        if (isEmptyAddress) {
          $jsOpenForm.hide();
          $btnSaveAndSubmit.toggle(true);
          $btnSubmit.toggle(true);
          $addressFormInput.attr("data-validate-required", true);
          $("#streetNumber").removeAttr("data-validate-required");
          $addressAddition.removeAttr("data-validate-required");
          _this._switchVisibilityEditButton(isEmptyAddress);
          $jsOpenForm.hide();
          $jsSubscribeClub365Event.removeProp("disabled");
          $addressFormInput.show();
          $addressForm.show();
          $address.hide();
        }
      } else {
        if (editMode) {
          $jsOpenForm.click().trigger("change");
          $("#streetNumber").removeAttr("data-validate-required");
          $address.hide();
        }
        $ssn.prop("required", true);
        $terms.prop("checked", false).trigger("change");
        $addressFormInput.removeAttr("data-validate-required");
        $btnSaveAndSubmit.toggle(false);
        $btnSubmit.toggle(true);

        if (!isClubVisible) {
          $button.hide();
          $acceptTerms.hide();
        }
      }
    });
    $jsOpenForm.on("click", function(e) {
      var editMode = $jsOpenForm.hasClass("active");
      var subscriptionInProgress =
        $subscription_in_progress.attr("data-subscriptionInProgress") ===
        "true";
      var ssnValue = $ssn.val();
      var termsChecked = $terms.is(":checked");
      _this._switchVisibilityEditButton(editMode);
      if (editMode) {
        $addressFormInput.attr("data-validate-required", true);
        $("#streetNumber").removeAttr("data-validate-required");
        $button.show();
        $acceptTerms.show();
        $addressAddition.removeAttr("data-validate-required");
        $birthdayForm.show();
      } else {
        $subscribeForm.trigger("reset");
        $magazine.prop("checked", true).trigger("change");
        $loyaltyPoints.prop("checked", true).trigger("change");
        $jsSubscribeClub365Event.removeProp("disabled");
        if (termsChecked) {
          $terms.prop("checked", true).trigger("change");
        }
        if ($ssn.val() != null) {
          $ssn.val(ssnValue);
        }
        if (!subscriptionInProgress) {
          $button.hide();
          $acceptTerms.hide();
          $birthdayForm.hide();
        }
      }
    });
    $jsOpenBirthday.on("click", function(e) {
      e.preventDefault();
      $("#birthdayFormInput").toggle();
    });
  },
  _switchVisibilityMagazineEnabled: function(isVisbile) {
    $(".addressForm").toggle(false);
    $(".addressFormInput").toggle(false);
    $(".js_editmode").toggle(false);
    $(".address").toggle(isVisbile);
    $(".address-headline").toggle(isVisbile);
  },
  _switchVisibilityClubEnabled: function(isVisbile) {
    $("#subscribe365_magazin").toggle(isVisbile);
    $("#checkbox-label-terms").toggle(isVisbile);
    $("#acceptTerms").toggle(isVisbile);
    $("#button").toggle(isVisbile);
    $(".ssn").toggle(isVisbile);
    $(".js_editmode").toggle(!isVisbile);
    $("#birthdayForm").toggle(isVisbile);
  },
  _switchVisibilityEditButton: function(isVisbile) {
    $(".btn_save-and-submit").toggle(isVisbile);
    $(".btn_submit").toggle(!isVisbile);
    $(".addressForm").toggle(isVisbile);
    $(".addressFormInput").toggle(isVisbile);
    $(".js_editmode").toggle(isVisbile);
    $(".address").toggle(!isVisbile);
  }
};
