/**
 * gmaps_service module to access google maps api and integrate it in the shop
 */

// global const if gmaps is loaded
var GMAPS_LOADED;

var self,
  handler, // current handler or who is called the gmaps service i.e. the location finder or my store page
  options, // map options
  countries, // map countries
  baseCountry = "de", // the base country - default 'de' override by base module
  countryRestrict = {
    country: baseCountry
  };

var gmaps_service = {
  GMAPS_LOADED: GMAPS_LOADED,

  /**
   * init the module
   */
  init: function(binder) {
    self = this;

    // set the current handler
    self.setHandler(binder);

    // set the base country - default is 'de' my override this here in setBaseCountry
    self.setBaseCountry();

    // bind the resources loaded event to call the given handler after the sources are loaded
    $(window).bind("gmaps-service-gmaps-loaded", self.setMapEnvAndCallHandler);
  },

  // some getter

  getHandler: function() {
    return handler;
  },

  getCountries: function() {
    return countries;
  },

  getBaseCountry: function() {
    return baseCountry;
  },

  getOptions: function() {
    return options;
  },

  getBaseZoom: function() {
    return self.getCountries()[baseCountry].zoom;
  },

  /**
   * loads the gmap resources
   */
  loadMaps: function() {
    var script = document.createElement("script");

    script.setAttribute("type", "text/javascript");
    script.setAttribute(
      "src",
      "https://maps.google.com/maps/api/js?key=AIzaSyC9x6A50aPGn5dsr_RNr6pjqvgICKIwW3M&v=3.exp&libraries=places&callback=GMapsLoadedCallback"
    );

    (
      document.getElementsByTagName("head")[0] || document.documentElement
    ).appendChild(script);
  },

  /**
   * setup the map environment and calls the given handler like location finder or mystore page
   */
  setMapEnvAndCallHandler: function() {
    if (!self.isGoogleUndefined()) {
      self.setCountries();
      self.setOptions();

      if (self.getHandler()) {
        $(window).trigger(self.getHandler());
      }
    }
  },

  // some setter

  setOptions: function() {
    options = {
      center: self.getCountries()[baseCountry].center,

      zoom: self.getCountries()[baseCountry].zoom,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.LARGE,
        position: google.maps.ControlPosition.LEFT_CENTER
      },

      scaleControl: true,
      panControl: false,
      streetViewControl: false,

      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_LEFT
      }
    };
  },

  setCountries: function() {
    countries = {
      at: {
        center: new google.maps.LatLng(47.5, 14.5),
        zoom: 6
      },
      au: {
        center: new google.maps.LatLng(-25.3, 133.8),
        zoom: 4
      },
      br: {
        center: new google.maps.LatLng(-14.2, -51.9),
        zoom: 3
      },
      ca: {
        center: new google.maps.LatLng(62, -110.0),
        zoom: 3
      },
      fr: {
        center: new google.maps.LatLng(46.2, 2.2),
        zoom: 5
      },
      de: {
        center: new google.maps.LatLng(51.2, 10.4),
        zoom: 6
      },
      mx: {
        center: new google.maps.LatLng(23.6, -102.5),
        zoom: 4
      },
      nz: {
        center: new google.maps.LatLng(-40.9, 174.9),
        zoom: 5
      },
      it: {
        center: new google.maps.LatLng(41.9, 12.6),
        zoom: 5
      },
      za: {
        center: new google.maps.LatLng(-30.6, 22.9),
        zoom: 5
      },
      es: {
        center: new google.maps.LatLng(40.5, -3.7),
        zoom: 5
      },
      pt: {
        center: new google.maps.LatLng(39.4, -8.2),
        zoom: 6
      },
      us: {
        center: new google.maps.LatLng(37.1, -95.7),
        zoom: 3
      },
      uk: {
        center: new google.maps.LatLng(54.8, -4.6),
        zoom: 5
      }
    };
  },

  setHandler: function(val) {
    handler = val;
  },

  /**
   * sets the base country for the right env in the google map
   * gets the base country from the base module
   */
  setBaseCountry: function(country) {
    baseCountry = country || "de";
    countryRestrict = {
      country: baseCountry
    };
  },

  /**
   * creates a map on a given element
   *
   * @param el
   * @returns {exports.ecmaIdentifiers.Map}
   */
  createMap: function(el) {
    el = document.getElementById(el);
    if (el === null) {
      return;
    }
    if (self.isGoogleUndefined()) {
      return;
    }
    return new google.maps.Map(el, self.getOptions());
  },

  /**
   * adds a autocomplete input to the map
   *
   * @param el
   * @returns {google.maps.places.Autocomplete}
   */
  addAutocomplete: function(el) {
    el = document.getElementById(el);
    var autocomplete;

    if (el === null || this.isGoogleUndefined()) {
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(
      /** @type {HTMLInputElement} */
      (el),
      {
        //types: ['address','(regions)','(cities)'],
        componentRestrictions: countryRestrict
      }
    );

    return autocomplete;
  },

  /**
   * adds a listener to the map
   *
   * @param el
   * @param eventName
   * @param callback
   */
  addListener: function(el, eventName, callback) {
    if (!el || !eventName || !callback || this.isGoogleUndefined()) {
      return;
    }
    google.maps.event.addListener(el, eventName, callback);
  },

  /**
   * trigger a maps event - needed el and the event name
   *
   * @param el
   * @param eventName
   */
  triggerEvent: function(el, eventName) {
    if (!el || !eventName || this.isGoogleUndefined()) {
      return;
    }

    google.maps.event.trigger(el, eventName);
  },

  /**
   * adds a info pop up to the map
   *
   * @param el
   * @returns {google.maps.InfoWindow}
   */
  addInfoWindow: function(el) {
    el = document.getElementById(el);
    var infoWindow;

    if (el === null || this.isGoogleUndefined()) {
      return;
    }

    infoWindow = new google.maps.InfoWindow({
      content: el
    });

    return infoWindow;
  },

  /**
   * add marker to the maps
   *
   * @param opts
   * @returns {google.maps.Marker}
   */
  addMarker: function(opts) {
    if (this.isGoogleUndefined()) {
      return;
    }
    return new google.maps.Marker(opts);
  },

  /**
   * get map bounds
   *
   * @returns {google.maps.LatLngBounds}
   */
  newBounds: function() {
    if (this.isGoogleUndefined()) {
      return;
    }
    return new google.maps.LatLngBounds();
  },

  /**
   * sets a map position by given lat and lng attributes
   *
   * @param lat
   * @param lng
   * @returns {google.maps.LatLng}
   */
  setMapPosition: function(lat, lng) {
    if (!lat || !lng || this.isGoogleUndefined()) {
      return;
    }

    // replace ',' by '.' in lat and lng

    if (isNaN(lat) && lat.indexOf(",") > -1) {
      lat = lat.replace(",", ".");
    }

    if (isNaN(lng) && lng.indexOf(",") > -1) {
      lng = lng.replace(",", ".");
    }

    return new google.maps.LatLng(lat, lng);
  },

  /**
   * reset the maps to position to the default center and zoom.
   * Usable to go back to a solid state
   *
   * @param map
   */
  resetMapPosition: function(map) {
    if (!map) {
      return;
    }

    var opts = self.getOptions();

    map.setZoom(opts.zoom);
    map.setCenter(opts.center);
  },

  /**
   * find locations by input value in el and calls callback fn with the location result
   *
   * @param el
   * @param callback
   */
  findCurrentPlaceByInputValue: function(el, callback, callbackNoResults) {
    if (!el) {
      return;
    }
    var searchTerm = document.getElementById(el).value;

    if (!searchTerm.length) {
      return;
    }

    if (this.isGoogleUndefined()) {
      return;
    }

    var autocompleteService = new google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      {
        input: searchTerm,
        offset: searchTerm.length,
        componentRestrictions: countryRestrict
      },
      function autocompleteResult(list) {
        if (list === null || list.length === 0) {
          // There are no suggestions available.
          // The user saw an empty list and hit enter.
          if (callbackNoResults) {
            callbackNoResults();
          } else {
            document.getElementById(el).placeholder =
              "Keine Ergebnisse gefunden";
          }
        } else {
          // First result that the user saw in the list.
          // We can use it and it'll be just
          // as if the user actually selected it
          // themselves. But first we need to get its details
          // to receive the result on the same format as we
          // do in the AutoComplete.
          var placesService = new google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(
            {
              reference: list[0].reference
            },
            function currentPlaceResult(detailsResult) {
              var currentPlace = detailsResult;
              document.getElementById(el).value =
                currentPlace.formatted_address;
              // show result
              if (callback) {
                callback(currentPlace);
              }
            }
          );
        }
      }
    );
  },

  isGoogleUndefined: function() {
    return typeof google == "undefined" || typeof google.maps == "undefined";
  }
};

export default gmaps_service;

// global callback fn after gmaps is loaded
window.GMapsLoadedCallback = function() {
  // set global flag and call event so the magic init function can happen
  gmaps_service.GMAPS_LOADED = true;
  $(window).trigger("gmaps-service-gmaps-loaded");
};
