var SnsInstagram = function(element, userConfig) {
  var self = this,
    globalUser = "globetrotterde",
    globalHash = "globetrotter",
    searchTags = null,
    headline = "",
    displayMedia = [],
    mediaResponse = null,
    bigImageIndex = 0,
    bigImagePosition = [0, 2, 4, 6],
    maxElements = 0;

  var config = {
    random: false,
    demo: false
  };

  function __construct() {
    searchTags = element.data().hash || "globetrotter";
    maxElements = element.data().maxElements || 1000;
    //config = $.extend(config, userConfig);

    if (config.random) {
      bigImageIndex = parseInt(Math.random() * (4 - 1) + 1, 10);
    }

    init();

    return self;
  }

  function init() {
    determineTags();
  }

  function determineTags() {
    searchTags = searchTags.split(",");

    if (searchTags.length > 1) {
      headline =
        searchTags[0] != "globetrotter" ? searchTags[0] : searchTags[1];
    } else {
      headline = searchTags[0];
    }

    getMediaRecent();
  }

  function getMediaRecent() {
    $.ajax({
      url: window.iShop.config.baseUrl + "/instagram/",
      success: function(data) {
        sorting(data.data);
      },
      error: function(jqXHR, textStatus, errorThrown) {}
    }).success();
  }

  function sorting(mediaResponse) {
    var currentImages = [];
    for (var i = 0; i < mediaResponse.length; i++) {
      var obj = mediaResponse[i];

      if (tagTester(searchTags.slice(), obj.tags.slice())) {
        currentImages.push(obj);
      }
    }

    for (var j = 0; j < currentImages.length; j++) {
      var obj1 = currentImages[j],
        currentImage = {};

      // jscs:disable
      /* jshint ignore:start */
      currentImage.small = obj1.images["low_resolution"].url;
      currentImage.big = obj1.images["standard_resolution"].url;
      currentImage.desc = obj1.caption.text;
      currentImage.likes = obj1.likes.count;
      currentImage.link = obj1.link;
      /* jshint ignore:end */
      // jscs:enable

      displayMedia.push(currentImage);
    }

    buildModul();
  }

  function tagTester(searchTerms, haystackTags) {
    if (searchTerms.length === 0) {
      return true;
    }

    if (searchTerms.length > haystackTags.length) {
      return false;
    }

    var currentSearchTerm = searchTerms[0];

    for (var h = haystackTags.length; h--; ) {
      var currentHaystack = haystackTags[h];

      if (currentSearchTerm == currentHaystack) {
        searchTerms.shift();
        haystackTags.splice(h, 1);

        return tagTester(searchTerms, haystackTags);
      }
    }

    return false;
  }

  function buildModul() {
    var headlineObj = $("<h3>", {
      class: "section-title"
    });

    var currentInnerHTML = element.html();
    element.html("");

    headlineObj.text("#" + headline);

    var content = $("<div>", {
        class: "content-element"
      }),
      wrapper = $("<div>", {
        class: "mosaic instagram-big clearfix "
      });

    /*if (config.demo) {
     var needed = 7 - displayMedia.length, lastElement = displayMedia.length - 1;
     for (; needed--;) {
     displayMedia.push(displayMedia[lastElement]);
     }
     }*/

    if (displayMedia.length < maxElements) {
      maxElements = displayMedia.length;
    }

    for (var i = 0; i < maxElements; i++) {
      var obj = displayMedia[i];
      var currentElement =
        bigImagePosition[bigImageIndex] == i
          ? buildBigTeaer(obj)
          : buildSmallTeaer(obj);

      wrapper.append(currentElement);
    }

    content.append(wrapper);
    if (element.data().headline) {
      element.append(headlineObj);
    }
    content.append(currentInnerHTML);
    element.append(content);

    show(wrapper);
  }

  function buildSmallTeaer(object) {
    return (
      '<div class="boxer column-4 js_mosaic-item mosaic-item size11">' +
      '<a href="' +
      object.link +
      '" target="_blank">' +
      '<img class="js_mosaic-background mosaic-image" src="' +
      object.small +
      '" alt="" />' +
      '<span class="caption"><span class="text-inner-padding"><span class="paragraph-small text">' +
      object.desc +
      '</span><span class="icon-heart paragraph-small">' +
      object.likes +
      "</span> </span></span>" +
      "</a>" +
      "</div>"
    );
  }

  function buildBigTeaer(object) {
    return (
      '<div class="boxer column-8 js_mosaic-item mosaic-item size22">' +
      '<a href="' +
      object.link +
      '" target="_blank">' +
      '<img class="js_mosaic-background mosaic-image" src="' +
      object.big +
      '" alt="" />' +
      '<span class="caption"><span class="text-inner-padding"><span class="paragraph-small text">' +
      object.desc +
      '</span><span class="icon-heart paragraph-small">' +
      object.likes +
      "</span> </span></span>" +
      "</a>" +
      "</div>"
    );
  }

  function show(wrapper) {
    element.SnsMosaic();
  }

  return __construct();
};

$.fn.extend({
  SnsInstagram: function(config) {
    return $(this).each(function() {
      new SnsInstagram($(this), config);
    });
  }
});
