var SnsEqualizeHeights = function(element, settings) {
  var self = this,
    config = {
      children: "> .js_same-height-element",
      minWidth: 802,
      resizePerRow: false,
      enableUnveil: true
    },
    resizeTimeout = null,
    childRows = {};

  /**
   *
   * @returns {SnsEqualizeHeights}
   * @private
   */
  function __construct() {
    config = $.extend(config, settings);

    collectChildrenByRow();

    initEvents();
    resetHeight();

    element.data("SnsEqualizeHeight", self).addClass("js_has-same-height");

    return self;
  }

  function collectChildrenByRow() {
    childRows = {};

    if (config.resizePerRow) {
      var tmpChildrenList = element.find(config.children);

      for (var i = 0; i < tmpChildrenList.length; i++) {
        var currentChild = $(tmpChildrenList[i]),
          currentTop = currentChild.position().top;

        if (typeof childRows[currentTop] !== "undefined") {
          childRows[currentTop] = childRows[currentTop].add(currentChild);
        } else {
          childRows[currentTop] = currentChild;
        }
      }
    } else {
      childRows[0] = element.find(config.children);
    }
  }

  /**
   *
   * @returns {Object}
   */
  function getAllImages() {
    var images = null;

    for (var key in childRows) {
      if (!childRows.hasOwnProperty(key)) {
        continue;
      }

      if (images === null) {
        images = childRows[key].find("img");
      } else {
        images = images.add(childRows[key].find("img"));
      }
    }

    return images;
  }

  /**
   *
   * @param {number|string} height
   */
  function setAllHeights(height) {
    for (var key in childRows) {
      if (!childRows.hasOwnProperty(key)) {
        continue;
      }

      childRows[key].height(height);
    }
  }

  function initEvents() {
    $(window).on("resize", function() {
      if (resizeTimeout !== null) {
        window.clearTimeout(resizeTimeout);
      }

      resizeTimeout = window.setTimeout(resetHeight, 100);
    });

    let childImages = getAllImages();
    if (childImages != null && childImages.length) {
      childImages.one("load", resetHeight);
      if (config.enableUnveil) {
        childImages.one("unveil", resetHeight);
      }
    }
  }

  function resetHeight() {
    if (resizeTimeout !== null) {
      window.clearTimeout(resizeTimeout);
      resizeTimeout = null;
    }

    collectChildrenByRow();
    setAllHeights("auto");

    if ($(window).width() >= config.minWidth) {
      setNewHeights();
    }
  }

  /**
   *
   * @param {Object} items
   * @returns {number}
   */
  function getRowHeight(items) {
    var currentMaxHeight = 0;

    for (var i = items.length; i--; ) {
      var currentChild = $(items[i]),
        currentHeight = currentChild.outerHeight();

      if (currentHeight > currentMaxHeight) {
        currentMaxHeight = currentHeight;
      }
    }

    return currentMaxHeight;
  }

  function setNewHeights() {
    for (var key in childRows) {
      if (!childRows.hasOwnProperty(key)) {
        continue;
      }

      var currentHeight = getRowHeight(childRows[key]);

      if (currentHeight === 0) {
        currentHeight = "auto";
      }

      childRows[key].height(currentHeight);
    }
  }

  this.refresh = function() {
    resetHeight();
  };

  return __construct();
};

$.fn.extend({
  SnsEqualizeHeights: function(options) {
    return $(this).each(function() {
      new SnsEqualizeHeights($(this), options);
    });
  }
});
