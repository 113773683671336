import base from "../base";
import global from "../global";

var element = {
  formId: "productReviewForm",
  containerClass: "ratings",
  containerHeadClass: "rating-head",
  jsToggleClass: "js_toggleButton",
  scrollTargetId: "success"
};

var _self;

var reviews = {
  init: function() {
    _self = this;

    this.reviewFormHandling();
    this.reviewRatingFormHandling();
    this.filterReviews();
    this.filterWithRatingReviews();
    this.pagingReviews();
    this.pagingEvents();
    this.writeReviewAnchor();
    this.linkToReview();
    this.reportReview();
    this.scrollToReviews();
    this.setHiddenParcellabFields();
  },
  reviewRatingFormHandling: function() {
    $(document).on("click", ".js-review-rating", function(e) {
      e.preventDefault();

      let $form = $(this).closest("form");
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: $form.attr("action"),
        data: $form.serialize(),
        success: function() {
          let index = $form.attr("data-index");
          $("#voteSection" + index).hide();
          $("#voteSend" + index).show();
        },
        error: function() {
          return false;
        }
      });
    });
  },

  reviewFormHandling: function() {
    // Remove server error on first keydown
    $(document).on("keydown", ".js-server-error", function() {
      $(this)
        .parsley()
        .removeError("server-error");
      $(this)
        .parsley()
        .validate();
    });

    $(document).on("submit", "#" + element.formId, function(event) {
      event.preventDefault();

      let $form = $(this);
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: $form.attr("action"),
        data: $form.serialize(),
        success: function(response) {
          let $captcha = $($.parseHTML(response)).filter("#missing-captcha");
          let $success = $($.parseHTML(response)).filter("#success");
          let $failureMessages = $($.parseHTML(response)).filter(
            ".validation-failure-messages"
          );

          if ($captcha.length) {
            $(".missing-captcha").html(response);
          } else if ($failureMessages.length) {
            $form.find(".js-server-error").each(function() {
              // remove old message
              $(this)
                .parsley()
                .removeError("server-error");
            });

            // add new server errors
            $failureMessages.each(function() {
              let $key = $(this).attr("data-message-key");
              let $value = $(this).html();
              let $inputField;
              if ($key === "rating") {
                $inputField = $form.find(".rating-stars");
                $inputField.next().addClass("error-txt");
              } else {
                $inputField = $form.find("#" + $key);
              }
              $inputField
                .parsley()
                .addError("server-error", { message: $value });
              if ($key === "rating") {
                $inputField.next().addClass("error-txt");
              }
            });

            $("html, body").animate(
              {
                scrollTop: $("#productReviewForm").offset().top
              },
              600
            );
          } else if ($success.length) {
            $("." + element.containerClass)
              .find("." + element.jsToggleClass)
              .addClass("disabled");
            $("." + element.containerHeadClass).prepend(response);

            $form.remove();

            $("html, body").animate(
              {
                scrollTop: $("#" + element.scrollTargetId).offset().top
              },
              600
            );
          }
        },
        error: function() {
          return false;
        }
      });
    });
  },

  pagingReviews: function() {
    var pages = [],
      reviewContainer = $(document).find("#reviewContainer"),
      reviews = reviewContainer.find(".comment-wrap"),
      pagination = reviewContainer.find(".pagination .pages"),
      isAverageView = reviewContainer
        .find(".rating-details-wrap")
        .hasClass("average");

    if (!isAverageView) {
      reviews.hide();
    }

    $.each(reviews, function() {
      pages.push($(this).attr("data-page"));
    });

    $.unique(pages);

    $.each(pages, function(key, value) {
      var pageElement = $("<li />")
        .append(
          $("<a />")
            .addClass("link")
            .attr("href", "#")
            .text(value)
            .attr("data-pagination", value)
        )
        .addClass("page");
      pageElement.appendTo(pagination);
    });

    setTimeout(function() {
      $(document)
        .find('[data-pagination="1"]')
        .trigger("click", "noUserClick");
    }, 200);
  },

  pagingEvents: function() {
    var $reviewContainer = $(document).find("#reviewContainer"),
      visibleClass = "visible";

    var setSpanValues = function() {
      var span = $reviewContainer.find(".js_span");
      var visibleElements = $reviewContainer.find(".comment-wrap:visible");
      var firstElement = visibleElements.first().attr("data-item-count"),
        lastElement = visibleElements.last().attr("data-item-count");
      var spanElement = firstElement + " - " + lastElement;
      span.text(spanElement);
    };

    var setPage = function(pageToSet, current) {
      $reviewContainer.find("[data-page]").hide();
      $reviewContainer.find('[data-page="' + pageToSet + '"]').show();

      var paging = $reviewContainer.find(".page");
      paging.removeClass("current");
      $(current)
        .parent()
        .addClass("current");
    };

    var setSpaces = function() {
      var pages = $reviewContainer.find(".pages .page");
      var len = pages.length;

      if (len > 6) {
        var space = '<li class="space">...</li>';

        pages.show();
        $reviewContainer.find(".pages .space").remove();
        pages.removeClass(visibleClass);

        $.each(pages, function(key, value) {
          var element = $(value);
          if (key === 0) {
            element.addClass("first");
          }
          if (key === len - 1) {
            element.addClass("last");
          }
          if (element.hasClass("current")) {
            element.addClass(visibleClass);
            element.prev().addClass(visibleClass);
            element.next().addClass(visibleClass);
          }
          if (key === 0 && element.hasClass("current")) {
            element
              .nextAll()
              .eq(1)
              .addClass(visibleClass);
          }
          if (key === len - 1 && element.hasClass("current")) {
            element
              .prevAll()
              .eq(1)
              .addClass(visibleClass);
          }
        });

        if ($reviewContainer.find(".current").index() >= 3) {
          $reviewContainer
            .find(".page.first")
            .nextUntil("." + visibleClass)
            .hide();
          $(space).insertAfter($reviewContainer.find(".first"));
        }
        if ($reviewContainer.find(".current").index() + 3 <= len) {
          $reviewContainer
            .find(".page.last")
            .prevUntil("." + visibleClass)
            .hide();
          $(space).insertBefore($reviewContainer.find(".last"));
        }
      }
    };

    $(document).on("click", ".pagination .link", function(e, type) {
      var page = $(this).attr("data-pagination");

      setPage(page, $(this));
      setSpanValues();
      setSpaces();

      if (type !== "noUserClick") {
        $("body,html").animate(
          {
            scrollTop: $("#reviewsContainer").offset().top
          },
          300
        );
      }
    });
  },
  filterReviews: function() {
    $(document).on("click", ".js-review-filter", function(e) {
      e.preventDefault();

      const productID = $("#productId").val();
      const dataHref = $(this).data("href");
      $.get(
        dataHref,
        {
          productId: productID
        },
        function(data) {
          $("#reviewsContainer").html(data);
          $(".js-navigation-item").removeClass("active");
          $('.js-review-filter[data-href="' + dataHref + '"]')
            .parent()
            .addClass("active");
          _self.pagingReviews();
        }
      );
    });
  },

  filterWithRatingReviews: function() {
    $(document).on("click", ".js-review-with-rating-filter", function(e) {
      e.preventDefault();

      const productID = $("#productId").val();
      const dataHref = $(this).data("href");
      $.get(
        dataHref,
        {
          productId: productID
        },
        function(data) {
          $("#reviewsContainer").html(data);
          $(".js-navigation-item").removeClass("active");
          $('.js-review-with-rating-filter[data-href="' + dataHref + '"]')
            .parent()
            .addClass("active");
          _self.pagingReviews();
        }
      );
    });
  },

  writeReviewAnchor: function() {
    $(document).on("click", ".js_writereview", function() {
      $("body,html").animate(
        {
          scrollTop: $("#reviewContainer").offset().top
        },
        300
      );
      $(document)
        .find(".js_writebtn")
        .trigger("click");
    });
  },

  linkToReview: function() {
    var _jumpToEnrty = function(reviewID) {
      var reviewElement = $(document).find("#" + reviewID),
        reviewPage = reviewElement.attr("data-page");
      $(document)
        .find('[data-pagination="' + reviewPage + '"]')
        .trigger("click", "noUserClick");
      $("body,html").animate(
        {
          scrollTop: $("#" + reviewID).offset().top
        },
        600
      );
    };

    $(document).on("click", ".js_link-to-review", function() {
      var reviewID = $(this).attr("data-review-link");
      _jumpToEnrty(reviewID);
    });
  },

  reportReview: function() {
    $(document).on("click", ".js-report-review", function(e) {
      e.preventDefault();
      var specificRT = $(this).attr("data-index");
      var captcha = "uniqueCaptcha" + specificRT;
      if (typeof grecaptcha !== undefined) {
        grecaptcha.render(captcha, {
          sitekey: $(".g-recaptcha-manual").attr("data-sitekey")
        });
      }
    });

    $(document).on("click", ".js-report-review-button", function(event) {
      event.preventDefault();
      var specificRT = $(this).attr("data-index");
      var formId = "#reportForm" + specificRT;
      var $form = $(formId);
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: $form.attr("action"),
        data: $form.serialize(),
        success: function(response) {
          var result = $(response).filter("#missing-captcha");
          var captchaMissedId = "#captchaMissedMessage" + specificRT;
          if (result.length > 0) {
            $(captchaMissedId).show();
          } else {
            var successMessageId = "#reportMessage" + specificRT;
            $(successMessageId).show();
            $(captchaMissedId).hide();
            var reportBoxId = "#reportReview" + specificRT;
            $(reportBoxId).hide();
          }
        },
        error: function(error) {
          console.log(error);
          return false;
        }
      });
    });
  },

  scrollToReviews: function() {
    $(document).on("click", ".js_rating-link", function() {
      $("body,html").animate(
        {
          scrollTop: $("#reviewContainer").offset().top
        },
        200
      );
    });
  },

  /**
   * Reads GET-Parameter and creates hidden input fields with these values.
   *
   */
  setHiddenParcellabFields: function() {
    var variantSelect = $("#variant"),
      form = $("#productReviewForm"),
      sku = base.getURLParameter("sku");
    var _hiddenFields = {
      dn: base.getURLParameter("dn"),
      on: base.getURLParameter("on")
    };

    if (_hiddenFields.dn && _hiddenFields.on) {
      $(".js_writebtn").trigger("click");

      $.each(_hiddenFields, function(key, value) {
        $("<input>")
          .attr({
            type: "hidden",
            name: key,
            value: value
          })
          .appendTo(form);
      });

      if (sku) {
        variantSelect.val("GLR3_" + sku);
        variantSelect.trigger("change");
      }
      $(".js_rating-link").trigger("click");
    }
  }
};

export default reviews;
