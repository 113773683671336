//CSRF protection
// import $ from "jquery";

import hi_base64 from "../js/lib/base64.min";
import lazyload from "../js/modules/lazyload";
import "../js/plugin/snsslider";
import "../js/modules/checkbox";
import "../js/plugin/lightcase";
import "../js/utils/selector";
import "../js/utils/imagefit";
import "../js/plugin/texttocanvas";
import "../js/utils/squareresize";
import "../js/plugin/accordion";
import "../js/plugin/stickynavi";
import "../js/utils/equalizeheights";
import "../js/plugin/snsstorelocator";
import "../js/plugin/tooltips";
import "../js/plugin/subscribe365";
import "../js/plugin/request_spinner";
import frontend_notifier from "./utils/frontend-notifier";

$("meta[name='_csrf_parameter']").attr("content");
var csrfHeader = $("meta[name='_csrf_header']").attr("content");
var csrfToken = $("meta[name='_csrf']").attr("content");
let headers = {};
headers[csrfHeader] = csrfToken;

var _this;

export default {
  headers: headers,

  init: function() {
    _this = this;
    this.globalEvents();
    this.contentToggle();
    this.initLightCase();
    this.openNewsletterLayer();
    this.openRegisterOptInLayer();
    this.openUserCountryLayer();
    this.mainNavigationCategoryImageLoader();
    this.mobileFooterListSlide();
    this.addFrontendDevModeBadge();
    this.setStickyNavigation();
    this.showSpinnerEvent();
    this.desktopSticky();
    this.initTopButton();
    this.toggleUspHeader();
    this.initContentToggle();

    const ro = new ResizeObserver(this.onHeaderResize);
    ro.observe(document.getElementById("header-and-navi"));
  },
  onHeaderResize() {
    const bumperElement = document.getElementById("sticky-navi-bumper");
    const headerAndNavi = document.getElementById("header-and-navi");
    if (bumperElement !== null && headerAndNavi !== null) {
      const headerAndNaviRect = headerAndNavi.getBoundingClientRect();
      bumperElement.style.height = headerAndNaviRect.height + "px";

      headerAndNavi.dispatchEvent(new Event("headerAndNavi-resized"), {
        height: headerAndNaviRect.height
      });
    }
  },

  initContentToggle() {
    let foldedContents = document.querySelectorAll(".js_hide_when_toggled");
    foldedContents.forEach(foldedContent => {
      if (typeof foldedContent !== "undefined" && foldedContent !== null) {
        let isOverflowed =
          foldedContent.offsetHeight < foldedContent.scrollHeight;
        let moreLink = foldedContent.nextElementSibling;
        if (!isOverflowed) {
          moreLink.classList.add("hide");
        } else {
          moreLink.classList.remove("hide");
        }
      }
    });
  },

  initLightCase: function() {
    $("a[data-rel=lightcase]").lightcase({
      labels: {
        "sequenceInfo.of": "/"
      }
    });
  },

  initTopButton: function() {
    jQuery(document).ready(function($) {
      const offset = 100;
      const speed = 250;
      const duration = 500;
      $(window).scroll(function() {
        if ($(this).scrollTop() < offset) {
          $(".topbutton").fadeOut(duration);
        } else {
          $(".topbutton").fadeIn(duration);
        }
      });
      $(".topbutton").on("click", function() {
        $("html, body").animate({ scrollTop: 0 }, speed);
        return false;
      });
    });
  },

  toggleUspHeader: function() {},

  mobileFooterListSlide: function() {
    var linkListElementClass = ".js-list-slidedowm-item";
    $(".js-list-slidedown").click(function() {
      if ($(window).width() <= 800) {
        $(linkListElementClass).slideUp();
        if (
          $(this)
            .next()
            .is(":visible")
        ) {
          return false;
        }
        $(this)
          .next()
          .slideToggle("fast");
      }
    });
    $(window).resize(function() {
      if ($(window).width() >= 801) {
        $(linkListElementClass).show();
      } else {
        $(linkListElementClass).hide();
      }
    });
  },

  desktopSticky: function() {
    document.addEventListener("scroll", function() {
      $(document).ready(function() {
        _this.handleSticky(0);
      });
    });
  },

  handleSticky: function(timeout) {
    if (document.querySelector("#nav-toggle")) {
      setTimeout(function() {
        let el = document.getElementById("header-and-navi");
        const isMobileDevice =
          window.getComputedStyle(document.querySelector("#nav-toggle"))
            .display !== "none";
        if (
          !isMobileDevice &&
          $(".navi").size() > 0 &&
          $(window).width() > 801
        ) {
          let deltohide = document.querySelectorAll(
            "#header-and-navi > .hide-when-tablet"
          );
          let adjustedheight =
            deltohide.length > 0 ? deltohide[0].offsetHeight : 0;
          let topofScreen = window.pageYOffset;
          if (
            topofScreen > adjustedheight + 37 &&
            !el.classList.contains("sticky")
          ) {
            el.classList.add("header-and-navi-stickyness");
            el.classList.add("sticky");
          } else if (
            topofScreen <= adjustedheight + 7 &&
            el.classList.contains("sticky")
          ) {
            el.classList.add("header-and-navi-stickyness");
            el.classList.remove("sticky");
          }
        }
      }, timeout);
    }
  },

  globalEvents: function() {
    $(document).on("click", "[data-fetching-screen]", function() {
      _this.isProcessing(true);
    });
    // geneirc toggle prev class
    $(document).on("click", ".js_toggle_prev_class", function(e) {
      e.preventDefault();
      var $this = $(this);
      $this.toggleClass("open");
      let teaserContainer = $this
        .parent(".text-module")
        .parent(".plp-text-image-teaser");
      teaserContainer.toggleClass("text-fold text-unfold");

      let bodyTextElement = $this.parent().find(".js_hide_when_toggled");
      bodyTextElement.toggleClass("fold unfold");

      let moreText = $this.parent().find(".moreText");
      moreText.toggleClass("hide");

      if ($this.find(".js_toggle-text").length > 0) {
        var textElem = $this.find(".js_toggle-text"),
          currText = textElem.text();

        textElem.text(textElem.data().text);
        textElem.data().text = currText;
      }
    });

    // Hide Omni RegisterLink, when customer is Logged in
    (function() {
      let $loginAsCustomerButton = document.getElementById(
        "omni-customer-logout"
      );
      if ($loginAsCustomerButton) {
        $("#omni-register-link").addClass("hide");
      } else {
        $("#omni-register-link").removeClass("hide");
      }
    })();

    $(".js-toggle-show-hide").click(function(e) {
      e.preventDefault();
      $(this)
        .find(".js-checkbox")
        .toggleClass("checked");
      $(".js-newsletter-section").slideToggle(0);
    });

    $("a[data-rel^=lightcase]").lightcase({
      labels: { "sequenceInfo.of": "/" }
    });

    (function() {
      var csrfTokenValue = document.querySelector('meta[name="_csrf"]');
      var targetInput = document.querySelector('input[name="_csrf"]');
      if (csrfTokenValue && targetInput) {
        targetInput.value = csrfTokenValue.content;
      }
    })();

    $(".js_gallery-slider").SnsSlider({
      slidesPerPage: 1,
      slidesPerScroll: 1,
      cycle: false,
      controls: {
        outer: true,
        generate: false
      },
      pageIndicator: {
        outer: true,
        generate: false,
        type: "text"
      }
    });

    lazyload.init();
    $(".js-design-checkbox").SnsDesignCheckbox();
    $(".js-design-radio").SnsDesignRadio();

    $(".help-popup-wrap, .help-popup-icon").SnsTooltips();
    $(".js_default-slider").SnsSlider({
      slidesPerPage: 1,
      slidesPerScroll: 1,
      cycle: true,
      pageIndicator: {
        type: "line"
      }
    });

    if (
      !document.implementation.hasFeature(
        "http://www.w3.org/TR/SVG11/feature#Image",
        "1.1"
      )
    ) {
      var allSvgImages = $("[data-fallback-img]");

      allSvgImages.each(function() {
        var $this = $(this),
          $img = $("<img />", {
            src: $this.attr("data-fallback-img"),
            class: $this.attr("class") + " fallback-img"
          });
        $this.replaceWith($img);
      });
    }

    $(".selector").SnsSelector();

    // brand-slider
    $(".js_brand-slider").SnsSlider({
      slidesPerPage: 3,
      slidesPerScroll: 3,
      breakpoints: {
        802: {
          disable: true
        }
      },
      pageIndicator: {
        enable: false
      }
    });

    $(".js_product-slider-large").SnsSlider({
      cycle: true,
      fullpage: false,
      slidesPerPage: 1,
      slidesPerScroll: 1,
      shortListPosition: "left",
      breakpoints: {
        600: { slidesPerPage: 2, slidesPerScroll: 2 },
        802: { slidesPerPage: 3, slidesPerScroll: 3 },
        1024: { slidesPerPage: 4, slidesPerScroll: 4 },
        1400: { slidesPerPage: 6, slidesPerScroll: 6 },
        1920: { slidesPerPage: 6, slidesPerScroll: 6 },
        2300: { slidesPerPage: 7, slidesPerScroll: 7 }
      },
      controls: {
        content: {
          prev: "",
          next: ""
        },
        outer: true
      },
      pageIndicator: {
        enable: false
      }
    });

    // product-slider (e.g. cross selling)
    $(".js_product-slider").SnsSlider({
      cycle: true,
      slidesPerPage: 1,
      slidesPerScroll: 1,
      shortListPosition: "left",
      breakpoints: {
        600: { slidesPerPage: 2, slidesPerScroll: 2 },
        802: { slidesPerPage: 4, slidesPerScroll: 4 }
      },
      controls: {
        content: {
          prev: "",
          next: ""
        },
        outer: true
      },
      pageIndicator: {
        enable: false
      }
    });

    $(".js_video-slider").SnsSlider({
      slidesPerPage: 1,
      slidesPerScroll: 1,
      cycle: true,
      breakpoints: {
        802: { disable: true }
      },
      pageIndicator: {
        enable: false
      }
    });

    $(".js_teaser-small-slider").SnsSlider({
      cycle: true,
      slidesPerPage: 1,
      slidesPerScroll: 1,
      breakpoints: {
        600: { slidesPerPage: 2, slidesPerScroll: 2 }
      },
      controls: {
        outer: true
      },
      pageIndicator: {
        enable: false
      }
    });

    $(".js_teaser-highlight-slider").SnsSlider({
      cycle: true,
      slidesPerPage: 1,
      slidesPerScroll: 1,
      breakpoints: {
        600: { slidesPerPage: 3, slidesPerScroll: 1 }
      },
      controls: {
        outer: true
      },
      pageIndicator: {
        enable: false
      }
    });

    $(".js_teaser-slider").SnsSlider({
      cycle: false,
      fullPage: true,
      slidesPerPage: 1,
      slidesPerScroll: 1,
      breakpoints: {
        600: { slidesPerPage: 2 },
        802: { slidesPerPage: 4 }
      },
      controls: {
        outer: true
      },
      pageIndicator: {
        enable: false
      }
    });

    //google maps
    var maps = $(".js_map");
    var mapStyleId = maps.data("mapstyle-id");
    if (maps.length) {
      $.getScript(
        "https://maps.google.com/maps/api/js?key=AIzaSyC9x6A50aPGn5dsr_RNr6pjqvgICKIwW3M&map_ids=" +
          mapStyleId +
          "&v=3.exp&libraries=places",
        function() {
          maps.SnsStoreLocator({
            marker: {
              useMarkerCluster: true
            }
          });
        }
      );
    }

    $(document).on("click", ".js_toggle-box-toggle", function() {
      var toggleBox = $(this).closest(".js_toggle-box");
      toggleBox.toggleClass("active");
    });

    // generic close
    $(document).on("click", ".js-close", function() {
      var $this = $(this);
      $this.parent().slideUp();
    });

    // abc selector
    $(".js-abc-selector a").on("click", function(e) {
      e.preventDefault();
      $(".js-abc-selector a").removeClass("active");
      var $this = $(this),
        letter = $this.attr("data-letter"),
        $topMarken = $(".js-top-marken a");
      $this.addClass("active");

      $(".js-top-marken").removeClass("show-all");

      $topMarken.removeClass("show");
      $topMarken.each(function() {
        if (
          $(this)
            .text()
            .substr(0, 1)
            .toUpperCase() === letter
        ) {
          $(this).addClass("show");
        }
      });
    });
    // fill loyalty remaining points circle MyAccount
    $(document).ready(function() {
      var $round = $(".round");
      if ($round) {
        let roundRadius = $round.find("circle").attr("r");
        let roundPercent = $round.data("percent");
        let roundCircum = 2 * roundRadius * Math.PI;
        let roundDraw = (roundPercent * roundCircum) / 100;
        $round.css("stroke-dasharray", roundDraw + " 999");
      }
    });

    $(".js-show-all-top-marken").on("click", function(e) {
      e.preventDefault();
      $(".js-top-marken").addClass("show-all");
    });
    // end abc selector

    $("#responsive_size_switcher svg").on("click", function() {
      var $this = $(this);
      $("#responsive_size_switcher svg").removeClass("active");
      $this.addClass("active");
      $("#page-frame")
        .width($this.attr("data-page-size-w"))
        .height($this.attr("data-page-size-h"));
    });

    // geneirc toggle class
    $(".js_toggle_element_class").on("click", function(e) {
      e.preventDefault();
      var $this = $(this),
        $toggleId = $this.attr("data-toggle-id"),
        toggleIdClass = $this.attr("data-toggle-id-class");
      $this.toggleClass("open");
      $("#" + $toggleId).toggleClass(toggleIdClass);
    });

    $(".js_close-activity").on("click", function(e) {
      e.preventDefault();

      var form = $(this).closest(".js_activity-form"),
        editBtn = $(this)
          .closest(".js_activity-content")
          .find(".js_open-activity-form");
      form.addClass("hide");
      editBtn.removeClass("hide");
    });

    $(".js_open-activity-form").on("click", function(e) {
      e.preventDefault();

      var form = $(this)
        .closest(".js_activity-content")
        .find(".js_activity-form");
      form.removeClass("hide");
      $(this).addClass("hide");
    });

    $(".js_save-activity").on("click", function(e) {
      e.preventDefault();

      // TODO: Save chekcboxen within closest inputs
    });

    $(".js_open-edit").on("click", function(e) {
      e.preventDefault();
      var self = $(this),
        currentId = self.data("remove-element"),
        parent = $('[data-remove-id="' + currentId + '"]'),
        formElement = parent.find(".edit-form"),
        buttonBox = parent.find(".alter-infos");

      if (self.data("type") === "form") {
        parent.find('input[type="text"]').removeAttr("disabled");
      }

      formElement.removeClass("hide");
      buttonBox.addClass("hide");
    });

    $(".js_open-form").on("click", function(e) {
      e.preventDefault();

      var self = $(this),
        form = self.parent().find(".js_edit-form"),
        wrapper = self.closest(".js_same-height-element");

      if (self.hasClass("active")) {
        form.addClass("hide");
        self
          .removeClass("active")
          .removeClass("icon-close")
          .addClass("icon-edit");
        wrapper.css("height", wrapper.data("previous-height"));
      } else {
        form.removeClass("hide");
        self
          .addClass("active")
          .addClass("icon-close")
          .removeClass("icon-edit");
        wrapper.data("previous-height", wrapper.css("height"));
        wrapper.css("height", "");
      }
    });

    $(".js_open-new").on("click", function(e) {
      e.preventDefault();

      var self = $(this),
        parent = self.closest(".js_edit-wrapper");

      parent.find(".js_new-form").removeClass("hide");
      parent.find(".icon-plus").addClass("hide");
    });

    $(".js_btn-decline").on("click", function(e) {
      e.preventDefault();

      var wrapper = $(this).closest(".js_edit-wrapper");
      if (wrapper.find(".js_new-form").length > 0) {
        wrapper.find(".js_new-form").addClass("hide");
        wrapper.find(".icon-plus").removeClass("hide");
      } else {
        wrapper.find(".js_edit-form").addClass("hide");
        wrapper.find(".alter-infos").removeClass("hide");
        wrapper.find('input[type="text"]').attr("disabled", true);
      }
    });

    $(".js_btn-accept").on("click", function(e) {
      // TODO: Save data within closest inputs
    });

    $(".js_remove_element_class").on("click", function(e) {
      e.preventDefault();
      var $this = $(this),
        $toggleId = $this.data("toggle-id"),
        toggleIdClass = $this.data("toggle-id-class");
      $this.addClass("open");
      $("#" + $toggleId).removeClass(toggleIdClass);
    });

    // geneirc toggle class
    $(".js_toggle_element_classes").on("click", function(e) {
      e.preventDefault();
      var $this = $(this),
        toggleClass = $this.data("toggle-class");
      $this.toggleClass("open");
      $this
        .parent()
        .parent()
        .find('[data-toggle-class="' + toggleClass + '"]')
        .toggleClass(toggleClass);

      if ($this.data("toggle-text") !== undefined) {
        var currentText = $this.text(),
          altText = $this.data("toggle-text");

        $this.text(altText).data("toggle-text", currentText);
      }
      $this.trigger("togglingFinished", [
        {
          isOpen: $this.hasClass("open")
        }
      ]);
    });

    $(".simple-stage").SnsImageFit();
    $(".js_accordion").SnsAccordion();
    $(".js_accordion-table").SnsAccordion({
      multipleBody: true
    });
    $(".stage-disrupter").SnsSquareResize();

    //generic submit on SnS-JS-Fake select Input
    $(document).on("selectchange", ".selector.js-select-submit", function(
      event
    ) {
      $(this)
        .closest("form")
        .submit();
    });

    $(document).on("click", "a.js-base64-link", function(e) {
      e.preventDefault();
      var url = hi_base64.decode($(this).attr("data-href"));
      window.open(url, "_blank");
    });

    $(".js_same-height-row-wrap").SnsEqualizeHeights({
      resizePerRow: true
    });

    $(document).on("click", ".close-lightcase", function() {
      lightcase.close();
    });
  },
  // this function is called by wishlist and other
  removeItemInit: function(callbackOnConfirm, callbackOnCancel) {
    /*
     FIXME: confirm box is removed due to UX -> add something like a lightbox for a nice confirmation
     Message text:  $this.data('delete-text')
     Cancel action: if ('function' === typeof callbackOnCancel) { callbackOnCancel($this); }
     */
    $(".js-remove-data").on("click", function(e) {
      var $this = $(this),
        removeId = $this.data("remove-element");

      e.preventDefault();
      if ("function" === typeof callbackOnConfirm) {
        callbackOnConfirm($this);
      }
      var element = $("[data-remove-id=" + removeId + "]");
      if (element.hasClass("watch-item")) {
        var elements = element.parent().children();
        $(elements[4])
          .removeAttr("data-toggle-class")
          .removeClass("hide");
      }
      element.remove();
    });
  },
  // this may be called by ajax reload content
  contentToggle: function() {
    toggle(".js_toggleButton", ".js_contentToggle");
    toggle(".mobileToggleButton", ".mobileToggle");

    function toggle(a, b) {
      var contentToggleButton = $(a);
      for (var i = contentToggleButton.length; i--; ) {
        var currentButton = $(contentToggleButton[i]),
          currentWrapper = currentButton.closest(b),
          externalWrapperName = currentButton.data().externalToggleWrap;

        if (externalWrapperName) {
          currentWrapper = $(
            '[data-toggle-name="' + externalWrapperName + '"]'
          );
        }

        if (currentWrapper.length === 0) {
          continue;
        }

        currentButton.on(
          "click",
          { wrapper: currentWrapper, button: currentButton },
          toggleContent
        );
      }

      function toggleContent(ev) {
        if (ev.data.button.data().preventDefault) {
          ev.preventDefault();
        }

        let wrapper = ev.data.wrapper;
        if (ev.data.button.data().toggleForceOpen) {
          wrapper.addClass("open");
        } else {
          wrapper.toggleClass("open");
        }
      }
    }
  },

  openNewsletterLayer: function() {
    $(document).on("submit", "#js-newsletter-form", function(e) {
      e.preventDefault();
      var $form = $(this);
      var ajaxUrl = $form.attr("action");
      $.ajax({
        url: ajaxUrl,
        cache: false,
        method: "POST",
        data: $form.serialize()
      })
        .then(function(data) {
          let $newsletter = $(".newsletter");
          let $recaptcha = $newsletter.find(".recaptcha");
          let $recaptchaError = $recaptcha.find(".error-txt");
          if (data.success) {
            lightcase.start({
              href: "#newsletter-subscribe-success"
            });
            $recaptcha.hide();
            $recaptchaError.hide();
            grecaptcha.reset();
          } else if (data.error) {
            lightcase.start({
              href: "#newsletter-subscribe-error"
            });
            grecaptcha.reset();
          } else {
            $recaptcha.show();
            if (data["captchaMissed"]) {
              $recaptchaError.show();
            }
          }
        })
        .catch(function(error) {
          if (window.console) {
            console.log(error);
          }
        });
    });
  },

  openRegisterOptInLayer: function() {
    if ($("#register-new-opt-in").length) {
      lightcase.start({
        href: "#register-new-opt-in"
      });
    }
    if ($("#register-new-opt-in-error").length) {
      lightcase.start({
        href: "#register-new-opt-in-error"
      });
    }
  },

  openUserCountryLayer: function() {
    if ($("#user-country-check").length) {
      lightcase.start({
        href: "#user-country-check",
        maxWidth: 400
      });
    }
  },

  mainNavigationCategoryImageLoader: function() {
    $(".main-link").one("mousemove", function() {
      $(this)
        .find(".js_unveil-category")
        .unveil();
    });
  },

  isProcessing: function(state) {
    var el = $("body");
    if (state === true) {
      el.addClass("fetching");
    } else {
      el.removeClass("fetching");
    }
  },

  setStickyNavigation: function() {
    if (iShop.isNavigationSticky) {
      document.body.classList.add("stick-navigation-top");
    }
  },

  /*
    Adds a badge on the bottom right corner when webpack dev server is running
  */
  addFrontendDevModeBadge: function() {
    frontend_notifier.addFrontendNotifier();
  },

  showSpinnerEvent: function() {
    let $buttonWithSpinner = $(".js-spinner");
    $buttonWithSpinner.click(function() {
      $buttonWithSpinner.requestSpinner({ elementType: "form" });
    });
  }
};
