// import $ from 'jquery';
import config from "../config/config";
import "../onsiteedit/jquery_selectboxes";

function showPanel(event) {
  if (!event) {
    return;
  }

  $("#messageValueInput")
    .focus()
    .select();

  var $onsiteeditPanel = $("#onsiteeditPanel");
  if (!$onsiteeditPanel.attr("data-clipped")) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  $("#clip").hide();
  $("#editorPanel").show();
  $("#closeButton").show();
  $onsiteeditPanel.attr("data-clipped", false);
  $onsiteeditPanel.off("click", showPanel);
}

function hidePanel(event) {
  event.preventDefault();
  event.stopPropagation();

  var $onsiteeditPanel = $("#onsiteeditPanel");
  $("#editorPanel").hide();
  $("#closeButton").hide();
  $("#clip").show();
  $onsiteeditPanel.attr("data-clipped", true);
  $onsiteeditPanel.on("click", showPanel);
}

function setMessageValueInput(event) {
  var $oldTranslatable = $(".ishop_i18n[data-ref].current");
  $oldTranslatable.removeClass("current");

  var $translatable = $("#keySelect option:selected").data("translatable");

  var value =
    $translatable.data("displaytext") ||
    $translatable.data(
      "displaytext-" + $("#pluralKeySelect option:selected").val()
    );
  if ($translatable.data("displaytext")) {
    $("#pluralKeySelect").hide();
  } else {
    $("#pluralKeySelect").show();
  }

  $("#messageValueInput")
    .data("originValue", value)
    .val(value);

  $translatable.addClass("current").html($("#messageValueInput").val());

  showPanel(event);
}

function selectTranslatable(event) {
  event.preventDefault();
  event.stopPropagation();

  $("#keySelect").val($(event.target).data("ref"));

  $("#keySelect").trigger("change");
}

function checkUpdateButtonEnabled(event) {
  var val = $("#messageValueInput").val();
  var orig = $("#messageValueInput").data("originValue");
  $("#updateButton").prop("disabled", val == orig);
}

function triggerUpdateOnEnter(event) {
  var keycode = event.keyCode || event.which;
  if (keycode == 13 || keycode == 10) {
    $("#updateButton").click();
  }
}

function toggleLinkSelectMode(event) {
  var $selectModeButton = $("#selectModeButton");
  var $linkModeButton = $("#linkModeButton");
  var selectMode = $selectModeButton.prop("disabled");

  $selectModeButton.prop("disabled", !selectMode);
  $linkModeButton.prop("disabled", selectMode);

  var $translatables = $(".ishop_i18n[data-ref]");
  if (selectMode) {
    $translatables.off("click").addClass("linking");
  } else {
    $translatables.on("click", selectTranslatable).removeClass("linking");
  }
}

function updateTranslation(event) {
  var pluralForm = $("#pluralKeySelect").val();
  $.ajax({
    url: config.contextPath + "/admin/onsiteedit/updateTranslation",
    type: "POST",
    dataType: "json",
    data: {
      key: $("#keySelect").val(),
      message: $("#messageValueInput").val(),
      pluralForm: pluralForm !== null ? pluralForm : -1
    },
    success: function(XMLHttpRequest, textStatus, errorThrown) {
      var $messageValueInput = $("#messageValueInput");
      $("#keySelect option:selected")
        .data("translatable")
        .html($messageValueInput.val());
      $messageValueInput.data("originValue", $messageValueInput.val());
      $messageValueInput.focus();
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      showError("Error occurred! " + errorThrown);
    }
  });
}

function init() {
  var $onsiteeditPanel = $("<div>")
    .attr("id", "onsiteeditPanel")
    .attr("data-clipped", true)
    .on("click", showPanel) //
    .appendTo($("body"));

  $("<div>")
    .attr("id", "clip")
    .addClass("clip")
    .text("edit")
    .appendTo($onsiteeditPanel);
  var $editorPanel = $("<div>")
    .attr("id", "editorPanel")
    .addClass("editorPanel")
    .hide()
    .appendTo($onsiteeditPanel);

  var $keySelect = $("<select>")
    .attr("id", "keySelect")
    .addClass("form-control")
    .on("change", setMessageValueInput) //
    .appendTo($editorPanel);

  var $translatables = $(".ishop_i18n[data-ref]");
  $translatables.each(function(i, translatable) {
    var $t = $(translatable);
    $t.on("click", selectTranslatable);

    $("<option>")
      .val($t.data("ref"))
      .text($t.data("ref"))
      .data("translatable", $t)
      .appendTo($keySelect);
  });

  $("<select>")
    .attr("id", "pluralKeySelect")
    .addClass("form-control")
    .on("change", setMessageValueInput) //
    .append(
      $("<option>")
        .val(1)
        .text("one")
    ) //
    .append(
      $("<option>")
        .val(2)
        .text("many")
    ) //
    .appendTo($editorPanel);

  $("<input>")
    .attr("id", "messageValueInput")
    .addClass("form-control")
    .attr("type", "text") //
    .on("focus keyup cut paste", checkUpdateButtonEnabled)
    .on("keydown", triggerUpdateOnEnter)
    .appendTo($editorPanel);

  $("<button>")
    .attr("id", "updateButton")
    .addClass("btn btn-80")
    .prop("disabled", true)
    .html("Update") //
    .on("click", updateTranslation)
    .appendTo($editorPanel);

  $("<button>")
    .attr("id", "selectModeButton")
    .addClass("btn btn-80")
    .html("Select")
    .on("click", toggleLinkSelectMode) //
    .prop("disabled", true)
    .appendTo($editorPanel);

  $("<button>")
    .attr("id", "linkModeButton")
    .addClass("btn btn-80")
    .html("Link")
    .on("click", toggleLinkSelectMode) //
    .appendTo($editorPanel);

  $("<button>")
    .attr("id", "closeButton")
    .addClass("btn")
    .text("×")
    .hide()
    .on("click", hidePanel)
    .appendTo($editorPanel);

  setMessageValueInput(null);
}

export default {
  init: init
};
