import "../plugin/lightcase";
import Inputmask from "inputmask/dist/inputmask/inputmask";

function MyAccount() {}

function applyIbanMask(inputElm) {
  Inputmask("aa99 9999 9999 9999 9999 99", {
    placeholder: " ",
    showMaskOnFocus: false,
    showMaskOnHover: false,
    undoOnEscape: false,
    casing: "upper"
  }).mask(inputElm);
}

MyAccount.prototype = {
  init: function() {
    let _this = this;
    _this._submitVoyadoNewsletterUnsubscribeHandler();
    _this._submitBirthdateHandler();
    _this._submitInterestsHandler();
    _this._removeInterestsHandler();
    _this._submitStoreHandler();
    _this._submitEmailHandler();
    _this._submitEditShippingAddressHandler();
    _this._submitDeleteShipingAddressHandler();
    _this._submitDeletePaymentHandler();
    _this._submitNewsletterSubscribeHandler();
    _this._submitNewsletterUnsubscribeHandler();
    _this._activateNewsletterSubmitButton();
    _this._deleteDebitPaymentHandler();
    _this._deletePhoneHandler();
    _this._addAccountIbanInputMask();
    _this._updateMaskOnEdit();

    $(".js_same-height-row-wrap").SnsEqualizeHeights({
      resizePerRow: true
    });
  },

  _submitStoreHandler: function() {
    let _this = this;
    $(document).on("click", ".js_profile-submit-store", function(e) {
      e.preventDefault();
      let $form = $("#js_store_form");
      let $selectedOption = $form.find("option:selected");
      let $fieldValue = $selectedOption.attr("data-storeId");
      let $selectedValue = $selectedOption.text();
      let $ajaxUrl = $form.attr("data-target") + "?storeId=" + $fieldValue;
      _this._submitAreaHandler("store", $selectedValue, $ajaxUrl);
    });
  },

  _submitBirthdateHandler: function() {
    let _this = this;
    $(document).on("click", ".js_profile-submit-birthdate", function(e) {
      e.preventDefault();
      let $form = $("#js_birthdate_form");
      let $fieldValue = $form.find(".js_datepicker").val();
      let $ajaxUrl = $form.attr("action") + "?birthdate=" + $fieldValue;
      _this._submitAreaHandler("birthdate", $fieldValue, $ajaxUrl);
    });
  },

  _submitInterestsHandler: function() {
    let _this = this;
    $(document).on("click", ".js_profile-submit-interests", function(e) {
      e.preventDefault();
      let $form = $("#js_interests_form");
      let $ajaxUrl = $form.attr("action");
      $form.find(".js-design-checkbox").each(function() {
        let $this = $(this);
        if ($this.parent().hasClass("checked")) {
          $ajaxUrl += $ajaxUrl.indexOf("?") === -1 ? "?" : "&";
          $ajaxUrl += "interests=" + $this.val();
        }
      });
      _this._submitAreaHandler("interests", null, $ajaxUrl);
    });
  },

  _removeInterestsHandler: function() {
    let _this = this;
    $(document).on("click", ".js_profile-remove-interest", function(e) {
      e.preventDefault();
      let $fieldValue = $(this).attr("data-value");
      let $ajaxUrl = $(this).attr("data-target") + "?interest=" + $fieldValue;
      _this._submitAreaHandler("interests", null, $ajaxUrl);
    });
  },

  _submitAreaHandler: function(area, selectedValue, ajaxUrl, data) {
    $.ajax({
      url: ajaxUrl,
      data: data,
      cache: false
    })
      .success(function(data) {
        if (typeof data.success !== "undefined") {
          if (data.success) {
            $(".js_selected_" + area).text(selectedValue);
            $(".js_close_" + area).click();
          } else {
            if (window.console) {
              console.log(error);
            }
          }
        } else {
          let $form = $("#js_interests_form");
          $(".js_selected_" + area).html(data);
          if ($form.is(":visible")) {
            $(".js_close_" + area).click();
          }

          // Update the "checked" after removing an element
          $form.find(".js-design-checkbox").each(function() {
            let $interestCheckbox = $(this);
            $interestCheckbox.attr("checked", false);
            $interestCheckbox.parent().removeClass("checked");
            $(".js_profile-remove-interest").each(function() {
              let activeInterest = $(this).attr("data-value");
              let interest = $interestCheckbox.val();
              if (interest === activeInterest) {
                $interestCheckbox.attr("checked", true);
                $interestCheckbox.parent().addClass("checked");
              }
            });
          });
        }
      })
      .fail(function(error) {
        if (window.console) {
          console.log(error);
        }
      });
  },

  _submitEmailHandler: function() {
    $(document).on("click", ".js_profile-submit-email", function(e) {
      e.preventDefault();
      let $form = $("#js_email_form");
      let $error = $form.find(".js-email-error");
      let $ajaxUrl = $form.attr("action");
      $.ajax({
        type: "POST",
        url: $ajaxUrl,
        cache: false,
        data: $form.serialize()
      })
        .success(function(data) {
          if (data.success) {
            $error.addClass("hide");
            $(".js_selected_email").text(data.email);
            $form
              .parent("div")
              .prev(".js_open-form")
              .click();
            $form.trigger("reset");
          } else {
            $error.text(data.error);
            $error.removeClass("hide");
          }
          $form.find("input").text("");
        })
        .fail(function(error) {
          if (window.console) {
            console.log(error);
          }
        });
    });
  },

  _submitVoyadoNewsletterUnsubscribeHandler: function() {
    $(document).on("click", ".js_submit_voyado_nl_unsubs", function(e) {
      e.preventDefault();
      $.ajax({
        url: $(this).attr("data-subscribe-action"),
        cache: false,
        method: "POST",
        data: $("#js-signOff").serialize()
      })
        .success(function(response) {
          $("html").html(response);
        })
        .fail(function(error) {
          if (window.console) {
            console.log(error);
          }
        });
    });
  },

  _submitFavoriteBrandsHandler: function() {
    $(document).on("click", ".js_brands-submit", function(e) {
      // TODO PW Implement click event + ajax call here. URL: /account/ajax/saveBrands/
    });
  },

  _submitEditShippingAddressHandler: function() {
    $(document).on("click", ".js_btn-shipping-edit", function(e) {
      e.preventDefault();
      let index = $(this).attr("data-selectedEditIndex");
      $("#selectedEditIndex-hidden").val(index);
      let $form = $(this).closest("form");
      let formActionUrl = $(this).attr("data-edit-action");
      $form.attr("action", formActionUrl).submit();
    });
  },

  _submitDeleteShipingAddressHandler: function() {
    $(document).on("click", ".js_btn-shipping-remove", function(e) {
      e.preventDefault();
      let index = $(this).attr("data-selectedRemoveIndex");
      $("#selectedRemoveIndex-hidden").val(index);
      let $form = $(this).closest("form");
      let formActionUrl = $(this).attr("data-remove-action");
      $form.attr("action", formActionUrl).submit();
    });
  },

  _submitDeletePaymentHandler: function() {
    $(document).on("click", ".remove-payment", function(e) {
      e.preventDefault();
      let formId = $(this).data("formid");
      $(formId).submit();
    });
  },

  _deleteDebitPaymentHandler: function() {
    $(document).on("click", ".js_delete-debit", function(e) {
      e.preventDefault();
      $.ajax({
        url: $(".js_delete-debit-url").val(),
        method: "POST"
      })
        .success(function() {
          location.reload();
        })
        .fail(function(error) {
          if (window.console) {
            window.console.log(error);
          }
        });
    });
  },

  _deletePhoneHandler: function() {
    $(document).on("click", ".js_delete-phone", function(e) {
      e.preventDefault();
      $.ajax({
        url: $(".js_delete-phone-url").val(),
        method: "POST"
      })
        .success(function() {
          location.reload();
        })
        .fail(function(error) {
          if (window.console) {
            window.console.log(error);
          }
        });
    });
  },

  _activateNewsletterSubmitButton: function() {
    let $newsletterOptoutConsent = $("#newsletterOptoutConsent");
    if ($newsletterOptoutConsent.is(":checked")) {
      $("#newsletterSubmitBtn").removeAttr("disabled");
    } else {
      $("#newsletterSubmitBtn").attr("disabled", "disabled");
    }

    $newsletterOptoutConsent.on("change", function() {
      if ($(this).is(":checked")) {
        $("#newsletterSubmitBtn").removeAttr("disabled");
      } else {
        $("#newsletterSubmitBtn").attr("disabled", "disabled");
      }
    });
  },

  _submitNewsletterSubscribeHandler: function() {
    $(document).on("click", ".js_submit_nl_subs", function(e) {
      e.preventDefault();
      $.ajax({
        url: $(this).attr("data-subscribe-action"),
        cache: false,
        method: "POST"
      })
        .success(function() {
          lightcase.start({
            href: "#newsletter-subscribe-success"
          });
        })
        .fail(function(error) {
          if (window.console) {
            console.log(error);
          }
        });
    });
  },

  _submitNewsletterUnsubscribeHandler: function() {
    $(document).on("click", ".js_submit_nl_unsubs", function(e) {
      e.preventDefault();
      $.ajax({
        url: $(this).attr("data-subscribe-action"),
        cache: false,
        method: "POST",
        data: $("#js-signOff").serialize()
      })
        .success(function() {
          location.reload();
        })
        .fail(function(error) {
          if (window.console) {
            console.log(error);
          }
        });
    });
  },

  _addAccountIbanInputMask: function() {
    let selectorAccountPaymentCredential = document.querySelector(
      ".js-account-payment-credential-value"
    );
    if (selectorAccountPaymentCredential) {
      Inputmask(
        "aa(99|XX) (9999)|(XXXX) (9999)|(XXXX) (9999)|(XXXX) (9999|XX99) 99",
        {
          placeholder: " ",
          showMaskOnFocus: false,
          showMaskOnHover: false,
          undoOnEscape: false,
          casing: "upper",
          definitions: {
            X: {
              validator: "[xX]"
            }
          }
        }
      ).mask(selectorAccountPaymentCredential);
    }
  },

  _updateMaskOnEdit: function() {
    let ibanInputElm = $(".js-account-payment-credential-value");
    let ibanHiddenValueFromServer = ibanInputElm.attr("value");
    ibanInputElm.on("click", function() {
      ibanInputElm.off("click");
      Inputmask.remove(ibanInputElm);
      if (ibanHiddenValueFromServer && ibanHiddenValueFromServer.length > 0) {
        if (
          ibanHiddenValueFromServer.indexOf("x") > -1 ||
          ibanHiddenValueFromServer.indexOf("X") > -1
        ) {
          ibanInputElm.val("DE");
          applyIbanMask(ibanInputElm);
        } else {
          applyIbanMask(ibanInputElm);
        }
      } else {
        applyIbanMask(ibanInputElm);
      }
    });
  }
};

export default new MyAccount();
