/**
 * base app module to bootstrap shop logic and more
 */

import moduleloader from "./moduleloader";
import global from "./global";

var self, basePath, assetPath, imagePath, countryCode;

var base = {
  /**
   * use to init the module
   */
  init: function() {
    self = this;

    moduleloader.init();
    global.init();

    var $body = $("body");

    // set the base path by data attribute in the body
    basePath = $body.data("basePath");
    // set the asset path by data attribute in the body
    assetPath = $body.data("assetPath");
    imagePath = assetPath + "/images/";
    // set the country code by data attribute in the body
    countryCode = $body.data("country");

    $body.addClass("loaded");

    return true;
  },

  /**
   * get the app base path
   *
   * @returns {*}
   */
  getBasePath: function() {
    return basePath;
  },

  /**
   * get the asset path
   *
   * @returns {*}
   */
  getAssetPath: function() {
    return assetPath;
  },

  /**
   * get the image path
   *
   * @returns {*}
   */
  getImagePath: function() {
    return imagePath;
  },

  /**
   * get the country code (de / at)
   *
   * @returns {*}
   */
  getCountryCode: function() {
    return countryCode;
  },

  /**
   * validate email-syntax
   *
   * @returns {*}
   */
  validateEmail: function(email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    return emailReg.test(email);
  },

  /**
   * get parameter from url
   *
   * @returns {*}
   */
  getURLParameter: function(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  },

  updateQueryStringParam: function(key, value) {
    var baseUrl = [
        location.protocol,
        "//",
        location.host,
        location.pathname
      ].join(""),
      urlQueryString = document.location.search,
      newParam = key + "=" + value,
      params = "?" + newParam;

    if (urlQueryString) {
      var keyRegex = new RegExp("([?&])" + key + "[^&]*");
      if (urlQueryString.match(keyRegex) !== null) {
        params = urlQueryString.replace(keyRegex, "$1" + newParam);
      } else {
        params = urlQueryString + "&" + newParam;
      }
    }
    window.history.replaceState({}, "", baseUrl + params);
  }
};
export default base;
