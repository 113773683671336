/*globals define, document, window, $*/
var tooltipCount = 0;

var SnsTooltips = function(element, userConf) {
  var self = this,
    conf = {
      clickable: false,
      positionOrder: ["right", "left", "bottom", "top"],
      fallbackTill: 801
    },
    wrap = null,
    tooltip = null,
    windowElement = null,
    bodyElement = null,
    screenSize = 0,
    screenHeight = 0,
    hasSubLightcase = false;

  function __construct() {
    wrap = $(element);
    tooltip = $(element).find(".help-popup");
    windowElement = $(window);
    bodyElement = $("body");
    hasSubLightcase = checkforLightcase();

    conf = $.extend(conf, userConf);

    updateScreenSize();

    moveTooltipToBody();

    initEvents();

    return self;
  }

  function checkforLightcase() {
    return wrap.find("[data-rel^=lightcase]").length;
  }

  function initEvents() {
    windowElement.on("resize", function() {
      updateScreenSize();
      if (!hasSubLightcase) {
        initLightcaseFallback();
      }
    });

    if (conf.clickable) {
      wrap
        .on("mouseup touchend", function() {
          positionTooltip();

          addBodyTouchClose();
        })
        .on("mouseleave", function() {
          bodyElement.trigger("touchstart");
        });
    } else {
      wrap
        .on("mouseenter", function() {
          positionTooltip();

          addBodyTouchClose();
        })
        .on("mouseleave", function() {
          bodyElement.trigger("touchstart");
        });
    }

    tooltip.attr("id", "tooltip" + tooltipCount);
    tooltipCount++;
    if (!hasSubLightcase) {
      initLightcaseFallback();
    }
  }

  function initLightcaseFallback() {
    wrap.unbind("click");

    if (conf.fallbackTill >= screenSize) {
      wrap.lightcase({
        href: "#" + tooltip.attr("id")
      });
    }
  }

  function addBodyTouchClose() {
    bodyElement.one("touchstart", function(ev) {
      var target = $(ev.target);
      if (!target.is(wrap) && !target.is(tooltip)) {
        tooltip.hide();
        tooltip.removeClass(
          "position-top position-right position-bottom position-left"
        );
      } else {
        addBodyTouchClose();
      }
    });
  }

  function updateScreenSize() {
    screenSize = windowElement.width();
    screenHeight = windowElement.height();
  }

  function positionTooltip() {
    var wrapOffset = wrap.offset(),
      wrapperWidth = wrap.outerWidth(),
      wrapperHeight = wrap.outerHeight(),
      tooltipWidth = tooltip.outerWidth(true),
      tooltipHeight = tooltip.outerHeight(true),
      isPositioned = false;

    if (conf.fallbackTill >= screenSize) {
      return;
    }

    tooltip.show();

    for (var i = 0; i < conf.positionOrder.length && !isPositioned; i++) {
      var currentPosition = conf.positionOrder[i],
        forcePosition = false;

      if (i === conf.positionOrder.length - 1) {
        forcePosition = true;
      }

      switch (currentPosition) {
        case "top":
          isPositioned = positionToTop(
            wrapOffset,
            tooltipHeight,
            wrapperWidth,
            forcePosition
          );
          break;
        case "bottom":
          isPositioned = positionToBottom(
            wrapOffset,
            tooltipHeight,
            wrapperWidth,
            wrapperHeight,
            forcePosition
          );
          break;
        case "left":
          isPositioned = positionToLeft(
            wrapOffset,
            tooltipWidth,
            wrapperHeight,
            forcePosition
          );
          break;
        default:
          isPositioned = positionToRight(
            wrapOffset,
            tooltipWidth,
            wrapperWidth,
            wrapperHeight,
            forcePosition
          );
          break;
      }
    }
  }

  function positionToTop(
    wrapOffset,
    tooltipHeight,
    wrapperWidth,
    forcePosition
  ) {
    var leftPosition = wrapOffset.left + wrapperWidth / 2,
      topPosition = wrapOffset.top - tooltipHeight;

    if (!forcePosition && topPosition - tooltipHeight < 0) {
      return false;
    }

    tooltip
      .css({
        left: leftPosition,
        top: topPosition
      })
      .addClass("position-top");

    return true;
  }

  function positionToRight(
    wrapOffset,
    tooltipWidth,
    wrapperWidth,
    wrapperHeight,
    forcePosition
  ) {
    var leftPosition = wrapOffset.left + wrapperWidth,
      topPosition = wrapOffset.top + wrapperHeight / 2;

    if (!forcePosition && leftPosition + tooltipWidth > screenSize) {
      return false;
    }

    tooltip
      .css({
        left: leftPosition,
        top: topPosition
      })
      .addClass("position-right");

    return true;
  }

  function positionToBottom(
    wrapOffset,
    tooltipHeight,
    wrapperWidth,
    wrapperHeight,
    forcePosition
  ) {
    var leftPosition = wrapOffset.left + wrapperWidth / 2,
      topPosition = wrapOffset.top + wrapperHeight;

    if (!forcePosition && topPosition + tooltipHeight > screenHeight) {
      return false;
    }

    tooltip
      .css({
        left: leftPosition,
        top: topPosition
      })
      .addClass("position-bottom");

    return true;
  }

  function positionToLeft(
    wrapOffset,
    tooltipWidth,
    wrapperHeight,
    forcePosition
  ) {
    var leftPosition = wrapOffset.left - tooltipWidth,
      topPosition = wrapOffset.top + wrapperHeight / 2;

    if (!forcePosition && leftPosition < 0) {
      return false;
    }

    tooltip
      .css({
        left: leftPosition,
        top: topPosition
      })
      .addClass("position-left");

    return true;
  }

  function moveTooltipToBody() {
    bodyElement.append(tooltip);
  }

  return __construct();
};

$.fn.extend({
  SnsTooltips: function(userConf) {
    return $(this).each(function() {
      new SnsTooltips($(this), userConf);
    });
  }
});
