import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import Swipe from "swipejs/swipe";

let _self;
export default {
  init: function(context) {
    _self = this;

    /**
     ISGTDE-2977 AB-Test of Mannequin images.
     window.hideMannequin will be set by Google Optimize AB Testing Tool
     **/
    if (window.hideMannequin) {
      $('.product-images *[data-asset-type="mannequin"]').remove();
    }

    const element = document.getElementById("slider");
    window.mySwipe = new Swipe(element, {
      startSlide: 0,
      draggable: true,
      autoRestart: false,
      continuous: true,
      disableScroll: false,
      stopPropagation: true,
      callback: function(index, element) {
        _self.setActiveClass(mySwipe, element);
      }
    });

    $(".detail-image").show();

    $(document).on(
      "mouseenter click",
      ".article-detail-context .js-src-image",
      function() {
        const index = $(this)
          .parent()
          .index();
        mySwipe.slide(index, 300);
      }
    );

    if (context !== "quickview") {
      _self.initPhotoSwipeFromDOM(".js-photoswipe");
    }
  },

  initPhotoSwipeFromDOM: function(gallerySelector) {
    const openPhotoSwipe = function(index, galleryElement, disableAnimation) {
      let pswpElement = document.querySelectorAll(".pswp")[0],
        gallery,
        options,
        items;

      items = parseThumbnailElements(galleryElement);

      options = {
        galleryUID: galleryElement.getAttribute("data-pswp-uid"),

        getThumbBoundsFn: function(index) {
          // See Options -> getThumbBoundsFn section of documentation for more info
          const thumbnail = items[index].el[0], // find thumbnail
            pageYScroll = window.scrollY || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect();

          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      };

      options.index = mySwipe.getPos();
      options.timeToIdle = false;
      if (isNaN(options.index)) {
        return;
      }

      if (disableAnimation) {
        options.showAnimationDuration = 0;
      }
      gallery = new PhotoSwipe(
        pswpElement,
        PhotoSwipeUI_Default,
        items,
        options
      );
      gallery.init();

      gallery.listen("afterChange", function() {
        const index = gallery.getCurrentIndex();
        mySwipe.slide(index, 100);
      });
    };
    const parseThumbnailElements = function() {
      const items = [],
        gallerItems = $(".js-photoswipe div:not([data-cloned])").find(
          ".gallery-item"
        );
      $.each(gallerItems, function(index, element) {
        items.push({
          src: $(element).attr("data-href"),
          w: 2000,
          h: 2000,
          el: $(element).find("img"),
          msrc: $(element).attr("data-msrc")
        });
      });
      return items;
    };

    const closest = function closest(el, fn) {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    const onThumbnailsClick = function(e) {
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);

      const eTarget = e.target;

      const clickedListItem = closest(eTarget, function(el) {
        return el.tagName && el.tagName.toUpperCase() === "DIV";
      });

      if (!clickedListItem) {
        return;
      }

      let clickedGallery = clickedListItem.parentNode,
        childNodes = clickedListItem.parentNode.childNodes,
        numChildNodes = childNodes.length,
        nodeIndex = 0,
        index;

      for (let i = 0; i < numChildNodes; i++) {
        if (childNodes[i].nodeType !== 1) {
          continue;
        }

        if (childNodes[i] === clickedListItem) {
          index = nodeIndex;
          break;
        }
        nodeIndex++;
      }

      if (index >= 0) {
        openPhotoSwipe(index, clickedGallery);
      }
      return false;
    };

    const photoswipeParseHash = function() {
      const hash = window.location.hash.substring(1);
      const params = {};

      if (hash.length < 5) {
        return params;
      }

      const vars = hash.split("&");
      for (let i = 0; i < vars.length; i++) {
        if (!vars[i]) {
          continue;
        }
        const pair = vars[i].split("=");
        if (pair.length < 2) {
          continue;
        }
        params[pair[0]] = pair[1];
      }

      if (params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      return params;
    };

    const galleryElements = document.querySelectorAll(gallerySelector);

    for (let i = 0; i < galleryElements.length; i++) {
      let galleryElement = galleryElements[i];
      galleryElement.setAttribute("data-pswp-uid", i + 1);
      galleryElement.onclick = onThumbnailsClick;
    }

    const hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
      openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true);
    }
  },

  setActiveClass: function(slider, element) {
    const elementDetailImage = $(element).find(".detail-image");
    elementDetailImage.attr("src", elementDetailImage.attr("data-src"));

    const imageThumbList = $(document).find(".image-list");
    imageThumbList.find(".item.active").removeClass("active");
    imageThumbList
      .find(".item")
      .eq(slider.getPos())
      .addClass("active");

    $(document)
      .find(".thumbs .activeSlideIndex")
      .text(slider.getPos() + 1);
  },

  quickviewImageSwipe: function() {
    const element = document.getElementById("quickviewslider");
    window.quickviewSwipe = new Swipe(element, {
      startSlide: 0,
      draggable: true,
      autoRestart: false,
      continuous: true,
      disableScroll: false,
      stopPropagation: true,
      callback: function(index, element) {
        _self.setActiveClass(quickviewSwipe, element);
      }
    });

    $(".detail-image").show();
    $(document).on(
      "mouseenter click",
      ".quickview-context .js-src-image",
      function() {
        const index = $(this)
          .parent()
          .index();
        quickviewSwipe.slide(index, 300);
      }
    );
  }
};
