import "jquery-ui/ui/widgets/datepicker";
import "../modules/validate";

var self;
export default {
  init: function() {
    self = this;
    self.eventHanders();
  },

  eventHanders: function() {
    $(".js_datepicker").datepicker({
      minDate: 0,
      dateFormat: "dd.mm.yy"
    });

    let $option = $(".option-item");
    let $giftcardAdsBtn = $("#giftcard_ads_btn");
    let $giftcartAmount = $(".giftcart_amount");
    let $giftcartAmountHidden = $(".giftcart_amount_hidden");
    let $giftcartAmountPreconfiguredValue = $(
      ".giftcart_amount_preconfigured_value"
    );

    $option.click(function() {
      $giftcartAmount.val("");
    });

    $giftcartAmount.click(function() {
      $giftcartAmount.attr("data-validate-required", true);
      $("input[name='giftcard']:checked").removeAttr("checked");
    });

    $giftcartAmountPreconfiguredValue.click(function() {
      $giftcartAmount.attr("data-validate-required", false);
      $giftcartAmount.removeClass("error");
      $(".error-txt").remove();
    });

    $giftcardAdsBtn.click(function() {
      let valueFreetext = $giftcartAmount.val();
      let valueRadio = $("input[name='giftcard']:checked").val();

      if (valueFreetext !== "") {
        let value = parseInt(valueFreetext);
        let attrMin = parseInt($giftcartAmount.attr("min"));
        let attrMax = parseInt($giftcartAmount.attr("max"));
        if (value >= attrMin && value <= attrMax) {
          $giftcartAmountHidden.val(value);
        }
      } else if (valueRadio !== undefined) {
        $giftcartAmountHidden.val(valueRadio);
      }
    });

    // TODO: PW - React on all possible amount variants of the customer and autocorrect it to a valid amount
    $(document).on("blur", ".fix-amount", function() {
      let giftcardAmount = $(this)
        .val()
        .valueOf()
        .toFixed(2)
        .replace(",", ".");
      $(this).val(giftcardAmount);
      if (
        giftcardAmount >
        $(this)
          .attr("max")
          .valueOf()
          .toFixed(2)
      ) {
        if ($(this).hasClass("error")) {
          $(this)
            .parsley()
            .updateError(name, {
              message: $(this).attr("data-validate-max-message"),
              updateClass: true
            });
        } else {
          $(this)
            .parsley()
            .addError(name, {
              message: $(this).attr("data-validate-max-message"),
              updateClass: true
            });
        }
      }
      if (
        $(this)
          .parsley()
          .validate()
      ) {
        $(this)
          .parsley()
          .removeError(name, {
            updateClass: true
          });
      }
    });

    $(document).on("click", ".js-recipient-type input", function() {
      $(".js-recipient-type li").removeClass("is-active");
      $(this)
        .closest("li")
        .addClass("is-active");
      self.updateRecipient($(this).attr("data-recipient-type-select"));
    });

    let initRecipientTypeSelect = $(".option-item.is-active .js-radio").attr(
      `data-recipient-type-select`
    );
    if (typeof initRecipientTypeSelect !== "undefined") {
      self.updateRecipient(initRecipientTypeSelect);
    }
  },

  updateRecipient: function(typeToShow) {
    let enumType = {
      email: "email",
      sms: "sms"
    };
    $.each(enumType, function(key, value) {
      let elementTypeToHide = $(document).find(
        '[data-recipient-type="' + value + '"]'
      );
      elementTypeToHide.hide();
      elementTypeToHide.find("input, textarea").prop("disabled", true);

      if (value === typeToShow) {
        let elementTypeToShow = $(document).find(
          '[data-recipient-type="' + typeToShow + '"]'
        );
        elementTypeToShow.show();
        elementTypeToShow.find("input, textarea").prop("disabled", false);
      }
    });
  }
};
