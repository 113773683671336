import global from "../global";

export default {
  init: function() {
    this.omqInit();
    this.supportFormHandling();
  },

  omqInit: function() {
    window._os = window._os || {};
    _os.account = "globetrotter";
    _os.apikey = "27265d10-ffbe-4f04-864e-b7da5b4eab67";
    _os.isMobile = true;
    _os.language = "de";
    _os.emptyText = "Hier werden mögliche Antworten zu deiner Frage angezeigt";

    (function() {
      var os = document.createElement("script");
      os.type = "text/javascript";
      os.async = true;
      os.src = "https://globetrotter.omq.de/os/os.min.js";

      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(os, s);
    })();

    $("#form-toggle").on("click", function() {
      var question = $("#os-message").val();
      $("#support-form .form-message").val(question);
      $("#support-form").slideToggle(150);
    });
  },
  supportFormHandling: function() {
    $("#support-form .btn.primary").addClass("disabled");

    $("#support-form ul").on("click", function() {
      var selected_text = $("#support-form span.js_selected_name")[0];
      if (selected_text.innerText.toLowerCase() != "bitte wählen") {
        $("#support-form ul li:contains(Bitte wählen)")[0].remove();
        $("#support-form select option[value='place_holder']").remove();
        $("#support-form .btn.primary").removeClass("disabled");
        $("#support-form ul").off("click");
      }
    });
    $(document).on("submit", "#support-form", function(event) {
      event.preventDefault();

      var $form = $(this);
      var jsondata = {};

      $form.find(".btn.primary").prop("disabled", true);
      $form.find(".error-txt").hide();
      $form.find(".text").removeClass("error");
      $form.serializeArray().forEach(function(property) {
        jsondata[property.name] = property.value;
      });

      $.ajax({
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        headers: global.headers,
        url: $form.attr("action"),
        data: JSON.stringify(jsondata),
        success: function(response) {
          $("#support-form .btn.primary").text(
            "Nachricht erfolgreich gesendet"
          );
          $("#support-form .btn.primary").addClass("disabled");
        },
        error: function(xhr, ajaxOptions, thrownError) {
          if (xhr.responseJSON.fieldErrors) {
            xhr.responseJSON.fieldErrors.forEach(function(fieldError) {
              var fieldName = fieldError.field;
              var errorMessage = fieldError.message;
              var errorField = $form.find("[name=" + fieldName + "]");
              var errorElement = errorField.siblings(".error-txt");

              errorField.addClass("error");
              errorElement.text(errorMessage);
              errorElement.show();
            });
          }
          $form.find(".btn.primary").prop("disabled", false);
        }
      });
    });
  }
};
