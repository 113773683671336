/*globals define, document, window, $*/

import datalayer from "../modules/datalayer";
import insurance from "../modules/insurance";

var status = 1; // current status of minicart 1=active || 0=inactive

function Minicart() {
  this.popover_html = null;
}

Minicart.prototype = {
  init: function() {
    this._fetchMetadata();
    this._deleteHandler();
  },

  setCount: function(count) {
    let $countElement = $("#cart-count");
    $countElement.html(count);
    $countElement.attr("data-count", count);
  },

  setNumberCircle: function(count) {
    let $numberCircle = $(".cart-preview .number-circle");
    if (count === "undefined" || count === 0) {
      $numberCircle.hide();
      $numberCircle.html(0);
    } else {
      $numberCircle.show();
      $numberCircle.html(count);
    }
  },

  setTotal: function(total) {
    $("#cart-price").html(total);
  },

  fireDataLayer: function(itemId, changingAmount) {
    datalayer.removeFromCart(itemId, changingAmount);
  },

  _setStatus: function(val) {
    status = val;
  },

  reset: function() {
    this.popover_html = null;
  },

  _getStatus: function() {
    return status;
  },

  _fillContent: function(data) {
    $(".js-minicart-content").html(data);
  },

  _fetchMetadata: function() {
    var _this = this;
    var metadataUrl =
      $(".js-minicart-metadata").attr("data-basepath") +
      "ajax/minibasket/metadata/";
    $.ajax({
      url: metadataUrl,
      cache: false
    })
      .then(function(data) {
        $(".js-minicart-metadata").html(data);
        if ($(window).width() >= 1024) {
          $(".js-cart-header").on("mouseenter", function(e) {
            // do not show minicart if its disabled by status set to 0
            if (_this._getStatus() === 0) {
              return;
            }
            // load ajax only first time
            if (!_this.popover_html) {
              var miniBasketUrl =
                $(".js-minicart-metadata").attr("data-basepath") +
                "ajax/minibasket/";
              $.ajax({
                url: miniBasketUrl,
                cache: false
              })
                .then(function(data) {
                  _this.popover_html = data;
                  _this._fillContent(data);
                })
                .catch(function(error) {
                  if (window.console) {
                    window.console.log(error);
                  }
                });
            }
          });
        }
      })
      .catch(function(error) {
        if (window.console) {
          window.console.log(error);
        }
      });
  },

  _deleteHandler: function() {
    var _this = this;
    $(document).on("click", ".js-item-delete", function() {
      var ajaxUrl = $(this).attr("data-target");
      var itemId = $(this).attr("data-item-id");
      var changingAmount = -$(this).data("initial-value");
      $.ajax({
        url: ajaxUrl,
        cache: false
      })
        .success(function(data) {
          _this._fillContent(data);
          _this.popover_html = data;
          var $html = $(data);
          var itemsCount = $html.data("count");
          if (itemsCount !== "undefined") {
            _this.setCount(itemsCount);
          }
          _this.setNumberCircle(itemsCount);
          var total = $html.data("total");
          if (total !== "undefined") {
            _this.setTotal(total);
          }
          _this.fireDataLayer(itemId, changingAmount);
          datalayer.updateVoyadoCartTracking();
          _this.handleInsuranceCheckbox(itemId);
        })
        .fail(function(error) {
          if (window.console) {
            window.console.log(error);
          }
        });
    });
  },

  handleInsuranceCheckbox: function(itemId) {
    let $insuranceDiv = $(".article-insurance");
    if ($insuranceDiv.length > 0) {
      let $insuranceJsCheckbox = $insuranceDiv.find(".js-design-checkbox");
      if (
        $insuranceJsCheckbox.attr("data-sku") === itemId ||
        $insuranceJsCheckbox.attr("data-insurance-sku") === itemId
      ) {
        insurance.setInsuranceCheckboxDisable(false);
      }
    }
  }
};

export default new Minicart();
