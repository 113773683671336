(function($) {
  var SnsDesignCheckbox = function(element) {
      var self = this,
        checkbox = null,
        designCheckbox = null,
        isCompleteFake = false,
        label = null;

      /**
       *
       * @returns {SnsDesignCheckbox}
       * @private
       */
      function __construct() {
        checkbox = $(element);

        if (!checkbox.is("input")) {
          isCompleteFake = true;
        }

        findLabel();
        generateFakeCheckbox();
        initEvents();

        return self;
      }

      /**
       *
       */
      function findLabel() {
        var checkboxId = checkbox.attr("id");

        if ($('label[for="' + checkboxId + '"]').length) {
          return; // in this case we don't have to do some magic with the labels
        } else if ($("#label-" + checkboxId).length) {
          label = $("#label-" + checkboxId);
        } else if ($("#checkbox-label-" + checkboxId).length) {
          label = $("#checkbox-label-" + checkboxId);
        }
      }

      /**
       *
       */
      function generateFakeCheckbox() {
        designCheckbox = $("<span>", {
          class: "design-checkbox"
        });
        checkbox.wrap(designCheckbox);

        designCheckbox = checkbox.parent();
        designCheckbox.toggleClass("checked", checkIfIsEnabled());

        if (checkbox.hasClass("error")) {
          designCheckbox.addClass("error");
        }
      }

      /**
       *
       */
      function initEvents() {
        if (isCompleteFake) {
          checkbox.on("click", function() {
            checkbox.toggleClass("checked");
            designCheckbox.toggleClass("checked", checkIfIsEnabled());
          });
        } else {
          checkbox.on("change", function() {
            designCheckbox.toggleClass("checked", checkIfIsEnabled());
          });
        }

        if (label && label.length) {
          label.on("click", function() {
            checkbox.trigger("click");
          });
        }
      }

      /**
       *
       */
      function checkIfIsEnabled() {
        if (isCompleteFake) {
          return checkbox.hasClass("checked");
        }
        return checkbox.is(":checked") ? true : false;
      }

      return __construct();
    },
    SnsDesignRadio = function(element) {
      var self = this,
        radio = null,
        radiosInGroup = null,
        designRadio = null,
        isCompleteFake = false,
        label = null;

      /**
       *
       * @returns {SnsDesignRadio}
       * @private
       */
      function __construct() {
        radio = $(element);

        if (!radio.is("input")) {
          isCompleteFake = true;
        }

        radiosInGroup = $('[name="' + radio.attr("name") + '"]');
        if (isCompleteFake) {
          radiosInGroup = $('[id^="' + getGroupBaseId() + '"]');
        }
        radiosInGroup = radiosInGroup.not(radio);

        generateFakeRadio();
        findLabel();
        initEvents();

        radio.data("snsFakeRadio", self);

        return self;
      }

      /**
       *
       * @returns {string}
       */
      function getGroupBaseId() {
        var fullId = radio.attr("id"),
          idParts = fullId.split("-");

        idParts.pop();
        return idParts.join("-");
      }

      /**
       *
       */
      function findLabel() {
        var checkboxId = radio.attr("id");
        findLabelByRadioId(checkboxId);

        if (label === null) {
          checkboxId = checkboxId.replace(/^radio\-/, "");
          findLabelByRadioId(checkboxId);
        }
      }

      /**
       *
       * @param {string} id
       */
      function findLabelByRadioId(id) {
        var radioParent = designRadio.parent();
        if (radioParent.find('label[for="' + id + '"]').length) {
          return; // in this case we don't have to do some magic with the labels
        } else if (radioParent.find("#label-" + id).length) {
          label = radioParent.find("#label-" + id);
        } else if (radioParent.find("#radio-label-" + id).length) {
          label = radioParent.find("#radio-label-" + id);
        }
      }

      /**
       *
       */
      function generateFakeRadio() {
        designRadio = $("<span>", {
          class: "design-radio"
        });
        radio.wrap(designRadio);

        designRadio = radio.parent();
        designRadio.toggleClass("checked", checkIfIsEnabled());

        if (radio.hasClass("error")) {
          designRadio.addClass("error");
        }
      }

      /**
       *
       */
      function initEvents() {
        if (isCompleteFake) {
          radio.on("click", function(ev) {
            if (radio.closest(".inactive").length) {
              return;
            }

            if (checkIfIsEnabled()) {
              return;
            }

            radio.toggleClass("checked");
            designRadio.toggleClass("checked", checkIfIsEnabled());

            for (var i = radiosInGroup.length; i--; ) {
              $(radiosInGroup[i])
                .data()
                .snsFakeRadio.disable();
            }
          });
        } else {
          radio
            .on("change", function() {
              if (radio.closest(".inactive").length) {
                radio.removeAttr("checked").prop("checked", false);
                return;
              }
              designRadio.toggleClass("checked", checkIfIsEnabled());

              for (var i = radiosInGroup.length; i--; ) {
                $(radiosInGroup[i])
                  .data()
                  .snsFakeRadio.update();
              }
            })
            .on("focus", function() {
              designRadio.addClass("focus");
            })
            .on("blur", function() {
              designRadio.removeClass("focus");
            });
        }

        if (label && label.length) {
          label.on("click", function() {
            radio.trigger("click");
          });
        }
      }

      /**
       *
       */
      function checkIfIsEnabled() {
        if (isCompleteFake) {
          return radio.hasClass("checked");
        }

        return radio.is(":checked") ? true : false;
      }

      /**
       *
       */
      self.update = function() {
        if (
          (designRadio.hasClass("checked") && checkIfIsEnabled()) ||
          (!designRadio.hasClass("checked") && !checkIfIsEnabled())
        ) {
          return;
        }
        radio.trigger("change");
      };

      /**
       *
       */
      self.disable = function() {
        if (designRadio.hasClass("checked") && checkIfIsEnabled()) {
          designRadio.removeClass("checked");
          radio.removeClass("checked");
        }
      };

      /**
       *
       */
      self.setInactive = function() {
        radio.attr("disabled", "disabled");
      };

      /**
       *
       */
      self.setActive = function() {
        radio.removeAttr("disabled");
      };

      return __construct();
    };

  $.fn.extend({
    SnsDesignCheckbox: function() {
      return $(this).each(function() {
        new SnsDesignCheckbox($(this));
      });
    },
    SnsDesignRadio: function() {
      return $(this).each(function() {
        new SnsDesignRadio($(this));
      });
    }
  });
})(jQuery);
