(function($) {
  var SnsGlossarHover = function(element, settings) {
    var config = {
        textWrapClass: "info-text-wrap"
      },
      hoverWrap = element;

    /**
     *
     * @returns {object}
     * @private
     */
    function __construct() {
      config = $.extend(config, settings);

      bindEvents();

      return hoverWrap;
    }

    /**
     *
     */
    function bindEvents() {
      hoverWrap.on("mouseover", function() {
        loadAndAppendText();

        hoverWrap.off("mouseover");
      });

      hoverWrap.on("touchend", function() {
        loadAndAppendText();
        const infoTextElement = $(this).children()[0];
        infoTextElement.style.display = "block";
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOSSafari) {
          infoTextElement.style.display = "block";
          infoTextElement.style.position = "fixed";
          infoTextElement.style.top = "50%";
          infoTextElement.style.left = "50%";
          infoTextElement.style.transform = "translate(-50%, -50%)";
          infoTextElement.style.height = "fit-content";
        }
        const infoTextOverlay = $("<div>", {
          class: "info-text-overlay"
        });
        hoverWrap.append(infoTextOverlay);
        $(".info-text-overlay").on("touchstart", function() {
          $(this).remove();
          $(".info-text-wrap").remove();
        });
        hoverWrap.off("touchstart");
      });
    }

    /**
     *
     */
    function loadAndAppendText() {
      var wrapData = hoverWrap.data();
      if (wrapData.glossarUri) {
        $.ajax({
          url: wrapData.glossarUri,
          success: function(data) {
            appendText(data);
          }
        });
      } else if (wrapData.glossarText) {
        appendText(wrapData.glossarText);
      }
    }

    /**
     *
     * @param {String} text
     */
    function appendText(text) {
      var infoText = $("<div>", {
        class: config.textWrapClass,
        text: text
      });

      hoverWrap.append(infoText);
    }

    return __construct();
  };

  $.fn.extend({
    SnsGlossarHover: function(options) {
      return $(this).each(function() {
        new SnsGlossarHover($(this), options);
      });
    }
  });
})(jQuery);
