import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui-timepicker-addon";

const DATEPICKER_CLASS = ".js_datepicker";
const SATURDAY = 5,
  SUNDAY = 6;

let datetimepicker = {
  init: function() {
    this.initDatetimePicker();
    this.initTimepicker();
  },

  initDatetimePicker: function() {
    let $datepicker = $(DATEPICKER_CLASS),
      startDateOffset = this.optInt($datepicker.data("startDateOffset")),
      maxDays = this.optInt($datepicker.data("maxDays")),
      blacklistedDays = $datepicker.data("blacklistedDays"),
      yearStart = this.optInt($datepicker.data("yearStart")),
      yearLimit = this.optInt($datepicker.data("yearLimit")),
      startDate = new Date(),
      options;

    function startDateWithOffset(startDate) {
      let tempDate = new Date(startDate.getTime()),
        offset = 0,
        i = 0;

      if (typeof blacklistedDays !== "undefined") {
        while (i < startDateOffset + offset) {
          for (let j = 0; j < blacklistedDays.length; j++) {
            if (
              (tempDate.getMonth() === blacklistedDays[j][0] - 1 &&
                tempDate.getDate() === blacklistedDays[j][1]) ||
              tempDate.getUTCDay() === SATURDAY ||
              tempDate.getUTCDay() === SUNDAY
            ) {
              offset++;
              break;
            }
          }
          tempDate.setDate(tempDate.getDate() + 1);
          i++;
        }
      }
      return startDate.getDate() + startDateOffset + offset;
    }

    function removeBlacklistedDays(date) {
      if (typeof blacklistedDays !== "undefined") {
        for (let i = 0; i < blacklistedDays.length; i++) {
          if (
            date.getMonth() === blacklistedDays[i][0] - 1 &&
            date.getDate() === blacklistedDays[i][1]
          ) {
            return [false, "blacklistedDay"];
          }
        }
      }
      return [true, ""];
    }

    function noWeekendsOrBlacklistedDays(date) {
      let noWeekend = $.datepicker.noWeekends(date);
      if (noWeekend[0]) {
        return removeBlacklistedDays(date);
      } else {
        return noWeekend;
      }
    }

    function getBirthdateOptions(defaultDate, country) {
      let currentYear = new Date().getFullYear();
      let yearRange = currentYear - yearStart + ":" + (currentYear - yearLimit);
      let dayNamesMin;
      let monthNames;
      let monthNamesShort;
      if (country === "de") {
        dayNamesMin = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
        monthNames = [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember"
        ];
        monthNamesShort = [
          "Jan",
          "Feb",
          "Mär",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dez"
        ];
      } else if (country === "fi") {
        dayNamesMin = ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"];
        monthNames = [
          "Tammikuu",
          "Helmikuu",
          "Maaliskuu",
          "Huhtikuu",
          "Toukokuu",
          "Kesäkuu",
          "Heinäkuu",
          "Elokuu",
          "Syyskuu",
          "Lokakuu",
          "Marraskuu",
          "Joulukuu"
        ];
        monthNamesShort = [
          "Tam",
          "Hel",
          "Maa",
          "Huh",
          "Tou",
          "Kes",
          "Hei",
          "Elo",
          "Syy",
          "Lok",
          "Mar",
          "Jou"
        ];
      } else if (country === "se") {
        dayNamesMin = ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"];
        monthNames = [
          "Januari",
          "Februari",
          "Mars",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "Augusti",
          "September",
          "Oktober",
          "November",
          "December"
        ];
        monthNamesShort = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Maj",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec"
        ];
      } else {
        dayNamesMin = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        monthNamesShort = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];
      }
      return {
        firstDay: 1,
        changeMonth: true,
        changeYear: true,
        yearRange: yearRange,
        defaultDate: defaultDate,
        dateFormat: "dd.mm.yy",
        dayNamesMin: dayNamesMin,
        monthNames: monthNames,
        monthNamesShort: monthNamesShort
      };
    }

    function getAppointmentOptions(minDate) {
      return {
        minDate: minDate,
        maxDate: "+" + maxDays + "D",
        firstDay: 1,
        beforeShowDay: noWeekendsOrBlacklistedDays,
        dateFormat: "dd.mm.yy",
        dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember"
        ]
      };
    }

    let startYear = startDate.getFullYear() - yearStart;
    let endYear = startDate.getFullYear() - yearLimit;
    let currentYear = startDate.getFullYear();
    if (currentYear >= startYear && currentYear <= endYear) {
      startDate.setFullYear(startDate.getFullYear());
    } else {
      startDate.setFullYear(startDate.getFullYear() - yearStart);
    }
    startDate.setDate(startDateWithOffset(startDate));
    if (
      startDateOffset === 0 &&
      maxDays === 0 &&
      typeof blacklistedDays === "undefined"
    ) {
      let country = $datepicker.attr("country");
      options = getBirthdateOptions(startDate, country);
    } else {
      options = getAppointmentOptions(startDate);
    }
    $datepicker.datepicker({
      ...options,
      onSelect: function(dateText) {
        $datepicker.val(dateText);
        $datepicker.trigger("input");
      }
    });
  },

  initTimepicker: function() {
    let $timepicker = $(".js_timepicker");
    $timepicker.timepicker({
      closeText: $timepicker.data("closeText"),
      timeText: $timepicker.data("timeText") + "&nbsp;",
      timeFormat: "H:mm",
      minTime: "12:00 pm",
      maxTime: "3:00 pm",
      stepMinute: 5,
      oneLine: true
    });
    $timepicker.on("click", function() {
      $(".ui-timepicker-div .ui-datepicker-title").text(
        $timepicker.data("title")
      );
    });
  },

  optInt: function(value) {
    return typeof value !== "undefined" ? value : 0;
  }
};
export default datetimepicker;
