const form = document.getElementById("exportForm"),
  $form = $(form),
  $executeButton = $form.find(".js_export-execute"),
  $cancelButton = $form.find(".js_export-cancel");

export default {
  init: function() {
    let _this = this;
    _this._initExportButton();
    _this._initCancelButton();
    _this._initPropertiesCheckbox();
    _this._initNamedPropertiesChoicebox();
    _this._initProgressPercentage();
  },

  _initExportButton: function() {
    let _this = this;
    const $progressIndicator = $executeButton.find(".progress-indicator");

    $executeButton.click(function(event) {
      event.preventDefault();

      let json = _this._createJson();

      $form.attr("disabled", "");
      $form.find("select").attr("disabled", "");
      $form.find("input").each(function() {
        $(this).prop("disabled", true);
      });
      $executeButton.attr("disabled", "");
      $progressIndicator.addClass("running");

      $cancelButton.show();

      $.ajax({
        type: "POST",
        url: $form.attr("action"),
        data: json,
        dataType: "json",
        contentType: "application/json; charset=utf-8"
      }).always(function(json) {
        _this._resetFormular();
        $progressIndicator.removeClass("running");
        if (!json["interrupt"]) {
          location.reload();
        }
      });
    });
  },

  _initCancelButton: function() {
    let _this = this;
    const $executeProgressIndicator = $executeButton.find(
      ".progress-indicator"
    );
    const $cancelProgressIndicator = $cancelButton.find(".progress-indicator");

    $cancelButton.click(function(event) {
      event.preventDefault();

      $cancelButton.attr("disabled", "");
      $cancelProgressIndicator.addClass("running");
      $executeProgressIndicator.removeClass("running");

      $.ajax({
        type: "GET",
        url: $cancelButton.attr("data-url")
      }).always(function(json) {
        _this._resetFormular();
        $cancelProgressIndicator.removeClass("running");
        if (json["interrupt"]) {
          location.reload();
        }
      });
    });
  },

  _initPropertiesCheckbox: function() {
    const $namedPropertyLists = $form.find("#namedPropertyLists");

    $form.find("article label").click(function() {
      if ($namedPropertyLists.find("option:selected:first")) {
        $namedPropertyLists.val("");
        $("input[name=propertyListName]").val("");
      }
    });
  },

  _initNamedPropertiesChoicebox: function() {
    const $namedPropertyLists = $form.find("#namedPropertyLists");

    $namedPropertyLists.change(function() {
      const $selectedOption = $namedPropertyLists.find("option:selected:first");
      let namedPropertyValues = $selectedOption.val();
      if (namedPropertyValues === "") {
        return;
      }

      $form.find("input").each(function() {
        const $element = $(this);
        if (
          $element.attr("type") === "checkbox" &&
          !$element.attr("readonly")
        ) {
          $element.prop("checked", false);
        }
      });

      const selectedValues = namedPropertyValues
        .substring(1, namedPropertyValues.length - 1)
        .split(", ");

      selectedValues.forEach(function(selectedValue) {
        const element = document.getElementById(selectedValue);
        if (element && !element.hasAttribute("readonly")) {
          $(element).prop("checked", true);
        }
      });

      $("input[name=propertyListName]").val($selectedOption.attr("label"));
    });
  },

  _initProgressPercentage: function() {
    const $progressPercentage = $(".js_progress-percentage");
    const percentageString = $progressPercentage.attr("data-percentage");
    if (typeof percentageString !== "undefined") {
      let splittedPercentageString = percentageString.split("");
      let percentage = parseInt(percentageString.replace("%", ""));
      let percentageInnerDiv = "";
      $.each(splittedPercentageString, function(i) {
        let color = "#000";
        if (
          (percentage > 41 && i === 0) ||
          (percentage > 49 && i === 1) ||
          (percentage > 58 && i === 2) ||
          i === 3
        ) {
          color = "#fff";
        } else if (
          (percentage === 41 && i === 0) ||
          (percentage === 49 && i === 1) ||
          (percentage === 58 && i === 2)
        ) {
          color = "#ccc";
        } else if (
          (percentage === 40 && i === 0) ||
          (percentage === 48 && i === 1) ||
          (percentage === 57 && i === 2)
        ) {
          color = "#999";
        } else if (
          (percentage === 39 && i === 0) ||
          (percentage === 47 && i === 1) ||
          (percentage === 56 && i === 2)
        ) {
          color = "#666";
        } else if (percentage === 55 && i === 2) {
          color = "#333";
        }
        percentageInnerDiv +=
          "<span style='color: " + color + "'>" + this + "</span>";
      });
      $progressPercentage.html("");
      $progressPercentage.append("<div>" + percentageInnerDiv + "</div>");
    }
  },

  _createJson: function() {
    const formData = new FormData(form);
    const params = {};
    for (const formDatum of formData) {
      const param = params[formDatum[0]];
      if (param) {
        if (typeof param === "string") {
          params[formDatum[0]] = [param, formDatum[1]];
        } else {
          param.push(formDatum[1]);
        }
      } else {
        params[formDatum[0]] = formDatum[1];
      }
    }

    let propertiesParam = params["properties"];
    if (
      !Array.isArray(propertiesParam) &&
      typeof propertiesParam !== "undefined"
    ) {
      params["properties"] = [propertiesParam];
    }
    let periodPropertiesParam = params["periodProperties"];
    if (
      !Array.isArray(periodPropertiesParam) &&
      typeof periodPropertiesParam !== "undefined"
    ) {
      params["periodProperties"] = [periodPropertiesParam];
    }
    return JSON.stringify(params);
  },

  _resetFormular: function() {
    $form.prop("disabled", false);
    $form.find("input").each(function() {
      const $element = $(this);
      if (!$element.attr("readonly")) {
        $element.prop("disabled", false);
      }
    });
    $executeButton.prop("disabled", false);
    $cancelButton.hide();
  }
};
