import lockscroll from "../utils/lock-scroll";

var _self;

var mobilemenu = {
  init: function() {
    _self = this;
    _self._initMobileHeaderUspBar();

    let $body = $("body");

    // mobile menu
    $("#mobile-menu-show").on("click", function(e) {
      e.preventDefault();
      $("#header-and-navi").toggleClass("mobile-menu-open");
    });
    // end mobile menu

    $(".mobile-menu-toggle-wrapper").on("click", function(e) {
      let $specialMenuWrapper = $(".special-menu-wrapper");
      $(".search-and-cart-wrapper").removeClass("search-open");
      e.preventDefault();
      $(".js-toggle-mobile-menu").toggleClass("active");
      $body.toggleClass("show-mobile-menu");

      if ($body.hasClass("show-mobile-menu")) {
        $("#m-q").focus();
        if (iShop.isNavigationSticky) {
          lockscroll.enable();
          $specialMenuWrapper.css("display", "flex");
        }
      } else {
        if (iShop.isNavigationSticky) {
          lockscroll.disable();
          $specialMenuWrapper.css("display", "none");
        }
      }
    });

    $(".js-toggle-next-main-link").on("click", function(e) {
      if ($body.hasClass("show-mobile-menu")) {
        e.preventDefault();
        let $this = $(this);
        $this.toggleClass("open");
        $this
          .parent()
          .find(".flyout")
          .toggleClass("show");
      }
    });

    $(".js-toggle-next").on("click", function(e) {
      if ($body.hasClass("show-mobile-menu")) {
        e.preventDefault();
        e.stopPropagation();
        let $this = $(this);
        $this.toggleClass("open");
        $this.next().toggleClass("close-when-mobile");
      }
    });

    $(".js-toggle-submenu").on("click", function() {
      let $this = $(this);
      let $toggleListElem = $this.closest(".toggleList");

      $toggleListElem.toggleClass("toggleAll");
      if ($toggleListElem.hasClass("toggleAll")) {
        $this.find(".showAll").show();
        $this.find(".collapse").hide();
      } else {
        $this.find(".showAll").hide();
        $this.find(".collapse").show();
      }
    });
  },

  _initMobileHeaderUspBar: function() {
    let $uspMobile = $("#header-and-navi").find(".usp.mobile");
    $uspMobile.find("div").each(function(index) {
      let _this = $(this);
      let text = _this.find("span:last-child").text();
      if (text.length > 1) {
        _this.show();
        if (index === 0) {
          _this.addClass("fadeIn");
        } else {
          _this.addClass("fadeOut");
        }
      }
    });
    if ($uspMobile.find(".fadeOut").length > 0) {
      setTimeout(
        _self._changeMobileHeaderUspBar,
        iShop.uspMobileMenuChangeSpeed
      );
    }
  },

  _changeMobileHeaderUspBar: function() {
    let $mobileUsps = $("#header-and-navi").find(".usp.mobile");
    let activeUsp = $mobileUsps.find(".fadeIn");
    let nextUsps = activeUsp.nextAll(".fadeOut");
    let nextUsp = nextUsps.first();

    if (nextUsp.length === 0) {
      nextUsp = $mobileUsps.find("div:first-child");
    }
    activeUsp.removeClass("fadeIn").addClass("fadeOut");
    nextUsp.removeClass("fadeOut").addClass("fadeIn");

    setTimeout(_self._changeMobileHeaderUspBar, iShop.uspMobileMenuChangeSpeed);
  }
};
export default mobilemenu;
