import Cookies from "js-cookie";

$.fn.extend({
  Slideup: function(conf) {
    return $(this).each(function() {
      new Slideup($(this), conf);
    });
  }
});

var Slideup = function(element, settings) {
  var self = this,
    conf = {
      wrapperClass: ".slide-wrap",
      slideBoardClass: ".slide",
      displayOnScrollDown: false,
      cookies: {
        setCookie: true,
        cookieName: "gui_slideup",
        expires: 1
      }
    },
    slideBoards = [],
    boardCount,
    wrapper,
    index = 1;

  var createSlides = function() {
    $.each(element.find(conf.slideBoardClass), function() {
      slideBoards.push($(this));
    });
    boardCount = slideBoards.length;
    var wrapWidth = slideBoards.length * 100;
    $(conf.wrapperClass).css({
      width: wrapWidth + "%"
    });
    $(conf.slideBoardClass).css({
      width: 100 / slideBoards.length + "%"
    });
  };

  this.nextSlide = function(slideDirection) {
    if (slideDirection == "next") {
      if (index >= boardCount) {
        index = 1;
      } else {
        index++;
      }
    } else if (slideDirection == "prev") {
      if (index <= 0) {
        index = 1;
      } else {
        index--;
      }
    }
    var position = (index - 1) * 100;
    $(conf.wrapperClass).animate(
      {
        left: "-" + position + "%"
      },
      200
    );
  };

  var showSlideup = function() {
    $(element).addClass("position-up");
  };

  var closeSlide = function() {
    if (conf.cookies.setCookie == true) {
      Cookies.set(conf.cookies.cookieName, "true", {
        expires: conf.cookies.expires
      });
    }
    $(element).removeClass("position-up");
  };

  var isCookiePresent = function() {
    if (Cookies.get(conf.cookies.cookieName)) {
      return true;
    }
  };

  var displayOnScroll = function() {
    var lastScrollTop = 0,
      delta = 100;
    $(window).scroll(function() {
      var nowScrollTop = $(this).scrollTop();
      if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
        if (nowScrollTop > lastScrollTop) {
          $(element).removeClass("position-up");
        } else {
          $(element).addClass("position-up");
        }
        lastScrollTop = nowScrollTop;
      }
    });
  };

  function __construct() {
    wrapper = $(element);
    conf = $.extend(true, conf, settings);

    if (conf.cookies.setCookie == true) {
      if (isCookiePresent()) {
        return false;
      }
    }

    if (wrapper.data().Slideup) {
      return wrapper.data().Slideup;
    }

    showSlideup();
    createSlides();

    if (conf.displayOnScrollDown == false) {
      displayOnScroll();
    }

    $(document).on("click", ".next", function() {
      self.nextSlide("next");
    });
    $(document).on("click", ".prev", function() {
      self.nextSlide("prev");
    });
    $(document).on("click", ".slideup .icon-close", function() {
      closeSlide(element);
    });
    $(document).on("triggerNextSlide", function() {
      self.nextSlide("next");
    });

    wrapper.data("Slideup", self);
    return self;
  }

  return __construct();
};

export default Slideup;
